// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting-switch-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10vh; /* 设置容器高度为视口高度，使其垂直居中 */
  }

  .setting-switch-container .label{
    margin-bottom: 2rem;
  }

  .setting-switch-container .form-switch {
    /* 设置开关的宽度和高度 */
    width: 50px;
    height: 25px;
    /* 设置开关的放大效果 */
    transform: scale(1.5); /* 倍数可以根据需要调整 */
  }

`, "",{"version":3,"sources":["webpack://./src/components/Integration/ConfigConnection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY,EAAE,uBAAuB;EACvC;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,WAAW;IACX,YAAY;IACZ,cAAc;IACd,qBAAqB,EAAE,eAAe;EACxC","sourcesContent":[".setting-switch-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 10vh; /* 设置容器高度为视口高度，使其垂直居中 */\n  }\n\n  .setting-switch-container .label{\n    margin-bottom: 2rem;\n  }\n\n  .setting-switch-container .form-switch {\n    /* 设置开关的宽度和高度 */\n    width: 50px;\n    height: 25px;\n    /* 设置开关的放大效果 */\n    transform: scale(1.5); /* 倍数可以根据需要调整 */\n  }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
