import NavigationBar from "../../components/NavBar/NavBar";
import { Folders } from "../../components/Folder/Folder";

function FolderPage() {
  return (
    <div className="folder-page">
      <NavigationBar />
      <Folders />
    </div>
  );
}

export default FolderPage;
