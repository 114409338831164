import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React from "react";


function DoubleComfirmModal(props) {
    const { datasetName } = props;
    const handleDoubleConfirm = () => {
        props.onDelete(datasetName)
        }

    return (
        <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                {props.title}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
                {props.text}
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.onHide}>Cancel</Button>
            <Button 
                variant="outline-danger" 
                onClick={() => {
                    props.onHide();
                    handleDoubleConfirm()
                }}>
                Delete
            </Button>
        </Modal.Footer>
        </Modal>
    );
}

export default DoubleComfirmModal