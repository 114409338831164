import axios from "axios";
import { useState } from "react";
import { Datasets } from "../../components/Dataset/Dataset";
import NavigationBar from "../../components/NavBar/NavBar";
import "./TagPage.css"

function TagPage() {
  return (
    <div>
      <NavigationBar />
      <div className="tag-page">
        <Datasets CustomizedContent={CustomizedContent} />
        {/* <p>I am Tag page</p> */}
      </div>
    </div>

  );
}

function CustomizedContent(props) {
  let { item, loadDatasetList } = props;

  let [IoUThred, setIoUThred] = useState(0.8);
  let [confidenceThred, setConfidenceThred] = useState(0.9);

  const handleChangeIoUThred = (e) => {
    const value = parseFloat(e.target.value);
    setIoUThred(isNaN(value) ? 0 : parseFloat(value.toFixed(2)));
  };

  const handleChangeConfidenceThred = (e) => {
    const value = parseFloat(e.target.value);
    setConfidenceThred(isNaN(value) ? 0 : parseFloat(value.toFixed(2)));
  };

  const TagMatched = async () => {
    axios
      .put(
        `/tag/tag_by_iou?dataset_name=${item.name}&iou_threshold=${IoUThred}&confidence_threshold=${confidenceThred}`
      )
      .then((response) => {
        loadDatasetList();
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const Untag = async () => {
    axios
      .delete(`/tag/untag_iou?dataset_name=${item.name}`)
      .then((response) => {
        loadDatasetList();
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  return (
    <div className="tag-customized-content">
      <p className="card-text">
        has ground_truth:
        {item.sample_label_fields &&
          item.sample_label_fields.includes("ground_truth")
          ? "Yes"
          : "No"}
      </p>
      <p className="card-text">
        has prediction:
        {item.sample_label_fields &&
          item.sample_label_fields.includes("prediction")
          ? "Yes"
          : "No"}
      </p>
      <p className="card-text">
        has matched tag:
        {item.count_label_tags && "matched" in item.count_label_tags
          ? "Yes"
          : "No"}
      </p>
      <label htmlFor="IoU-threshold">IOU threshold:</label>
      <input
        id="IoU-threshold"
        type="number"
        min={0}
        max={1}
        step={0.1}
        value={IoUThred}
        onChange={handleChangeIoUThred}
      />
      <label htmlFor="confidence-threshold">Confidence threshold:</label>
      <input
        id="confidence-threshold"
        type="number"
        min={0}
        max={1}
        step={0.1}
        value={confidenceThred}
        onChange={handleChangeConfidenceThred}
      />
      <button className="btn btn-success" type="button" onClick={TagMatched}>
        Tag matched
      </button>
      <button className="btn btn-danger" type="button" onClick={Untag}>
        Untag
      </button>
    </div>
  );
}

export default TagPage;
