import "./styles/App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDataContext, AppProvidider } from "./utils/DataContext";

import SettingPage from "./pages/SettingPage/SettingPage";
import DatasetPage from "./pages/DatasetPage/DatasetPage";
import TagPage from "./pages/TagPage/TagPage";
import AcquisitionDataPage from "./pages/AcquisitionDataPage/AcquisitionDataPage";
import LabelsPage from "./pages/LabelsPage/LabelsPage";
import CVATPage from "./pages/CVATPage/CVATPage";
import LLMPage from "./pages/LLMPage/LLMPage";
import ModelPage from "./pages/PreLabelPage/PreLabelPage";
import ExportPage from "./pages/ExportPage/ExportPage";
import SignUpPage from "./pages/Auth/Signup/SignupPage";
import SignInPage from "./pages/Auth/Login/LoginPage";
import ErrorRatePage from "./pages/ErrorRatePage/ErrorRatePage";
import ETLPage from "./pages/ETLPage/ETLPage";
import LabelStudioPage from "./pages/LabelStudioPage/LabelStudioPage";
import FolderPage from "./pages/FolderPage/FolderPage";
import DataCleansingPage from "./pages/DataCleansingPage/DataCleansingPage";
import TrainPage from "./pages/TrainPage/TrainPage";
import NotFoundPage from "./components/NotFound/NotFoundPage";
import OrganizationPage from "./pages/Auth/OrganizationPage/OrganizationPage";

const basicRoutes = [
  <Route path="*" element={<NotFoundPage />} />,
  <Route path="/" element={<AcquisitionDataPage />} />,
  <Route path="/signin" element={<SignInPage />} />,
  <Route path="/signup" element={<SignUpPage />} />,
  <Route path="/acquisition" element={<AcquisitionDataPage />} />,
  <Route path="/data-cleansing" element={<DataCleansingPage />} />,
  <Route path="/dataset" element={<DatasetPage />} />,
  <Route path="/pre-label" element={<ModelPage />} />,
  <Route path="/labels" element={<LabelsPage />} />,
  <Route path="/cvat" element={<CVATPage />} />,
  <Route path="/folder" element={<FolderPage />} />,
  <Route path="/tag" element={<TagPage />} />,
  <Route path="/label-studio" element={<LabelStudioPage />} />,
  <Route path="/llm" element={<LLMPage />} />,
  <Route path="/export" element={<ExportPage />} />,
  <Route path="/error-rate" element={<ErrorRatePage />} />,
  <Route path="/ETL" element={<ETLPage />} />,
  <Route path="/train" element={<TrainPage />} />,
  <Route path="/organization" element={<OrganizationPage />} />,
];

const annotatorRoutes = [...basicRoutes];

const developerRoutes = [...annotatorRoutes];

const adminRoutes = [
  ...developerRoutes,
  <Route path="/setting" element={<SettingPage />} />,
];

const rolesToRoutes = {
  admin: adminRoutes,
  developer: developerRoutes,
  annotator: annotatorRoutes,
};

function App() {
  return (
    <AppProvidider>
      <AppChild />
    </AppProvidider>
  );
}

function AppChild() {
  const { role } = useDataContext();

  const currentRoutes = [
    ...(rolesToRoutes.hasOwnProperty(role) ? rolesToRoutes[role] : basicRoutes),
  ];

  return (
    <Router>
      <div className="App">
        <Routes>{currentRoutes}</Routes>
      </div>
    </Router>
  );
}

export default App;
