import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Timer from "../../../components/Timer";
import axios from "axios";
import { useEffect, useState } from "react";
import "../../../styles/Dataset.css";
import "../../../styles/KubeflowPage.css";

function StatusCheckPage(props) {
  const { item, loadTaskRes, loadTask } = props;

  const loadTaskItems = async () => {
    axios
      .put(`/models/kubeflow/update`)
      .then((response) => {
        loadTask(item.name);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  return (
    <Modal
      fullscreen={true}
      show={props.show}
      onHide={props.onHide}
      className="Status_Modal_Dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>Status Check Page</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="kubeflow-tasks">
          {loadTaskRes &&
            loadTaskRes.map((item, index) => {
              return (
                <TaskItem
                  key={`${item.run_id}`}
                  item={item}
                  loadTaskItems={loadTaskItems}
                />
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function TaskItem(props) {
  const { item, loadTaskItems } = props;
  const [localDelLoading, setLocalDelLoading] = useState(false);

  useEffect(() => {}, []);

  const deleteTask = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this task?"
    );

    if (confirmDelete) {
      setLocalDelLoading(true);
      axios
        .delete(`/models/kubeflow/task/delete?run_id=${item.run_id}`)
        .then((response) => {
          loadTaskItems();
          setLocalDelLoading(false);
        })
        .catch((error) => {
          setLocalDelLoading(false);
          let errorMsg = error.response.data;
          window.alert(errorMsg);
        });
    }
  };

  return (
    <div
      className={`card ${
        item.status === "Running"
          ? "Running"
          : item.status === "Failed"
          ? "Failed"
          : "Succeeded"
      }`}
      style={{ width: "18rem" }}
    >
      {(item.status === "Succeeded" || item.status === "Failed") && (
        <div class="container-fluid">
          <div class="row">
            <div class="col col-xl-12 d-flex justify-content-end px-2 py-2">
              <button
                type="button"
                class="btn-close"
                aria-label="Close"
                onClick={() => deleteTask(item.run_id)}
                disabled={localDelLoading}
              ></button>
            </div>
          </div>
        </div>
      )}
      <div class="card-body">
        <button
          type="button"
          class="btn btn-light"
          onClick={() => {
            const w = window.open("about:blank");
            w.location.href = `http://${window.location.hostname}:3000`;
          }}
        >
          <h5 class="card-title">run id: {item.run_id}</h5>
        </button>
        <div className="task-items-container">
          <p>dataset: {item.dataset_name}</p>
          <p>model: {item.model_name}</p>
          <p>respath: {item.respath}</p>
          <p>status: {item.status}</p>
        </div>
      </div>
    </div>
  );
}

export default StatusCheckPage;
