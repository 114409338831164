import axios from "axios";
import { useState } from "react";
import Timer from "../../../components/Timer";
import Button from "react-bootstrap/Button";
import "react-dropdown/style.css";
import DoubleComfirmModal from "../../../components/DoubleConfirmModal/DoubleConfirm";

function DeleteRedundantPage(props) {
  const { item, loadDatasetList } = props;

  const [uniquenessThred, setUniquenessThred] = useState(0.2);
  const [computeLoading, setComputeLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [DoubleCheckModalShow, setDoubleCheckModalShow] = useState(false);

  const handleChangeUniquenessThred = (e) => {
    const value = parseFloat(e.target.value);
    setUniquenessThred(isNaN(value) ? 0 : parseFloat(value.toFixed(2)));
  };

  const handleClick = () => {
    setDoubleCheckModalShow(true);
  };

  const computeUniqueness = async () => {
    if (!item.samples.length || item.samples.length === 0) {
      window.alert(`There is no sample in dataset '${item.name}'`);
      return;
    }
    setComputeLoading(true);
    axios
      .get(`/datasets/compute_uniqueness?dataset_name=${item.name}`)
      .then((response) => {
        setComputeLoading(false);
        loadDatasetList();
        window.alert(`Compute uniqueness on dataset '${item.name}' success`);
      })
      .catch((error) => {
        setComputeLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const deleteRedundantSample = async () => {
    if (uniquenessThred < 0 || uniquenessThred > 1) {
      window.alert(
        `Uniqueness threshold is Invalid. Please choose a number between 0.0 to 1.0`
      );
      return;
    } else if (!item.samples.length || item.samples.length === 0) {
      window.alert(`There is no sample in dataset '${item.name}'`);
      return;
    }
    setDeleteLoading(true);
    let url = `/datasets/delete/redundant_samples?dataset_name=${item.name}&uniqueness_threshold=${uniquenessThred}`;
    axios
      .delete(url)
      .then((response) => {
        setDeleteLoading(false);
        loadDatasetList();
        window.alert(
          `Delete redundant samples on dataset '${item.name}' success`
        );
      })
      .catch((error) => {
        setDeleteLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  return (
    <div className="delete-redundant-page">
      <p class="card-text">
        media type: {item.media_type ? item.media_type : "null"}
      </p>
      <div class="clear-data">
        <div class="sample-container">
          <p class="card-text">samples num: {item.samples.length}</p>
        </div>
      </div>
      {item.brain_runs_list && item.brain_runs_list.includes("uniqueness") ? (
        <div className="delete-redundant-content" style={{ display: "ruby" }}>
          <label htmlFor="uniqueness-threshold">Uniqueness threshold:</label>
          <input
            id="uniqueness-threshold"
            type="number"
            min={0}
            max={1}
            step={0.1}
            value={uniquenessThred}
            onChange={handleChangeUniquenessThred}
          />
          <div className="down-button">
            <Button
              variant="outline-danger"
              type="button"
              onClick={handleClick}
              disabled={deleteLoading}
            >
              {deleteLoading ? "Loading..." : "Delete Redundancies"}
            </Button>
            <DoubleComfirmModal
              show={DoubleCheckModalShow}
              onHide={() => setDoubleCheckModalShow(false)}
              datasetName={item.name}
              onDelete={deleteRedundantSample}
              title="Delete Redundant"
              text="Data cannot be recovered after deletion, please proceed with caution."
            />
          </div>
        </div>
      ) : (
        <div>
          <div className="top-button">
            <Button
              type="button"
              onClick={computeUniqueness}
              disabled={computeLoading}
            >
              {computeLoading ? (
                <span>
                  <Timer loadingStates={[computeLoading]} />
                </span>
              ) : (
                "Compute Uniqueness"
              )}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default DeleteRedundantPage;
