import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

function LocalPage(props) {
  const { goToFolderPage } = props;

  const [folderName, setFolderName] = useState("");
  const [deleteAllLoading, setDeleteAllLoading] = useState(false);

  const deleteAllFolder = async () => {
    setDeleteAllLoading(true);
    axios
      .delete(`/file/folder/delete/all`)
      .then((response) => {
        setDeleteAllLoading(false);
        let msg = `delete all folder success.`;
        window.alert(msg);
      })
      .catch((error) => {
        setDeleteAllLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const addFolder = async () => {
    if (!folderName) {
      window.alert("Please input a folder name.");
      return;
    }
    axios
      .put(`/file/folder/create?folder_name=${folderName}`)
      .then((response) => {
        let msg = `${folderName} created success.`;
        window.alert(msg);
        goToFolderPage();
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  return (
    <div className="Local-Page">
      <div className="folder-create">
        <h5>Step1. Create Folder (Optional)</h5>
        <div>
          <label htmlFor="folder-name">Folder Name</label>
        </div>
        <div>
          <input
            id="folder-name"
            type="text"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
          />
        </div>
        <div class="data-button-container">
          <Button onClick={addFolder}>Create</Button>
          <Button
            variant="outline-danger"
            onClick={deleteAllFolder}
            disabled={deleteAllLoading}
          >
            {deleteAllLoading ? "Loading..." : "Delete All"}
          </Button>
          <Button onClick={goToFolderPage}>Skip</Button>
        </div>
      </div>
    </div>
  );
}

export default LocalPage;
