import axios from "axios";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";

export function getUserCookie() {
  const userToken = Cookies.get("user_token");
  const userData = userToken ? jwtDecode(userToken) : null;
  return userData;
}

export function updateTokenUserCookie() {
  axios
    .post("/auth/login", {
      requesterToken: Cookies.get("user_token"),
    })
    .then((response) => {
      Cookies.set("user_token", response.data.token);
    })
    .catch((error) => {
      const errorMsg = error.response.data;
      window.alert(errorMsg);
    });
}
