const ETLConfig = {
  Common: {
    Application: "ObjectDetection",
    Split: "training",
    FileSource: "1054",
    Video: true,
    ProcessMethod: "http://sssss",
    OriginalZip: "/home/qsc/Downloads/test/ETL_demo.zip",
    ZipPath: "fo_ETL_demo/image.zip",
    Rotation: "clock_wise_0",
  },
  FacialApp: {
    CatType: "",
    AttackType: "",
    Liveness: "",
    Aligned: false,
    FacialMap: "",
    RaceMethod: "",
  },
  ObjectDetection: {
    DetectionMap: "kneron_detection",
  },
  Classification: {
    ClassificationMap: "coco",
  },
  OCR: {
    Country: "",
  },
  Regression: {
    RegressionMap: "",
  },
  InstanceSeg: {
    InstanceSegMap: "",
  },
  SemanticSegSeg: {
    SegmanticSegMap: "",
  },
};

export { ETLConfig };