// ConfigModal.js
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from "axios";
import ConfigInputs from "./ConfigInputs";


function useSaveConfig() {
  const [loading, setLoading] = useState(false);

  const saveConfig = async (item, config) => {
    setLoading(true);
    try {
      await axios.post(`/ETL/config/save?dataset_name=${item.name}`, config);
      window.alert(`Save config on dataset '${item.name}' success`);
    } catch (error) {
      let errorMsg = error.response.data;
      window.alert(errorMsg);
    }
    setLoading(false);
  };

  return [loading, saveConfig];
}

function ConfigModal(props) {
  const { item, config, setConfig } = props;
  const [loading, saveConfig] = useSaveConfig();

  return (
    <div className="modal fade" id={`input-config-${item.name}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">
              ETL Input
            </h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <ConfigInputs item={item} config={config} setConfig={setConfig} />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button type="button" className="btn btn-primary" onClick={() => saveConfig(item, config)} disabled={loading}>
              {loading ? "Loading..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}


export default ConfigModal;
