import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function Instrument({text}) {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="button-tooltip-2">{text}</Tooltip>}
    >
      {({ ref, ...triggerHandler }) => (
        <Button
          variant="light"
          {...triggerHandler}
          style={{
            transform: 'scale(0.8)',
            display: 'flex',
            alignitems: 'center',
            justifycontent: 'center',
            width: '2rem',
            height: '2rem',
            borderRadius: '1rem',
            borderColor: '#DDDDDD',
          }}
          ref={ref}
        >
            <p className='button-text'>?</p>
        </Button>
      )}
    </OverlayTrigger>
  );
}

export default Instrument;