import axios from "axios";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import AcquisitionDataPageModal from "./Modal";
import UploadModal from "./UploadModal";
import DatasetInfo from "./DatasetInfo";
import { useDataContext } from "../../utils/DataContext";
import "../../styles/AcquisitionDataPage.css";
import "react-dropdown/style.css";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { getUserCookie } from "../../utils/cookiesUtils";

function CustomizedContent(props) {
  const { item, loadDatasetList } = props;

  const { selectedSource } = useDataContext();
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mntPath, setMntPath] = useState("");
  const [fiftyoneSelection, setFiftyoneSelection] = useState("");

  const userToken = Cookies.get("user_token");
  const userData = userToken ? jwtDecode(userToken) : null;
  const role = userData ? userData.role : null;
  const organization = userData ? userData.organization : null;

  const handleDeleteData = async (datasetName) => {
    let method = "delete";
    let data = null;
    if (role === "admin" || organization) {
      method = "post";
      data = { requesterToken: userToken };
    }
    axios({
      method: method,
      url: `/datasets/delete?dataset_name=${datasetName}&clear_data=true`,
      data: data,
    })
      .then((response) => {
        loadDatasetList();
        window.alert(`Delete dataset '${datasetName}' success`);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const handleDownloadClick = async () => {
    let url = "";
    switch (selectedSource) {
      case "goToMntPage":
        url = `/datasets/load_mnt?dataset_name=${item.name}&dir_path=${mntPath}`;
        break;
      case "fiftyone":
        url = `/datasets/zoo/load_data?dataset_name=${item.name}&zoo_dataset_name=${fiftyoneSelection.selectedDataset}&split=${fiftyoneSelection.selectedSplit}&max_sample=${fiftyoneSelection.sampleNum}`;
        setModalShow(false);
        break;
      default:
        return;
    }
    setLoading(true);
    axios
      .put(url)
      .then((response) => {
        setLoading(false);
        loadDatasetList();
        window.alert(`Load data success`);
      })
      .catch((error) => {
        setLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const isCommon = item.folder !== "Yes" && item.table !== "Yes";
  if (!isCommon) {
    return (
      <div className="acquisition-data-customized-content">
        <p>You can not add samples on dataset that represents a folder/table</p>
        {item.folder === "Yes" && (
          <div>
            <p>If you want to add new samples to folder dataset,</p>
            <p>please click 'Folders' tab, and you can upload data there.</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="acquisition-data-customized-content">
      <DatasetInfo item={item} onDeleteData={handleDeleteData} />
      <div class="chooseData-button">
        <Button variant="outline-primary" onClick={() => setModalShow(true)}>
          Upload Data
        </Button>
        <UploadModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          loadDatasetList={loadDatasetList}
          item={item}
        />
        {/* <Button variant="outline-primary" onClick={() => setModalShow(true)}>
          Choose Data
        </Button> */}
        {/* <AcquisitionDataPageModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          loadDatasetList={loadDatasetList}
          item={item}
          onMntPathChange={(mntPath) => {
            setMntPath(mntPath);
          }}
          onFiftyoneSelectionChange={(fiftyoneSelection) => {
            setFiftyoneSelection(fiftyoneSelection);
          }}
        /> */}
        <div>
          {fiftyoneSelection.selectedDataset &&
            selectedSource === "fiftyone" && (
              <div>
                <p>Selected dataset: {fiftyoneSelection.selectedDataset}</p>
                <p>Selected split: {fiftyoneSelection.selectedSplit}</p>
                <p>Selected max sample number: {fiftyoneSelection.sampleNum}</p>
              </div>
            )}
          {mntPath && selectedSource === "goToMntPage" && (
            <div className="mntPath-container">
              <p className="text">{mntPath}</p>
            </div>
          )}
        </div>
        {role !== "annotator" && (
          <div class="loadData-button">
            <Button onClick={handleDownloadClick} disabled={loading}>
              {loading ? "Loading..." : "LoadData"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomizedContent;
