// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kubeflow-tasks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


.task-items-container {
    width: 200px;
    white-space: wrap;
}

.card.Running {
    border: 2px solid orange;
}

.card.Failed {
    border: 2px solid red;
}

.card.Succeeded {
    border: 2px solid green;
}

/* 调整Modal的位置，可以根据需要调整top和left值 */
.Status_Modal_Dialog {
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    height: 90vh;
    max-width: none; /* 可以去掉最大宽度限制，以确保宽度为 90% */
}`, "",{"version":3,"sources":["webpack://./src/styles/Dataset.scss","webpack://./src/styles/Dataset.css","webpack://./src/styles/KubeflowPage.css"],"names":[],"mappings":"AAAA;IACI,aAAA;ICCJ,mBAAA;ICCI,eAAe;AACnB;;;AAGA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA,iCAAiC;AACjC;IACI,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,UAAU;IACV,YAAY;IACZ,eAAe,EAAE,0BAA0B;AAC/C","sourcesContent":[".datasetGallery {\n    display: flex;\n}",".datasetGallery {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.card {\n  margin-left: 1rem;\n  margin-bottom: 1rem;\n}\n\n.card-title {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.card-body {\n  max-width: 18rem;\n}\n\n.card-text {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.add-group {\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-start;\n}\n\n.dataset-option {\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-start;\n}\n\n.dataset-group {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  margin-left: 1rem;\n  margin-right: 1rem;\n}\n",".kubeflow-tasks {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n}\n\n\n.task-items-container {\n    width: 200px;\n    white-space: wrap;\n}\n\n.card.Running {\n    border: 2px solid orange;\n}\n\n.card.Failed {\n    border: 2px solid red;\n}\n\n.card.Succeeded {\n    border: 2px solid green;\n}\n\n/* 调整Modal的位置，可以根据需要调整top和left值 */\n.Status_Modal_Dialog {\n    margin-top: 5%;\n    margin-bottom: 5%;\n    margin-left: 5%;\n    margin-right: 5%;\n    width: 90%;\n    height: 90vh;\n    max-width: none; /* 可以去掉最大宽度限制，以确保宽度为 90% */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
