import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './BackButton.css';


const BackButton = ({ onClick }) => (
    <button className="back-button" onClick={onClick}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </button>
  );
export default BackButton