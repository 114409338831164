import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function DeleteDoudleConfirmModal(props) {
  const {
    item,
    datasetName,
    onHide,
    deleteMethod,
    onDelete,
    onDeleteSelected,
    resetDeleteMethod,
  } = props;

  const handleDoubleConfirm = () => {
    if (datasetName) {
      onDelete(datasetName);
    } else if (deleteMethod && deleteMethod === "deleteSelected") {
      onDeleteSelected();
    } else {
      onDelete();
    }
  };
  const canDelete = !item || (item.folder !== "Yes" && item.table !== "Yes");

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Dataset
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {canDelete ? (
          <p>
            Data cannot be recovered after deletion, please proceed with
            caution.
          </p>
        ) : (
          <p>
            Dataset that represents a folder/table can not be deleted here,
            Please delete them in folder/table page instead.
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Cancel</Button>
        {canDelete && (
          <Button
            variant="outline-danger"
            onClick={() => {
              onHide();
              handleDoubleConfirm();
              resetDeleteMethod();
            }}
          >
            Delete
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteDoudleConfirmModal;
