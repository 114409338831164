import axios from "axios";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import "react-dropdown/style.css";
import "../../../styles/LabelsPage.css";
import "../styles/FieldOpsPage.css";
import Timer from "../../../components/Timer";

function FieldOperationModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      isOpen={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "10px" }}
          >
            Field Operations
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <FieldOperation {...props} />
      </Modal.Body>
    </Modal>
  );
}

function FieldOperation(props) {
  const { item, loadDatasetList } = props;

  const [labelField, setLabelField] = useState(null);
  const [newFieldName, setNewFieldName] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const editFieldName = async () => {
    if (!labelField || labelField == "" || !newFieldName) {
      window.alert(
        `Please selected field and input new field name for dataset '${item.name}'`
      );
      return;
    }
    let url = `/datasets/field/edit?dataset_name=${item.name}&field=${labelField}&new_field_name=${newFieldName}`;
    axios
      .put(url)
      .then((response) => {
        loadDatasetList();
        window.alert(`Edit field name on dataset '${item.name}' success`);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const deleteField = async () => {
    setDeleteLoading(true);
    let url = `/datasets/field/delete?dataset_name=${item.name}`;
    if (labelField && labelField !== "") {
      url += `&field=${labelField}`;
    }
    axios
      .delete(url)
      .then((response) => {
        setDeleteLoading(false);
        loadDatasetList();
        window.alert(`Delete field on dataset '${item.name}' success`);
      })
      .catch((error) => {
        setDeleteLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const deleteSelectedField = async () => {
    if (!labelField || labelField === "") {
      window.alert(`Please selected field for dataset '${item.name}'`);
      return;
    }
    deleteField();
  };

  const deleteAllFields = async () => {
    setLabelField(null);
    deleteField();
  };

  const mergeFields = async () => {
    setDeleteLoading(true);
    let url = `/dataset/fields/merge?dataset_name=${item.name}`;
    axios
      .put(url)
      .then((response) => {
        setDeleteLoading(false);
        loadDatasetList();
        window.alert(
          `Merge all fields by label types on '${item.name}' success`
        );
      })
      .catch((error) => {
        setDeleteLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  let display = (
    <div>
      <h4>There is no field in this dataset</h4>
      <p>To create a new field:</p>
      <p>
        1. Go to <strong>Pre-Label</strong> page and choose a model to do
        inference.
      </p>
      <p>
        2. Go to <strong>Annotation</strong> page and do the annotation.
      </p>
    </div>
  );
  if (item.sample_fields && item.sample_fields.length > 0) {
    display = (
      <div className="fields-customized-content">
        <div className="field-input-content">
          <select
            id="field-select"
            className="field-select-option"
            value={labelField}
            onChange={(e) => {
              setLabelField(e.target.value);
            }}
          >
            <option value="">Please choose an field</option>
            {item.sample_fields &&
              item.sample_fields.map((field) => (
                <option key={field} value={field}>
                  {field}
                </option>
              ))}
          </select>
          <input
            type="text"
            placeholder="new field name"
            value={newFieldName}
            onChange={(e) => {
              setNewFieldName(e.target.value);
            }}
          />
        </div>
        <div className="field-button-container">
          <div className="field-button">
            <Button variant="primary" type="button" onClick={editFieldName}>
              Rename
            </Button>
          </div>
          <div className="field-button">
            <Button
              variant="outline-danger"
              type="button"
              onClick={deleteSelectedField}
              disabled={deleteLoading}
            >
              {deleteLoading ? (
                <span>
                  <Timer loadingStates={[deleteLoading]} />
                </span>
              ) : (
                "Delete one"
              )}
            </Button>
          </div>
          <div className="field-button">
            <Button
              variant="outline-danger"
              type="button"
              onClick={deleteAllFields}
              disabled={deleteLoading}
            >
              {deleteLoading ? (
                <span>
                  <Timer loadingStates={[deleteLoading]} />
                </span>
              ) : (
                "Delete all"
              )}
            </Button>
          </div>
          <div className="field-button">
            <Button
              variant="success"
              type="button"
              onClick={mergeFields}
              disabled={deleteLoading}
            >
              {deleteLoading ? (
                <span>
                  <Timer loadingStates={[deleteLoading]} />
                </span>
              ) : (
                "Merge all"
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return display;
}

export default FieldOperationModal;
