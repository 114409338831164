// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ETL-customized-content {
  display: flex;
  flex-direction: column;
}

.card-text {
  font-size: 16px;
}

.btn-secondary {
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.xml-select {
  display: flex;
  flex-direction: column;
  width: 220px;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.xml-select label {
  display: block;
  font-size: 16px;
  margin-bottom: 0.5rem;
}

.xml-select-option {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.xml-select-container {
  width: 220px;
  overflow: hidden;
}

.button-container {
  text-align: right;
}

.btn-primary {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ETLPage/ETLPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".ETL-customized-content {\n  display: flex;\n  flex-direction: column;\n}\n\n.card-text {\n  font-size: 16px;\n}\n\n.btn-secondary {\n  border: none;\n  padding: 10px 20px;\n  cursor: pointer;\n}\n\n.xml-select {\n  display: flex;\n  flex-direction: column;\n  width: 220px;\n  margin-bottom: 1rem;\n  margin-top: 1rem;\n}\n\n.xml-select label {\n  display: block;\n  font-size: 16px;\n  margin-bottom: 0.5rem;\n}\n\n.xml-select-option {\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ced4da;\n  border-radius: 4px;\n}\n\n.xml-select-container {\n  width: 220px;\n  overflow: hidden;\n}\n\n.button-container {\n  text-align: right;\n}\n\n.btn-primary {\n  background-color: #007bff;\n  color: #ffffff;\n  border: none;\n  padding: 10px 20px;\n  border-radius: 4px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
