// ConfigInputs.js
import ConfigTab from "./ConfigTab";

function ConfigInputs(props) {
  const { item, config, setConfig } = props;

  return (
    <div className="config-inputs">
      <nav>
        <div className="nav nav-tabs" id={`nav-tab-${item.name}`} role="tablist">
          {Object.keys(config).map((tab, index) => {
            const isSelected = tab === "Common";
            return (
              <button
                key={`${item.name}-${index}`}
                className={`nav-link${tab === "Common" ? " active" : ""}`}
                id={`nav-${tab}-${item.name}-tab`}
                data-bs-toggle="tab"
                data-bs-target={`#nav-${tab}-${item.name}`}
                type="button"
                role="tab"
                aria-controls={`nav-${tab}-${item.name}`}
                aria-selected={isSelected}
              >
                {tab}
              </button>
            );
          })}
        </div>
      </nav>
      <div className="tab-content" id="nav-tabContent">
        {Object.keys(config).map((tab) => (
          <ConfigTab key={`${item.name}-${tab}`} item={item} tab={tab} config={config} setConfig={setConfig} />
        ))}
      </div>
    </div>
  );
}

export default ConfigInputs;
