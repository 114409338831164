import { Button } from "react-bootstrap";

function UploadPage(props) {
  const { goToMntPage, goToLocalPage } = props;

  return (
    <div>
      <Button onClick={goToMntPage}>/mnt</Button>
      <Button onClick={goToLocalPage}>Local</Button>
    </div>
  );
}

export default UploadPage;
