import "../../styles/table.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDataContext } from "../../utils/DataContext";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

function Tables() {
  return (
    <div className="silver-tables">
      <InputGroup />
      <TableGallery />
    </div>
  );
}

function InputGroup() {
  const { fetchList } = useDataContext();

  const [tablePath, setTablePath] = useState(null);

  const [showAddModal, setShowAddModal] = useState(false);
  const [deleteAllLoading, setDeleteAllLoading] = useState(false);

  const loadTable = async () => {
    if (!tablePath) {
      window.alert("Please input a table path.");
      return;
    }
    let url = `/ETL/load/table?table_path=${tablePath}`;
    axios
      .put(url)
      .then((response) => {
        fetchList();
        window.alert(`Table "${tablePath}" has been loaded successfully.`);
        setShowAddModal(false);
        setTablePath(null);
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const deleteAllTable = async () => {
    setDeleteAllLoading(true);
    axios
      .delete(`/ETL/table/delete`)
      .then((response) => {
        setDeleteAllLoading(false);
        fetchList();
      })
      .catch((error) => {
        setDeleteAllLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const addTableModal = (
    <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Load Table</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type="text"
          id="table-path"
          placeholder="Silver Table Path"
          value={tablePath}
          onChange={(e) => setTablePath(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowAddModal(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={loadTable}>
          Load
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div className="input-group">
      <div class="input-group mb-3">
        <div className="table-group">
          <div className="table-option">
            <Button variant="outline-danger">Delete Selected</Button>
            <Button
              variant="outline-danger"
              onClick={deleteAllTable}
              disabled={deleteAllLoading}
            >
              {deleteAllLoading ? "Loading..." : "Delete All"}
            </Button>
          </div>
          <div className="add-group">
            <Button variant="primary" onClick={() => setShowAddModal(true)}>
              + Load More Table
            </Button>
          </div>
        </div>
        {addTableModal}
      </div>
    </div>
  );
}

function TableGallery() {
  const { tableList } = useDataContext();

  const [checkedItems, setCheckedItems] = useState([]);

  const TablesCards = (props) => {
    const { items } = props;
    return (
      <div className="tableGallery">
        {items &&
          items.map((item) => {
            return (
              <TableItem
                key={`table-${item.name}`}
                item={item}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
              />
            );
          })}
      </div>
    );
  };

  return <TablesCards items={tableList} />;
}

function TableItem(props) {
  const { fetchList } = useDataContext();

  const { item, checkedItems, setCheckedItems } = props;

  const [checked, setChecked] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    setChecked(checkedItems.includes(item.name));
  }, [checkedItems, item.name]);

  const deleteTable = async () => {
    let url = `/ETL/table/delete?table_name=${item.name}`;
    setIsDeleting(true);
    axios
      .delete(url)
      .then((response) => {
        setIsDeleting(false);
        fetchList();
        let newCheckedItems = checkedItems.filter((name) => name !== item.name);
        setCheckedItems(newCheckedItems.slice());
      })
      .catch((error) => {
        setIsDeleting(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const handleCheckboxChange = (e) => {
    let isChecked = e.target.checked;
    setChecked(isChecked);
    let newCheckedItems = [];
    if (isChecked) {
      newCheckedItems = checkedItems.concat([item.name]);
    } else {
      newCheckedItems = checkedItems.filter((name) => name !== item.name);
    }
    setCheckedItems(newCheckedItems.slice());
  };

  return (
    <div class="card" style={{ width: "18rem" }}>
      <div class="container-fluid">
        <div class="row">
          <div class="col col-xl-6 d-flex justify-content-start px-2 py-2">
            <input
              class="form-check-input"
              type="checkbox"
              checked={checked}
              onChange={handleCheckboxChange}
              style={{ left: "0.5rem", top: "0.3rem", scale: "1.5" }}
            />
          </div>
          <div class="col col-xl-6 d-flex justify-content-end px-2 py-2">
            <button
              onClick={() => deleteTable(true)}
              type="button"
              className="btn-close"
              aria-label="Close"
            />
          </div>
        </div>
      </div>
      <div class="card-body" style={{ marginTop: "-1.0rem" }}>
        <button
          type="button"
          class="btn btn-light"
          onClick={() => {
            const w = window.open("about:blank");
            w.location.href = `http://${window.location.hostname}:5151/datasets/${item.name}`;
          }}
        >
          <h5 class="card-title">{item.name}</h5>
        </button>
        <p className="card-text">valid: {item.valid}</p>
        <p className="card-text">size: {item.size}</p>
      </div>
    </div>
  );
}

export default Tables;
