// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projectGallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}


.card {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.buttons-container {
  display: flex;
  flex-direction: column;
}

.down-button {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
}

.data-button-container {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Dataset.scss","webpack://./src/styles/Dataset.css","webpack://./src/styles/LabelStudioProject.css"],"names":[],"mappings":"AAAA;EACI,aAAA;ECCJ,mBAAA;ECCE,eAAe;AACjB;;;AAGA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".datasetGallery {\n    display: flex;\n}",".datasetGallery {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.card {\n  margin-left: 1rem;\n  margin-bottom: 1rem;\n}\n\n.card-title {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.card-body {\n  max-width: 18rem;\n}\n\n.card-text {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.add-group {\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-start;\n}\n\n.dataset-option {\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-start;\n}\n\n.dataset-group {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  margin-left: 1rem;\n  margin-right: 1rem;\n}\n",".projectGallery {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n\n.card {\n  margin-left: 1rem;\n  margin-bottom: 1rem;\n}\n\n.card-title {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.card-text {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.buttons-container {\n  display: flex;\n  flex-direction: column;\n}\n\n.down-button {\n  display: flex;\n  justify-content: flex-start;\n  margin-top: 1rem;\n}\n\n.data-button-container {\n  display: flex;\n  flex-direction: row;\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
