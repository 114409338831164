import React, { useState, useEffect } from "react";

function Timer({ loadingStates }) {
  const [timerTime, setTimerTime] = useState(0);

  useEffect(() => {
    let interval;
    if (loadingStates.some((state) => state)) {
      interval = setInterval(() => {
        setTimerTime((prevTime) => prevTime + 0.1);
      }, 100);
    } else {
      clearInterval(interval);
      setTimerTime(0);
    }
    return () => {
      clearInterval(interval);
    };
  }, [loadingStates]);

  return <span>{timerTime.toFixed(1)}s</span>;
}

export default Timer;
