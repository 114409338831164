import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import BackButton from "../BackButton/BackButton";
import CopyPathPage from "./pages/CopyPathPage";
import LocalPage from "./pages/LocalPage";
import MainPage from "./pages/MainPage";

function UploadModal(props) {
  const { item, show, onHide } = props;

  const [currentPage, setCurrentPage] = useState("main");

  const goToCopyPathPage = () => {
    setCurrentPage("CopyPathPage");
  };

  const goToLocalPage = () => {
    setCurrentPage("LocalPage");
  };

  const goBackToMainPage = () => {
    setCurrentPage("main");
  };

  return (
    <Modal {...props} size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <div style={{ display: "flex", alignItems: "center" }}>
          {currentPage !== "main" && <BackButton onClick={goBackToMainPage} />}
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "10px" }}
          >
            Choose Data
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        {currentPage === "main" && (
          <MainPage
            goToCopyPathPage={goToCopyPathPage}
            goToLocalPage={goToLocalPage}
          />
        )}
        {currentPage === "CopyPathPage" && (
          <CopyPathPage onHide={props.onHide} item={item} />
        )}
        {currentPage === "LocalPage" && (
          <LocalPage onHide={props.onHide} item={item} />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default UploadModal;
