import { Button, Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useState, useEffect } from "react";
import axios from "axios";
import Timer from "../../Timer";
import { VIDEO_EXTS } from "../../../constant/FileType";
import { useDataContext } from "../../../utils/DataContext";

const LinkStyle = {
  color: "blue",
  textDecoration: "underline",
  cursor: "pointer",
};

function LocalPage(props) {
  const { item, onHide } = props;

  const { fetchList } = useDataContext();

  const [file, setFile] = useState(null);
  const [fps, setFps] = useState(null);
  const [imgQuality, setImgQuality] = useState(100);
  const [youtubeLink, setYoutubeLink] = useState(null);

  const [fileLoading, setFileLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const handleChangeImgQuality = (e) => {
    let newSplitNum = e.target.value;
    if (newSplitNum < 1 || newSplitNum > 100) {
      if (!newSplitNum) {
        setImgQuality(100);
      }
      return;
    }
    setImgQuality(newSplitNum);
  };

  const isVideoFile = () => {
    if (!file || !file.name) {
      return false;
    }
    const exts = file.name.split(".").pop();
    return VIDEO_EXTS.includes(exts);
  };

  const uploadFile = async () => {
    if (!item.name) {
      window.alert("No folder name.");
      return;
    }
    if (!file) {
      window.alert("Please upload a file.");
      return;
    }
    setFileLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    let url = `/file/upload?folder_name=${item.name}`;
    if (fps) {
      url += `&fps=${fps}`;
    }
    if (imgQuality) {
      if (imgQuality < 1 || imgQuality > 100) {
        window.alert("Image quality should between 1 and 100.");
        return;
      }
      url += `&image_quality=${imgQuality}`;
    }
    axios
      .post(url, formData)
      .then((response) => {
        setFileLoading(false);
        fetchList();
        let msg = `File '${file.name}' upload success.`;
        window.alert(msg);
        onHide();
      })
      .catch((error) => {
        setFileLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const downloadYouTube = async () => {
    if (!youtubeLink) {
      window.alert("Please enter a YouTube Link.");
      return;
    }
    setDownloading(true);
    let url = `/file/video/youtube?folder_name=${item.name}&link=${youtubeLink}`;
    axios
      .put(url)
      .then((response) => {
        setDownloading(false);
        let msg = `Download YouTube video to folder '${item.name}' success.`;
        window.alert(msg);
      })
      .catch((error) => {
        setDownloading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const frameRateTooltip = (
    <div>
      <p>
        <a
          onClick={() => {
            const w = window.open("about:blank");
            w.location.href = `https://en.wikipedia.org/wiki/Frame_rate`;
          }}
          style={LinkStyle}
        >
          <strong>Frame Rate</strong>
        </a>{" "}
        is typically the frequency at which consecutive images are captured or
        displayed.{" "}
      </p>
      <p>
        If the input frame rate is greater than 5, it will take 5 to convert to
        frames.
      </p>
    </div>
  );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div class="file-input" style={{ width: "100%" }}>
        <input
          id="file-upload"
          // style={{ width: "100%" }}
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
        />
      </div>
      {file && isVideoFile() && item.type === "image" && (
        <div style={{ margin: "1rem", width: "100%" }}>
          <label htmlFor="frame-rate">
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-bottom" placement="bottom">
                  {frameRateTooltip}
                </Tooltip>
              }
              placement="bottom"
            >
              <a
                onClick={() => {
                  const w = window.open("about:blank");
                  w.location.href = `https://en.wikipedia.org/wiki/Frame_rate`;
                }}
                style={LinkStyle}
              >
                <strong>Frame Rate</strong>
              </a>
            </OverlayTrigger>
          </label>
          <input
            id="frame-rate"
            type="number"
            value={fps}
            onChange={(e) => setFps(e.target.value)}
          />
          <label htmlFor="image-quality">Image Quality in %</label>
          <input
            id="image-quality"
            type="number"
            value={imgQuality}
            max={100}
            min={1}
            onChange={handleChangeImgQuality}
          />
        </div>
      )}
      {item.type == "video" && (
        <div style={{ width: "100%" }}>
          <label htmlFor="youtube-link">YouTube Link:</label>
          <input
            id="youtube-link"
            type="text"
            value={youtubeLink}
            onChange={(e) => setYoutubeLink(e.target.value)}
          />
          <Button onClick={downloadYouTube} disabled={downloading}>
            {downloading ? (
              <span>
                <Timer loadingStates={[downloading]} />
              </span>
            ) : (
              "Download"
            )}
          </Button>
        </div>
      )}
      <Modal.Footer>
        {fileLoading ? (
          <p className="loading-text">
            Tips: You can click X to leave here, we will continue to process
            your request in the background.
          </p>
        ) : null}
        <Button onClick={uploadFile} disabled={fileLoading}>
          {fileLoading ? (
            <span>
              <Timer loadingStates={[fileLoading]} />
            </span>
          ) : (
            "Confirm"
          )}
        </Button>
      </Modal.Footer>
    </div>
  );
}

export default LocalPage;
