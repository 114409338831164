// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dataset-customized-content {
  display: flex;
  flex-direction: column;
}

.input-content,
.delete-redundant-page,
.delete-samples-page,
.down-sampling-page,
.split-page,
.picker-page,
.cutter-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.sample-rate-input {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.target-dataset-name {
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/DatasetPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;;;;;;;EAOE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".dataset-customized-content {\n  display: flex;\n  flex-direction: column;\n}\n\n.input-content,\n.delete-redundant-page,\n.delete-samples-page,\n.down-sampling-page,\n.split-page,\n.picker-page,\n.cutter-page {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.Button {\n  margin-top: 0.5rem;\n  margin-bottom: 0.5rem;\n}\n\n.sample-rate-input {\n  margin-top: 0.25rem;\n  margin-bottom: 0.25rem;\n}\n\n.target-dataset-name {\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
