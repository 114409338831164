import "../../styles/Dataset.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DeleteDoudleConfirmModal from "./DeleteDoudleConfirmModal";
import { SpinnerCircularSplit } from "spinners-react";
import { Tabs, Tab, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import EmptyDataset from "./EmptyDataset";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import logger from "redux-logger";

function Datasets(props) {
  const { CustomizedContent, GlobalCustomizedContent } = props;

  const [datasetItems, setDatasetItems] = useState([]);
  const [checkedDatasetItems, setCheckedDatasetItems] = useState([]);

  const [datasetsLoading, setDatasetsLoading] = useState(false);

  const userToken = Cookies.get("user_token");
  const userData = userToken ? jwtDecode(userToken) : null;
  const role = userData ? userData.role : null;
  const organization = userData ? userData.organization : null;

  const loadDatasetList = async () => {
    let method = "get";
    let data = null;
    if (role === "admin" || organization) {
      method = "post";
      data = { requesterToken: userToken };
    }
    setDatasetsLoading(true);
    axios({
      method: method,
      url: "/datasets/list",
      data: data,
    })
      .then((response) => {
        const newDatasetItems = response.data;
        setDatasetItems(newDatasetItems.concat());
        setDatasetsLoading(false);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
        setDatasetsLoading(false);
      });
  };

  useEffect(() => {
    loadDatasetList();
  }, []);

  const navigate = useNavigate();

  if (!userData) {
    navigate("/signin");
  }

  const authorizedRoles = ["admin", "developer", "annotator"];

  if (!authorizedRoles.includes(role)) {
    return (
      <div>
        <h1>Insufficient permissions</h1>
        <p>
          Your role is <strong>"{role}"</strong> and you cannot make any changes
          to the dataset.
        </p>
        <p>
          If you want to operate the dataset, please contact the admin user to
          help upgrade your role to <strong>"developer"</strong>.
        </p>
      </div>
    );
  }

  return (
    <div className="datasets">
      <InputSet
        loadDatasetList={loadDatasetList}
        checkedDatasetItems={checkedDatasetItems}
        setCheckedDatasetItems={setCheckedDatasetItems}
      />
      {GlobalCustomizedContent && (
        <GlobalCustomizedContent
          loadDatasetList={loadDatasetList}
          checkedDatasetItems={checkedDatasetItems}
          setCheckedDatasetItems={setCheckedDatasetItems}
        />
      )}
      <DatasetGallery
        loadDatasetList={loadDatasetList}
        datasetItems={datasetItems}
        CustomizedContent={CustomizedContent}
        checkedDatasetItems={checkedDatasetItems}
        setCheckedDatasetItems={setCheckedDatasetItems}
        datasetsLoading={datasetsLoading}
      />
    </div>
  );
}

function InputSet(props) {
  const { loadDatasetList, checkedDatasetItems, setCheckedDatasetItems } =
    props;

  const [datasetName, setDatasetName] = useState(null);
  const [mediatype, setMediatype] = useState("image");
  const [deleteMethod, setDeleteMethod] = useState(null);
  const [mergeDatasetName, setMergeDatasetName] = useState(null);
  const [groupName, setGroupName] = useState(null);
  const [selectedDatasets, setSelectedDatasets] = useState([]);

  const [showCreateDatasetModal, setShowCreateDatasetModal] = useState(false);
  const [showMergeModal, setShowMergeModal] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [deleteAllLoading, setDeleteAllLoading] = useState(false);

  const userToken = Cookies.get("user_token");
  const userData = userToken ? jwtDecode(userToken) : null;
  const organization = userData ? userData.organization : null;

  const handleShowCreateDatasetModal = () => {
    setShowCreateDatasetModal(true);
  };

  const handleCloseCreateDatasetModal = () => {
    setShowCreateDatasetModal(false);
  };

  const handleCloseMergeModal = () => {
    setShowMergeModal(false);
  };

  const handleCloseGroupModal = () => {
    setShowGroupModal(false);
  };

  const createDataset = async () => {
    if (!datasetName) {
      window.alert("Please input a dataset name.");
      return;
    }
    let url = `/datasets/create?dataset_name=${datasetName}`;
    if (mediatype) {
      url += `&media_type=${mediatype}`;
    }
    let method = "put";
    let data = null;
    if (organization) {
      method = "post";
      data = { requesterToken: userToken };
    }
    axios({
      method: method,
      url: url,
      data: data,
    })
      .then((response) => {
        loadDatasetList();
        window.alert(`Dataset "${datasetName}" has been created successfully.`);
        handleCloseCreateDatasetModal();
        setDatasetName(null);
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const mergeDataset = () => {
    if (!checkedDatasetItems || checkedDatasetItems.length === 0) {
      window.alert("Please select dataset.");
      return;
    }
    setSelectedDatasets(checkedDatasetItems);
    setShowMergeModal(true);
  };

  const groupDataset = () => {
    if (!checkedDatasetItems || checkedDatasetItems.length === 0) {
      window.alert("Please select dataset.");
      return;
    }
    setSelectedDatasets(checkedDatasetItems);
    setShowGroupModal(true);
  };

  const performMerge = async () => {
    if (!mergeDatasetName) {
      window.alert("Please input a dataset name.");
      return;
    }
    axios
      .post(
        `/datasets/merge?dataset_name=${mergeDatasetName}`,
        selectedDatasets
      )
      .then((response) => {
        setMergeDatasetName("");
        setSelectedDatasets([]);
        setShowMergeModal(false);
        loadDatasetList();
        window.alert(`Datasets have been merged successfully.`);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const moveToGroup = async () => {
    if (!groupName) {
      window.alert("Please input a group name.");
      return;
    }
    let url = `/datasets/group?group_name=${groupName}`;
    perfromGroup(url);
  };

  const removeFromGroup = async () => {
    let url = `/datasets/group`;
    perfromGroup(url);
  };

  const perfromGroup = async (url) => {
    axios
      .post(url, selectedDatasets)
      .then((response) => {
        setGroupName(null);
        setSelectedDatasets([]);
        setShowGroupModal(false);
        loadDatasetList();
        window.alert(`Move datasets to/from group successfully.`);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const deleteAllDataset = async () => {
    setDeleteAllLoading(true);
    let method = "delete";
    let data = null;
    if (organization) {
      method = "post";
      data = { requesterToken: userToken };
    }
    axios({
      method: method,
      url: `/datasets/delete`,
      data: data,
    })
      .then((response) => {
        setDeleteAllLoading(false);
        window.alert(
          `The deletion of all datasets was completed successfully.`
        );
        loadDatasetList();
      })
      .catch((error) => {
        setDeleteAllLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const deleteSelected = async () => {
    if (!checkedDatasetItems || checkedDatasetItems.length === 0) {
      window.alert("Please select dataset.");
      return;
    }
    axios
      .post(`/datasets/delete/selected`, checkedDatasetItems)
      .then((response) => {
        window.alert(`The selected datasets have been successfully deleted.`);
        loadDatasetList();
        setCheckedDatasetItems([]);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const handleChangeDatasetName = (e) => {
    let newValue = e.target.value;
    setDatasetName(newValue);
  };

  const mediaTypeDisplay = (
    <div className="media-type-select-option-container">
      <label htmlFor="media-type-select">media type:</label>
      <select
        id="media-type-select"
        value={mediatype}
        onChange={(e) => {
          setMediatype(e.target.value);
        }}
        className="media-type-select-option"
      >
        <option value="image">image</option>
        <option value="video">video</option>
      </select>
    </div>
  );

  const role = userData ? userData.role : null;

  const CreateDatasetModal = (
    <Modal show={showCreateDatasetModal} onHide={handleCloseCreateDatasetModal}>
      <Modal.Header closeButton>
        <Modal.Title>Add new dataset</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type="text"
          id="dataset-name"
          placeholder="Dataset Name"
          value={datasetName}
          onChange={handleChangeDatasetName}
        />
        {role !== "annotator" && mediaTypeDisplay}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseCreateDatasetModal}>
          Close
        </Button>
        <Button variant="primary" onClick={createDataset}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const mergeModal = (
    <Modal show={showMergeModal} onHide={handleCloseMergeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Merge Datasets</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type="text"
          id="merge-dataset-name"
          placeholder="New Dataset Name"
          value={mergeDatasetName}
          onChange={(e) => setMergeDatasetName(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseMergeModal}>
          Close
        </Button>
        <Button variant="primary" onClick={performMerge}>
          Merge
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const groupModal = (
    <Modal show={showGroupModal} onHide={handleCloseGroupModal}>
      <Modal.Header closeButton>
        <Modal.Title>Merge Datasets</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type="text"
          id="group-dataset-name"
          placeholder="Group Name"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={moveToGroup}>
          Add to Group
        </Button>
        <Button variant="outline-danger" onClick={removeFromGroup}>
          Remove from Group
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div>
      <div className="input-group">
        <div className="input-group mb-3">
          <div className="dataset-group">
            <div className="dataset-option">
              {/* <Button variant="success" onClick={mergeDataset}>
                Merge
              </Button> */}
              <Button variant="secondary" onClick={groupDataset}>
                Group
              </Button>
              <Button
                variant="outline-danger"
                onClick={() => {
                  setDeleteMethod("deleteSelected");
                  setModalShow(true);
                }}
              >
                Delete Selected
              </Button>
              {role && role === "admin" && (
                <Button
                  variant="outline-danger"
                  onClick={() => {
                    setModalShow(true);
                  }}
                  disabled={deleteAllLoading}
                >
                  {deleteAllLoading ? "Loading..." : "Delete All"}
                </Button>
              )}
            </div>
            {mergeModal}
            {groupModal}
            <div className="add-group">
              <Button variant="primary" onClick={handleShowCreateDatasetModal}>
                + Create new dataset
              </Button>
            </div>
          </div>
        </div>
        {CreateDatasetModal}
      </div>
      <DeleteDoudleConfirmModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        resetDeleteMethod={() => setDeleteMethod(null)}
        deleteMethod={deleteMethod}
        onDelete={deleteAllDataset}
        onDeleteSelected={deleteSelected}
      />
    </div>
  );
}

function DatasetGallery(props) {
  const {
    loadDatasetList,
    datasetItems,
    CustomizedContent,
    checkedDatasetItems,
    setCheckedDatasetItems,
    datasetsLoading,
  } = props;

  const [dbMapList, setDbMapList] = useState([]);

  const userToken = Cookies.get("user_token");
  const userData = userToken ? jwtDecode(userToken) : null;
  const role = userData ? userData.role : null;
  const organization = userData ? userData.organization : null;

  const loadDbMapList = async () => {
    axios
      .get("/kneron/global_constant/dbmap/list")
      .then((response) => {
        setDbMapList(response.data);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const deleteDataset = async (datasetName) => {
    let method = "delete";
    let data = null;
    if (role === "admin" || organization) {
      method = "post";
      data = { requesterToken: userToken };
    }
    axios({
      method: method,
      url: `/datasets/delete?dataset_name=${datasetName}`,
      data: data,
    })
      .then((response) => {
        loadDatasetList();
        let newCheckedDatasetItems = checkedDatasetItems.filter(
          (name) => name !== datasetName
        );
        setCheckedDatasetItems(newCheckedDatasetItems.slice());
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  useEffect(() => {
    loadDbMapList();
  }, []);

  const groupItems = {};
  datasetItems.forEach((item) => {
    if (item.group) {
      if (!groupItems[item.group]) {
        groupItems[item.group] = [];
      }
      groupItems[item.group].push(item);
    }
  });

  let commonDatasetItems = datasetItems.filter(
    (item) => item.folder !== "Yes" && item.table !== "Yes"
  );
  if (role === "annotator") {
    commonDatasetItems = commonDatasetItems.filter(
      (item) => item.media_type === "image"
    );
  }
  const folderDatasetItems = datasetItems.filter(
    (item) => item.folder === "Yes"
  );
  const tableDatasetItems = datasetItems.filter((item) => item.table === "Yes");
  const restDatasetItems = commonDatasetItems.filter((item) => !item.group);
  const imageDatasetItems = commonDatasetItems.filter(
    (item) => item.media_type === "image"
  );
  const videoDatasetItems = commonDatasetItems.filter(
    (item) => item.media_type === "video"
  );

  const DatasetCards = (props) => {
    const { items } = props;
    return (
      <div className="datasetGallery">
        {items &&
          items.map((item) => {
            return (
              <DatasetItem
                key={`dataset-${item.name}`}
                item={item}
                CustomizedContent={CustomizedContent}
                loadDatasetList={loadDatasetList}
                dbMapList={dbMapList}
                deleteDataset={deleteDataset}
                checkedDatasetItems={checkedDatasetItems}
                setCheckedDatasetItems={setCheckedDatasetItems}
              />
            );
          })}
      </div>
    );
  };

  const tabItems = {
    COMMON: commonDatasetItems,
    IMAGE: imageDatasetItems,
    VIDEO: videoDatasetItems,
    FOLDER: folderDatasetItems,
    TABLE: tableDatasetItems,
    ALL: datasetItems,
  };
  let displayTabItems;
  if (role === "annotator") {
    displayTabItems = {
      All: commonDatasetItems,
    };
  } else {
    displayTabItems = tabItems;
  }

  const deleteGroup = async (groupName) => {
    const url = `/datasets/group?group_name=${groupName}`;
    axios
      .delete(url)
      .then((response) => {
        loadDatasetList();
        window.location.reload();
        window.alert(`Delete group '${groupName}' success.`);
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const deleteTabBtnStyle = {
    marginLeft: "5px",
    width: "0.7rem",
    height: "0.7rem",
  };

  const GroupDataset = (
    <Tabs defaultActiveKey="All-datasets">
      {groupItems &&
        Object.entries(groupItems).map(([name, items]) => {
          const title = (
            <div>
              {name}
              <button
                onClick={() => deleteGroup(name)}
                type="button"
                class="btn-close"
                style={deleteTabBtnStyle}
                aria-label="Close"
              />
            </div>
          );
          return (
            <Tab eventKey={name} title={title}>
              <DatasetCards items={items} />
            </Tab>
          );
        })}
      {tabItems &&
        Object.entries(displayTabItems).map(([title, items]) => {
          return (
            <Tab eventKey={`${title}-datasets`} title={title}>
              <DatasetCards items={items} />
            </Tab>
          );
        })}
      <Tab eventKey="rest-datasets" title="Non-Grouped">
        <DatasetCards items={restDatasetItems} />
      </Tab>
    </Tabs>
  );

  let display;
  if (datasetsLoading) {
    display = <SpinnerCircularSplit size="5.5rem" thickness={125} />;
  } else if (datasetItems.length === 0) {
    display = (
      <div>
        <h1>No dataset in your workspace.</h1>
        <p>
          Please click <strong>+ Create new dataset</strong> to create one.
        </p>
      </div>
    );
  } else {
    display = GroupDataset;
  }

  return <div className="Tabs-datasetGallery">{display}</div>;
}

function DatasetItem(props) {
  const {
    item,
    CustomizedContent,
    loadDatasetList,
    dbMapList,
    deleteDataset,
    checkedDatasetItems,
    setCheckedDatasetItems,
  } = props;

  const [checked, setChecked] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    setChecked(checkedDatasetItems.includes(item.name));
  }, [checkedDatasetItems, item.name]);

  const handleCheckboxChange = (e) => {
    let isChecked = e.target.checked;
    setChecked(isChecked);
    let newCheckedDatasetItems = [];
    if (isChecked) {
      newCheckedDatasetItems = checkedDatasetItems.concat([item.name]);
    } else {
      newCheckedDatasetItems = checkedDatasetItems.filter(
        (name) => name !== item.name
      );
    }
    setCheckedDatasetItems(newCheckedDatasetItems.slice());
  };

  const resetDeleteMethod = () => {
    setModalShow(false);
  };

  const location = useLocation();

  let display;
  if (location.pathname !== "/acquisition" && item.samples.length === 0) {
    display = <EmptyDataset />;
  } else {
    display = CustomizedContent && (
      <CustomizedContent
        item={item}
        loadDatasetList={loadDatasetList}
        dbMapList={dbMapList}
      />
    );
  }

  return (
    <div class="card" style={{ width: "18rem" }} key={item.name}>
      <div class="container-fluid">
        <div class="row">
          <div class="col col-xl-6 d-flex justify-content-start px-2 py-2">
            <input
              class="form-check-input"
              type="checkbox"
              checked={checked}
              onChange={handleCheckboxChange}
              style={{ left: "0.5rem", top: "0.3rem", scale: "1.5" }}
            />
          </div>
          <div class="col col-xl-6 d-flex justify-content-end px-2 py-2">
            <button
              onClick={() => setModalShow(true)}
              type="button"
              class="btn-close"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
      <div class="card-body" style={{ marginTop: "-1.0rem" }}>
        <button
          type="button"
          class="btn btn-light"
          onClick={() => {
            const w = window.open("about:blank");
            w.location.href = `http://${window.location.hostname}:5151/datasets/${item.name}`;
          }}
        >
          <h5 class="card-title">{item.name}</h5>
        </button>
        {display}
      </div>
      <DeleteDoudleConfirmModal
        item={item}
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={deleteDataset}
        datasetName={item.name}
        resetDeleteMethod={resetDeleteMethod}
      />
    </div>
  );
}

export { Datasets };
