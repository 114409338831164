import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import Timer from "../../Timer";
import "./ImportStorageModal.css"

function ImportStorageModal(props) {
  const { show, onHide, item, loadProjectList, folders } = props;

  const [title, setTitle] = useState(null);
  const [folder, setFolder] = useState(null);
  const [regex, setRegex] = useState(null);

  const [folderLoading, setFolderLoading] = useState(false);

  const importFolder = async () => {
    if (!folder) {
      window.alert(`No folder name.`);
      return;
    }
    let url = `/label_studio/project/import/folder?project_id=${item.id}&folder_name=${folder}`;
    if (title) {
      url += `&title=${title}`;
    }
    if (regex) {
      url += `&regex_filter=${regex}`;
    }
    setFolderLoading(true);
    axios
      .put(url)
      .then((response) => {
        setFolderLoading(false);
        loadProjectList();
        let msg = `Import folder '${folder}' success, you can go to label studio webiste to config`;
        window.alert(msg);
        onHide();
      })
      .catch((error) => {
        setFolderLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  return (
    <Modal show={show} onHide={onHide} className="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title>Import Storage</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="folder-select-option-container">
        <label htmlFor="storage-title">Folder</label>
          <select
            id="folder-select"
            value={folder}
            onChange={(e) => {
              setFolder(e.target.value);
            }}
            className="folder-select-option"
          >
            <option value="">Please choose an option</option>
            {folders &&
              folders.map((folder) => (
                <option key={`${item.name}-${folder.name}-${folder.type}`} value={folder.name}>
                  {folder.name}
                </option>
              ))}
          </select>
        </div>
        <div>
          <label htmlFor="storage-title">Storage Title</label>
          <input
            id="storage-title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="file-filter-regex">File Filter Regex</label>
          <input
            id="file-filter-regex"
            type="text"
            value={regex}
            placeholder=".*csv or .*(jpe?g|png|tiff) or .\w+-\d+.test"
            onChange={(e) => setRegex(e.target.value)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={importFolder} disabled={folderLoading}>
          {folderLoading ? (
            <span>
              <Timer loadingStates={[folderLoading]} />
            </span>
          ) : (
            "Import Storage"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ImportStorageModal;
