// ConfigModal.js
import axios from "axios";
import { useState } from "react";
import ConfigInputs from "./ConfigInputs";
import { Modal, Button } from 'react-bootstrap'

function useSaveConfig() {
  const [loading, setLoading] = useState(false);

  const saveConfig = async (item, config, onHide) => {
    setLoading(true);
    try {
      await axios.post(`/ETL/label_studio/config/save?project_id=${item.id}`, config);
      window.alert(`Save config on dataset '${item.title}' success`);
      onHide(); // 请求成功后关闭 Modal
    } catch (error) {
      let errorMsg = error.response.data;
      window.alert(errorMsg);
    }
    setLoading(false);
  };


  return [loading, saveConfig];
}

function ConfigModal(props) {
  const { show, onHide, item, config, setConfig } = props;
  const [loading, saveConfig] = useSaveConfig();

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">ETL Input</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ConfigInputs item={item} config={config} setConfig={setConfig} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => { }}>
          Close
        </Button>
        <Button variant="primary" onClick={() => saveConfig(item, config, onHide)} disabled={loading}>
          {loading ? "Loading..." : "Save"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfigModal;
