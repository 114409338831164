import { Datasets } from "../../components/Dataset/Dataset";
import "react-dropdown/style.css";
import "../../styles/AcquisitionDataPage.css";
import Modal from "react-modal";
import NavigationBar from "../../components/NavBar/NavBar";
import CustomizedContent from "./CustomizedContent";
import { Tabs, Tab } from "react-bootstrap";
import { Folders } from "../../components/Folder/Folder";
import {
  useDataContext,
  AcquisitionDataProvider,
} from "../../utils/DataContext";
import Tables from "../../components/Table/Table";
import { getUserCookie } from "../../utils/cookiesUtils";

Modal.setAppElement("#root");

function AcquisitionDataPage() {
  return (
    <AcquisitionDataProvider>
      <AcquisitionDataPageChild />
    </AcquisitionDataProvider>
  );
}

function AcquisitionDataPageChild() {
  const { availSpace } = useDataContext();

  const userData = getUserCookie();
  const role = userData ? userData.role : null;

  let display = <Datasets CustomizedContent={CustomizedContent} />;
  if (role !== "annotator") {
    <Tabs defaultActiveKey="acquisition-data-dataset">
      <Tab eventKey="acquisition-data-dataset" title="Datasets">
        <Datasets CustomizedContent={CustomizedContent} />
      </Tab>
      <Tab eventKey="acquisition-data-folder" title="Folders">
        <Folders />
      </Tab>
      <Tab eventKey="acquisition-data-table" title="Tables">
        <Tables />
      </Tab>
    </Tabs>;
  }

  return (
    <div className="acquisition-data-page">
      <NavigationBar />
      <strong>System available space: {availSpace}</strong>
      {display}
    </div>
  );
}

export default AcquisitionDataPage;
