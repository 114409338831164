import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import Timer from "../../Timer";
import axios from "axios";
import { useDataContext } from "../../../utils/DataContext";

function CopyPathPage(props) {
  const { item, onHide } = props;

  const { fetchList } = useDataContext();

  const [mntPath, setMntPath] = useState("");
  const [copyLoading, setCopyLoading] = useState(false);

  const handleConfirm = async () => {
    if (!item.name) {
      window.alert("No folder name.");
      return;
    }

    if (!mntPath) {
      window.alert("Please type your path.");
      return;
    }
    setCopyLoading(true);
    const url = `/file/audio/copy?folder_name=${item.name}&path=${mntPath}`;
    axios
      .put(url)
      .then((response) => {
        setCopyLoading(false);
        fetchList();
        let msg = `Copy data from '${mntPath}' success.`;
        window.alert(msg);
        onHide();
      })
      .catch((error) => {
        setCopyLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  return (
    <div>
      <div>
        <label htmlFor="input-field">Please Type Your Path</label>
      </div>
      <div>
        <input
          id="input-field"
          type="text"
          value={mntPath}
          onChange={(e) => setMntPath(e.target.value)}
        />
      </div>
      <Modal.Footer>
        {copyLoading ? (
          <p className="loading-text">
            Tips: You can click X to leave here, we will continue to process
            your request in the background.
          </p>
        ) : null}
        <Button onClick={handleConfirm} disabled={copyLoading}>
          {copyLoading ? (
            <span>
              <Timer loadingStates={[copyLoading]} />
            </span>
          ) : (
            "Confirm"
          )}
        </Button>
      </Modal.Footer>
    </div>
  );
}

export default CopyPathPage;
