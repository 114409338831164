import "../../styles/Dataset.css";
import "./ETLPage.css";
import axios from "axios";
import { Datasets } from "../../components/Dataset/Dataset";
import NavigationBar from "../../components/NavBar/NavBar";
import ConfigModal from "./ConfigModal/ConfigModal";
import { ETLConfig } from "../../constant/ETLconfig";
import Timer from "../../components/Timer";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";

function ETLPage() {
  return (
    <div>
      <NavigationBar />
      <div className="ETL-page">
        <Datasets CustomizedContent={CustomizedContent} />
        {/* <p>I am ETL page</p> */}
      </div>
    </div>
  );
}

function CustomizedContent(props) {
  let { item } = props;

  let [xmlFile, setXmlFile] = useState(null);
  let [config, setConfig] = useState(ETLConfig);
  let [loading, setLoading] = useState(false);
  let [reading, setReading] = useState(false);

  const loadConfig = async () => {
    setReading(true);
    axios
      .get(`/ETL/config/read?dataset_name=${item.name}`)
      .then((response) => {
        setReading(false);
        let config = response.data;
        if (config) {
          setConfig(config);
        }
      })
      .catch((error) => {
        setReading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const loadETL = async () => {
    if (!xmlFile) {
      window.alert(`Please select an xml file.`);
    }
    setLoading(true);
    axios
      .put(`/ETL/table/dump?dataset_name=${item.name}&file_name=${xmlFile}`)
      .then((response) => {
        setLoading(false);
        window.alert(`ETL labels in dataset '${item.name}' to table success`);
      })
      .catch((error) => {
        setLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  return (
    <div className="ETL-customized-content">
      <p className="card-text">
        config saved:
        {item.config_saved ? "Yes" : "No"}
      </p>
      <div class="input-content">
        <Button
          variant="secondary"
          class="btn btn-secondary"
          type="button"
          data-bs-toggle="modal"
          data-bs-target={`#input-config-${item.name}`}
          onClick={loadConfig}
          disabled={reading}
        >
          {reading ? "Loading..." : "Config"}
        </Button>
        {item.xml_files && (
          <div class="input-content">
            <div className="xml-select">
              <label htmlFor="class-select">Choose xml:</label>
              <div className="xml-select-container">
                <select
                  id="xml-select"
                  value={xmlFile}
                  onChange={(e) => setXmlFile(e.target.value)}
                  className="xml-select-option"
                >
                  <option value="">Please choose an option</option>
                  {item.xml_files &&
                    item.xml_files.map((file) => (
                      <option key={file} value={file}>
                        {file}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="button-container">
              <Button
                variant="primary"
                type="button"
                onClick={loadETL}
                disabled={loading}
              >
                {loading ? (
                  <span>
                    <Timer loadingStates={[loading]} />
                  </span>
                ) : (
                  "ETL"
                )}
              </Button>
            </div>
          </div>
        )}
      </div>
      <ConfigModal item={item} config={config} setConfig={setConfig} />
    </div>
  );
}

export default ETLPage;
