import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from '../src/redux/store'
import { Provider } from 'react-redux';
import { setAuthToken } from './utils/validations/setAuthToken';
import { setCurrentUser } from './redux/actions/authAction';
import jwtDecode from 'jwt-decode';


const root = ReactDOM.createRoot(document.getElementById('root'));
setAuthToken(localStorage.token)
if(localStorage.token){
  setAuthToken(localStorage.token)
  store.dispatch(setCurrentUser(jwtDecode(localStorage.token)))
}
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();