import axios from "axios";
import { useState } from "react";
import { Datasets } from "../../components/Dataset/Dataset";
import { Modal, Button } from "react-bootstrap";
import Timer from "../../components/Timer";
import "../../styles/LabelsPage.css";
import "./ExportPage.css";
import NavigationBar from "../../components/NavBar/NavBar";

function ExportPage() {
  return (
    <div>
      <NavigationBar />
      <div className="Export-page">
        <Datasets CustomizedContent={CustomizedContent} />
      </div>
    </div>
  );
}

function CustomizedContent(props) {
  const { item } = props;

  const [showModel, setShowModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const quickETL = async () => {
    const url = `/ETL/table/dump?dataset_name=${item.name}&default=true`;
    setLoading(true);
    axios
      .put(url)
      .then((response) => {
        setLoading(false);
        window.alert(`ETL labels in dataset '${item.name}' to table success`);
      })
      .catch((error) => {
        setLoading(false);
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const downloadData = async () => {
    let url = `/datasets/export?dataset_name=${item.name}`;
    url += "&overwrite=true&reformat=true&download=true";
    axios
      .get(url, {
        responseType: "blob", // important
      })
      .then((response) => {
        setDownloading(false);
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${item.name}_labels.xml`); //or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        window.alert(`Download dataset '${item.name}' success`);
      })
      .catch((error) => {
        setDownloading(false);
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const hasLabel =
    item.sample_label_fields && item.sample_label_fields.length > 0;
  const hasLabelStyle = hasLabel
    ? { backgroundColor: "lightblue" }
    : { backgroundColor: "lightcoral" };

  return (
    <div className="export-customized-content">
      <p className="card-text" style={hasLabelStyle}>
        has label:
        {hasLabel ? "Yes" : "No"}
      </p>
      <Button style={{ margin: "2.5px" }} onClick={quickETL}>
        {loading ? (
          <span>
            <Timer loadingStates={[loading]} />
          </span>
        ) : (
          "Quick ETL"
        )}
      </Button>
      <Button
        variant="outline-secondary"
        style={{ margin: "2.5px" }}
        onClick={downloadData}
      >
        {downloading ? (
          <span>
            <Timer loadingStates={[downloading]} />
          </span>
        ) : (
          "Download Data"
        )}
      </Button>
      <ExportPageModal
        show={showModel}
        onHide={() => setShowModel(false)}
        {...props}
      />
    </div>
  );
}

function ExportPageModal(props) {
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      isOpen={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "10px" }}
          >
            Download Page
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <DownloadPage {...props} />
      </Modal.Body>
    </Modal>
  );
}

function DownloadPage(props) {
  const { item, loadDatasetList } = props;

  const [fileName, setFileName] = useState(null);
  const [download, setDownload] = useState(false);
  const [overwrite, setOverwrite] = useState(false);
  const [reformat, setReformat] = useState(false);

  const [loadAnnoLoading, setLoadAnnoLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [exportLabelLoading, setExportLabelLoading] = useState(false);

  const loadAnno = async () => {
    setLoadAnnoLoading(true);
    axios
      .get(`/datasets/load_annotations?dataset_name=${item.name}&`)
      .then((response) => {
        setLoadAnnoLoading(false);
        loadDatasetList();
        window.alert(`Load label on dataset '${item.name}' success`);
      })
      .catch((error) => {
        setLoadAnnoLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const sdkDownloadAnno = async () => {
    setDownloadLoading(true);
    axios
      .get(`/sdk/datasets/load_annotations?dataset_name=${item.name}&`)
      .then((response) => {
        setDownloadLoading(false);
        window.alert(`SDK downLoad label on dataset '${item.name}' success`);
      })
      .catch((error) => {
        setDownloadLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const export_label = async () => {
    setExportLabelLoading(true);
    let url = `/datasets/export?dataset_name=${item.name}`;
    if (fileName) {
      url += `&file_name=${fileName}`;
    }
    if (overwrite) {
      url += `&overwrite=${overwrite}`;
    }
    if (reformat) {
      url += `&reformat=${reformat}`;
    }
    if (download) {
      dowanload_label(url);
      return;
    }
    axios
      .get(url)
      .then((response) => {
        setExportLabelLoading(false);
        loadDatasetList();
        window.alert(`Export dataset '${item.name}' success`);
      })
      .catch((error) => {
        setExportLabelLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const dowanload_label = async (url) => {
    url += `&download=${download}`;
    axios
      .get(url, {
        responseType: "blob", // important
      })
      .then((response) => {
        setExportLabelLoading(false);
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${item.name}_labels.xml`); //or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        loadDatasetList();
        window.alert(`Export dataset '${item.name}' success`);
      })
      .catch((error) => {
        setExportLabelLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  return (
    <div className="export-customized-content">
      <p className="card-text">
        has label:
        {item.sample_label_fields && item.sample_label_fields.length > 0
          ? "Yes"
          : "No"}
      </p>
      {item.anno_key && item.anno_key === "cvat" && (
        <div className="cvat-info">
          <p className="card-text">stage:{item.stage}</p>
        </div>
      )}
      <div class="input-content">
        <Button
          variant="primary"
          type="button"
          onClick={loadAnno}
          disabled={
            !item.anno_key ||
            item.anno_key !== "cvat" ||
            (item.stage && item.stage !== "acceptance") ||
            loadAnnoLoading
          }
        >
          {loadAnnoLoading ? (
            <span>
              <Timer loadingStates={[loadAnnoLoading]} />
            </span>
          ) : (
            "Load Label"
          )}
        </Button>
        <div className="button-container">
          <Button
            variant="light"
            onClick={sdkDownloadAnno}
            disabled={
              !item.anno_key || item.anno_key !== "cvat" || downloadLoading
            }
          >
            {downloadLoading ? (
              <span>
                <Timer loadingStates={[downloadLoading]} />
              </span>
            ) : (
              "SDK download Label"
            )}
          </Button>{" "}
        </div>
        <div className="text-container">
          <label htmlFor="input-field">Please Type File Name</label>
        </div>
        <div>
          <input
            id="input-field"
            type="text"
            value={fileName}
            placeholder="labels.xml"
            onChange={(event) => setFileName(event.target.value)}
          />
          <label>Download?</label>
          <input
            class="download-checkbox"
            type="checkbox"
            checked={download}
            onChange={() => setDownload(!download)}
            style={{ scale: "1.5" }}
          />
          <label>Overwrite?</label>
          <input
            class="overwrite-checkbox"
            type="checkbox"
            checked={overwrite}
            onChange={() => setOverwrite(!overwrite)}
            style={{ scale: "1.5" }}
          />
          <label>Reformat?</label>
          <input
            class="reformat-checkbox"
            type="checkbox"
            checked={reformat}
            onChange={() => setReformat(!reformat)}
            style={{ scale: "1.5" }}
          />
        </div>
        <div className="button-container">
          <Button
            variant="secondary"
            type="button"
            onClick={export_label}
            disabled={exportLabelLoading}
          >
            {exportLabelLoading ? "Loading..." : "Export Label"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ExportPage;
