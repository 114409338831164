import axios from "axios";
import { useEffect, useState } from "react";
import { Datasets } from "../../components/Dataset/Dataset";
import Button from "react-bootstrap/Button";
import ModelPageModal from "./Modal";
import StatusCheckPage from "./pages/StatusModelPage";
import LocalInferenceContent from "./LocalInferencePage";
import NavigationBar from "../../components/NavBar/NavBar";
import Timer from "../../components/Timer";
import "./PreLabelPage.css";
import { useDataContext, PreLabelProvider } from "../../utils/DataContext";

function ModelPage() {
  return (
    <PreLabelProvider>
      <ModelPageChild />
    </PreLabelProvider>
  );
}

function ModelPageChild() {
  const { fetchModelsList } = useDataContext();

  useEffect(() => {
    fetchModelsList();
  }, []);

  let CustomizedContent = KubeflowInferenceContent;
  if (process.env.REACT_APP_IS_PUBLIC_SERVER) {
    CustomizedContent = LocalInferenceContent;
  }

  return (
    <div>
      <NavigationBar />
      <div className="model-page">
        <Datasets CustomizedContent={CustomizedContent} />
      </div>
    </div>
  );
}

function KubeflowInferenceContent(props) {
  const { item, loadDatasetList } = props;

  const [selectedModelPage, setSelectedModelPage] = useState("");
  const [loadZooModalShow, setLoadZooModalShow] = useState(false);

  const [selectedZooModel, setSelectedZooModel] = useState("");
  const [selectedKubeflow, setSelectedKubeflow] = useState("");
  const [selectedAutodag, setSelectedAutodag] = useState("");
  const [selectedHuggingface, setSelectedHuggingface] = useState("");
  const [selectedHqSam, setSelectedHqSam] = useState("");
  const [customModel, setCustomModel] = useState("");
  const [ultralyticsModel, setUltralyticsModel] = useState("");

  const [loading, setLoading] = useState(false);

  const [showStatusCheckModal, setShowStatusCheckModal] = useState(false);
  const [loadTaskRes, setLoadTaskRes] = useState();

  const handleStatusCheckModel = () => {
    loadTask(item.name);
    setShowStatusCheckModal(true);
  };

  const handleModelTypeSelected = (data) => {
    setSelectedModelPage(data);
  };

  const pageToSetFunc = {
    ZooModelPage: () => setSelectedZooModel(selectedZooModel),
    KubeflowPage: () => setSelectedKubeflow(selectedKubeflow),
    AutodagPage: () => setSelectedAutodag(selectedAutodag),
    HuggingFacePage: () => setSelectedHuggingface(selectedHuggingface),
    HqSamPage: () => setSelectedHqSam(selectedHqSam),
    CustomPage: () => setCustomModel(customModel),
    UltralyticsPage: () => setUltralyticsModel(ultralyticsModel),
  };

  const pageToSetFuncPass = {
    ZooModelPage: (model) => setSelectedZooModel(model),
    KubeflowPage: (model) => setSelectedKubeflow(model),
    AutodagPage: (model) => setSelectedAutodag(model),
    HuggingFacePage: (model) => setSelectedHuggingface(model),
    HqSamPage: (model) => setSelectedHqSam(model),
    CustomPage: (model) => setCustomModel(model),
    UltralyticsPage: (model) => setUltralyticsModel(model),
  };

  const fetchSelectedModel = () => {
    if (!selectedModelPage) {
      return;
    }
    if (pageToSetFunc.hasOwnProperty(selectedModelPage)) {
      let setFunc = pageToSetFunc[selectedModelPage];
      setFunc();
    } else {
      let eMsg = `Model Page '${selectedModelPage}' don't have function.`;
      window.alert(eMsg);
    }
  };

  useEffect(() => {
    fetchSelectedModel();
  }, [selectedModelPage]);

  const handleModel = (page, model) => {
    if (!page) {
      return;
    }
    if (pageToSetFuncPass.hasOwnProperty(page)) {
      let setFunc = pageToSetFuncPass[page];
      setFunc(model);
    } else {
      let eMsg = `Model Page ${page} don't have set function.`;
      window.alert(eMsg);
    }
  };

  let kubeflowURL = `/models/kubeflow/load_model?dataset_name=${item.name}`;
  if (typeof selectedKubeflow === "string") {
    kubeflowURL += `&model_name=${selectedKubeflow}`;
  } else if (typeof selectedKubeflow === "object") {
    kubeflowURL += `&model_name=${selectedKubeflow.model}&text_caption=${selectedKubeflow.textCaption}`;
  }

  const pageToUrl = {
    ZooModelPage: `/models/zoo/load_model?dataset_name=${item.name}&zoo_model_name=${selectedZooModel}`,
    KubeflowPage: kubeflowURL,
    AutodagPage: `/models/kubeflow/autodag/load_model?dataset_name=${item.name}&dag_name=${selectedAutodag.dagName}&sub_dag=${selectedAutodag.subDag}`,
    HuggingFacePage: `/models/huggingface/load_model?dataset_name=${item.name}&model_name=${selectedHuggingface}`,
    HqSamPage: `/models/hq_sam/load_model?dataset_name=${item.name}&model_name=${selectedHqSam.hqsam}&label_field=${selectedHqSam.labelField}`,
    CustomPage: `/models/custom/load_model?dataset_name=${item.name}&model_name=${customModel}`,
    UltralyticsPage: `/models/ultralytics/load_model?dataset_name=${item.name}&model_name=${ultralyticsModel}`,
  };

  const handleLoadingModel = async () => {
    let url = ``;
    if (pageToUrl.hasOwnProperty(selectedModelPage)) {
      url = pageToUrl[selectedModelPage];
    } else {
      window.alert(`Invalid model selected: ${selectedModelPage}`);
      return;
    }
    setLoading(true);
    axios
      .put(url)
      .then((response) => {
        setLoading(false);
        loadDatasetList();
        window.alert(`Load model in datasets '${item.name}' success`);
      })
      .catch((error) => {
        setLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const loadTask = async (dataset_name) => {
    try {
      const response = await axios.get(
        `/models/kubeflow/dataset/tasks?dataset_name=${dataset_name}`
      );
      if (response.data) {
        const res = response.data;
        setLoadTaskRes(res);
      } else {
      }
    } catch (error) {
      let errorMsg = error.response?.data || "Unknown error occurred";
      window.alert(errorMsg);
    }
  };

  let hasLabel, hasLabelStyle;
  if (item.sample_label_fields && item.sample_label_fields.length > 0) {
    hasLabel = "Yes";
    hasLabelStyle = { backgroundColor: "lightblue" };
  } else {
    hasLabel = "No";
    hasLabelStyle = { backgroundColor: "lightcoral" };
  }

  const selectedModelInfo = (
    <div className="model-info">
      {selectedZooModel && selectedModelPage === "ZooModelPage" && (
        <div>
          <p>
            <strong>Zoo Model: </strong>
            {selectedZooModel}
          </p>
        </div>
      )}
      {selectedKubeflow && selectedModelPage === "KubeflowPage" && (
        <div>
          <p>
            <strong>Kubeflow Model: </strong>
            {typeof selectedKubeflow === "string"
              ? selectedKubeflow
              : selectedKubeflow.model}
          </p>
          {typeof selectedKubeflow === "object" && (
            <p>
              <strong>Text Caption: </strong>
              {selectedKubeflow.textCaption}
            </p>
          )}
        </div>
      )}
      {selectedAutodag && selectedModelPage === "AutodagPage" && (
        <div>
          <p>
            <strong>Auto DAG: </strong>
            {selectedAutodag.dagName}
          </p>
          <p>
            <strong>Sub DAG: </strong>
            {selectedAutodag.subDag}
          </p>
        </div>
      )}
      {selectedHuggingface && selectedModelPage === "HuggingFacePage" && (
        <div>
          <p>
            <strong>Hugging Face Model: </strong>
            {selectedHuggingface}
          </p>
        </div>
      )}
      {selectedHqSam && selectedModelPage === "HqSamPage" && (
        <div>
          <p>
            <strong>HQ SAM Model: </strong>
            {selectedHqSam.hqsam}
          </p>
          <p>
            <strong>label field: </strong>
            {selectedHqSam.labelField}
          </p>
        </div>
      )}
      {customModel && selectedModelPage === "CustomPage" && (
        <div>
          <p>Inputed Custom Model Name: {customModel}</p>
        </div>
      )}
      {ultralyticsModel && selectedModelPage === "UltralyticsPage" && (
        <div>
          <p>Inputed Ultralytics Model Name: {ultralyticsModel}</p>
        </div>
      )}
    </div>
  );

  return (
    <div className="model-customized-content">
      <p className="card-text" style={hasLabelStyle}>
        hasLabel: {hasLabel}
      </p>
      <p class="card-text">samples num: {item.samples.length}</p>
      <div className="buttonContainer">
        <div className="chooseModel">
          <Button
            variant="outline-primary"
            onClick={() => setLoadZooModalShow(true)}
            disabled={loading}
          >
            Choose Model
          </Button>
          <ModelPageModal
            show={loadZooModalShow}
            onHide={() => setLoadZooModalShow(false)}
            item={item}
            onSelectedModelType={handleModelTypeSelected}
            handleModel={handleModel}
          />
          {selectedModelInfo}
        </div>
        <div className="loadModel">
          <Button onClick={handleLoadingModel} disabled={loading}>
            {loading ? (
              <span>
                <Timer loadingStates={[loading]} />
              </span>
            ) : (
              "Start"
            )}
          </Button>
        </div>
        <div className="loadModel">
          <Button onClick={handleStatusCheckModel}>Status Check</Button>
          <StatusCheckPage
            show={showStatusCheckModal}
            onHide={() => setShowStatusCheckModal(false)}
            item={item}
            loadTaskRes={loadTaskRes}
            loadTask={loadTask}
          />
        </div>
      </div>
    </div>
  );
}

export default ModelPage;
