import "../../styles/LabelStudioProject.css";
import "./LabelStudioProject.css";
import axios from "axios";
import { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Timer from "../Timer";
import ImportStorageModal from "./ImportStorageModal/ImportStorageModal";
import ConfigModal from "./ConfigModal/ConfigModal";
import formatDate from "../../utils/time";

function LabelStudioProject() {
  const [projectItems, setProjectItems] = useState([]);
  const [folderItems, setFolderItems] = useState([]);

  const loadProjectList = async () => {
    axios
      .get("/label_studio/projects/list")
      .then((response) => {
        const newItems = response.data;
        setProjectItems(newItems.concat());
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const loadFolderList = async () => {
    axios
      .get("/file/folder/list")
      .then((response) => {
        const newItems = response.data;
        setFolderItems(newItems.concat());
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  useEffect(() => {
    loadProjectList();
    loadFolderList();
  }, []);

  const audiofolderItems = folderItems.filter((item) => item.type === "audio");

  return (
    <div className="label-studio-projects">
      <Nav.Item>
        <Nav.Link href={`http://${window.location.hostname}:8081/`}>
          Label Studio
        </Nav.Link>
      </Nav.Item>
      <ProjectGallery
        loadProjectList={loadProjectList}
        items={projectItems}
        folders={audiofolderItems}
      />
    </div>
  );
}

function ProjectGallery(props) {
  const { loadProjectList, items, folders } = props;

  return (
    <div className="projectGallery">
      {items &&
        items.map((item) => {
          return (
            <ProjectItem
              key={`ls-proj-${item.id}`}
              loadProjectList={loadProjectList}
              item={item}
              folders={folders}
            />
          );
        })}
    </div>
  );
}

function ProjectItem(props) {
  const { item, loadProjectList, folders } = props;

  const [config, setConfig] = useState();
  const [reading, setReading] = useState(false);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteCardLoading, setDeleteCardLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [etlLoading, setEtlLoading] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCloseConfirmModal, setShowCloseConfirmModal] = useState(false);
  const [importStorageModalShow, setImportStorageModalShow] = useState(false);
  const [configModalshow, setConfigModalshow] = useState(false);

  const handleOpenImportModal = () => {
    setImportStorageModalShow(true);
  };

  const handleClearButtonClick = () => {
    setShowConfirmModal(true);
  };

  const handleShowCloseConfirmModal = () => {
    setShowCloseConfirmModal(true);
  };

  const handleETLButtonClick = async () => {
    await loadConfig();
    setConfigModalshow(true);
  };

  const loadConfig = async () => {
    setReading(true);
    axios
      .get(`/ETL/label_studio/config/read?project_id=${item.id}`)
      .then((response) => {
        setReading(false);
        let config = response.data;
        if (config) {
          setConfig(config);
        }
      })
      .catch((error) => {
        setReading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const deleteAllTasks = async () => {
    setDeleteLoading(true);
    axios
      .delete(`/label_studio/project/tasks/delete/all?project_id=${item.id}`)
      .then((response) => {
        setDeleteLoading(false);
        loadProjectList();
        let msg = `Delete all tasks in project '${item.title}' success.`;
        window.alert(msg);
        setShowConfirmModal(false);
      })
      .catch((error) => {
        setDeleteLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const deleteProject = async () => {
    setDeleteCardLoading(true);
    axios
      .delete(`/label_studio/project/delete?project_id=${item.id}`)
      .then((response) => {
        setDeleteCardLoading(false);
        loadProjectList();
        let msg = `Delete project '${item.title}' success.`;
        window.alert(msg);
      })
      .catch((error) => {
        setDeleteCardLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const export_project = async () => {
    setExportLoading(true);
    axios
      .put(`/label_studio/project/export?project_id=${item.id}`)
      .then((response) => {
        setExportLoading(false);
        window.alert(
          `Export project '${item.title}' success, the export dir is '${item.export_dir}'`
        );
      })
      .catch((error) => {
        setExportLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const loadETL = async () => {
    setEtlLoading(true);
    axios
      .put(`/label_studio/project/ETL?project_id=${item.id}`)
      .then((response) => {
        setEtlLoading(false);
        window.alert(
          `ETL label-studio project '${item.title}' to table success`
        );
      })
      .catch((error) => {
        setEtlLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  useEffect(() => {
    loadConfig();
  }, []);

  const StorageLinkStyle = {
    color: "blue",
    textDecoration: "underline",
    cursor: "pointer",
  };

  return (
    <div class="card" style={{ width: "18rem" }}>
      <div class="card-content">
        <button
          type="button"
          class="btn btn-light"
          onClick={() => {
            const w = window.open("about:blank");
            w.location.href = `http://${window.location.hostname}:8081/projects/${item.id}`;
          }}
        >
          <h5 class="card-title">{item.title}</h5>
        </button>
        <a
          onClick={() => {
            const w = window.open("about:blank");
            w.location.href = `http://${window.location.hostname}:8081/projects/${item.id}/settings/storage`;
          }}
          style={StorageLinkStyle}
        >
          Check Storage
        </a>
        <div>
          <p class="card-text">created at: {formatDate(item.created_at)}</p>
        </div>
        <div class="task-nums-container">
          <p class="card-text">tasks num: {item.task_number}</p>
          <button
            className="clear-button"
            onClick={handleClearButtonClick}
            disabled={item.task_number === 0}
          >
            Clear
          </button>
          <Modal
            show={showConfirmModal}
            onHide={() => setShowConfirmModal(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Clear Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Data cannot be recovered after deletion, please proceed with
                caution.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="outline-danger"
                onClick={() => {
                  deleteAllTasks();
                }}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <div>
          <p class="card-text">finished: {item.finished_task_number}</p>
        </div>
      </div>
      <div className="import-button">
        <Button
          variant="btn btn-outline-primary"
          onClick={handleOpenImportModal}
        >
          Import Storage
        </Button>
      </div>

      <ImportStorageModal
        show={importStorageModalShow}
        onHide={() => setImportStorageModalShow(false)}
        loadProjectList={loadProjectList}
        folders={folders}
        item={item}
      />

      {item.finished_task_number > 0 && (
        <>
          <Button
            variant="btn btn-primary"
            type="button"
            onClick={export_project}
            disabled={exportLoading}
            className="export-button"
          >
            {exportLoading ? (
              <span>
                <Timer loadingStates={[exportLoading]} />
              </span>
            ) : (
              "Export"
            )}
          </Button>
          <div className="lb-etl-button-container">
            <Button
              variant="btn btn-primary"
              type="button"
              onClick={handleETLButtonClick}
              className="config-button"
            >
              Config
            </Button>
            <Button
              variant="btn btn-primary"
              type="button"
              onClick={loadETL}
              disabled={etlLoading}
              className="etl-button"
            >
              {etlLoading ? (
                <span>
                  <Timer loadingStates={[etlLoading]} />
                </span>
              ) : (
                "ETL"
              )}
            </Button>
          </div>
          <ConfigModal
            show={configModalshow}
            onHide={() => setConfigModalshow(false)}
            item={item}
            config={config}
            setConfig={setConfig}
          />
        </>
      )}

      <button
        onClick={handleShowCloseConfirmModal}
        type="button"
        className="btn-close bottom-left"
        aria-label="Close"
      />
      <Modal
        show={showCloseConfirmModal}
        onHide={() => setShowCloseConfirmModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Close Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to close this project?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={() => {
              deleteProject();
              setShowCloseConfirmModal(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export { LabelStudioProject };
