// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cvat-upload-input {
  display: flex;
  flex-direction: column;
}

.cvat-select {
  display: flex;
  flex-direction: column;
  width: 220px;
}

.cvat-select label {
  display: block;
  font-size: 16px;
  margin-bottom: 0.5rem;
}

.cvat-select-option {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.cvat-select-option-container {
  width: 220px; /* 将容器的宽度设置为 200 像素 */
  overflow: hidden; /* 隐藏容器内部的溢出内容 */
}

.button-container {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.annotate-selected-container {
  margin-left: 1rem;
  margin-right: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CVATPage/CVATPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,cAAc;EACd,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,YAAY,EAAE,qBAAqB;EACnC,gBAAgB,EAAE,gBAAgB;AACpC;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".cvat-upload-input {\n  display: flex;\n  flex-direction: column;\n}\n\n.cvat-select {\n  display: flex;\n  flex-direction: column;\n  width: 220px;\n}\n\n.cvat-select label {\n  display: block;\n  font-size: 16px;\n  margin-bottom: 0.5rem;\n}\n\n.cvat-select-option {\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ced4da;\n  border-radius: 4px;\n}\n\n.cvat-select-option-container {\n  width: 220px; /* 将容器的宽度设置为 200 像素 */\n  overflow: hidden; /* 隐藏容器内部的溢出内容 */\n}\n\n.button-container {\n  margin-top: 0.5rem;\n  margin-bottom: 0.5rem;\n}\n\n.annotate-selected-container {\n  margin-left: 1rem;\n  margin-right: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
