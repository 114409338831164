import { Modal } from "react-bootstrap";
import React, { useState } from "react";

import BackButton from "../../components/BackButton/BackButton";
import MainPage from "./pages/MainPage";
import UploadPage from "./pages/UploadPage";
import MntPage from "./pages/MntPage";
import LocalPage from "./pages/LocalPages/LocalPage";
import FiftyonePage from "./pages/FiftyonePage";
import FolderPage from "./pages/LocalPages/ChooseFolder";
import DatasetPage from "./pages/DatasetPage";

function AcquisitionDataPageModal(props) {
  const [currentPage, setCurrentPage] = useState("main");

  const goToMntPage = () => {
    setCurrentPage("MntPage");
  };
  const goToLocalPage = () => {
    setCurrentPage("LocalPage");
  };
  const goToFolderPage = () => {
    setCurrentPage("folderPage");
  };

  const backPagesMap = {
    main: "main",
    UploadPage: "main",
    datasetPage: "main",
    FiftyonePage: "main",
    MntPage: "main",
    LocalPage: "UploadPage",
    folderPage: "LocalPage",
  };

  const handleBackButtonClick = () => {
    const nextPage = backPagesMap[currentPage];
    setCurrentPage(nextPage);
  };

  const pageComponents = {
    main: <MainPage setCurrentPage={setCurrentPage} />,
    UploadPage: (
      <UploadPage
        goToMntPage={goToMntPage}
        goToLocalPage={goToLocalPage}
        onHide={props.onHide}
      />
    ),
    MntPage: (
      <MntPage onHide={props.onHide} onMntPathChange={props.onMntPathChange} />
    ),
    LocalPage: (
      <LocalPage
        item={props.item}
        loadDatasetList={props.loadDatasetList}
        goToFolderPage={goToFolderPage}
      />
    ),
    datasetPage: (
      <DatasetPage item={props.item} loadDatasetList={props.loadDatasetList} />
    ),
    FiftyonePage: (
      <FiftyonePage
        onHide={props.onHide}
        onFiftyoneSelectionChange={props.onFiftyoneSelectionChange}
      />
    ),
    folderPage: (
      <FolderPage
        onHide={props.onHide}
        item={props.item}
        loadDatasetList={props.loadDatasetList}
      />
    ),
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      isOpen={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <div style={{ display: "flex", alignItems: "center" }}>
          {currentPage !== "main" && (
            <BackButton onClick={handleBackButtonClick} />
          )}
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "10px" }}
          >
            Choose Data
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>{pageComponents[currentPage]}</Modal.Body>
    </Modal>
  );
}

export default AcquisitionDataPageModal;
