import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Timer from "../../../components/Timer";
import axios from "axios";
import { useEffect, useState } from "react";
import "../../../styles/Dataset.css";
import "../../../styles/KubeflowPage.css";

function ProgressBar({ progress, interval = 1000 }) {

  useEffect(() => {
    const timer = setInterval(() => {
      if (progress >= 100) {
        clearInterval(timer);
      }
    }, interval);

    return () => clearInterval(timer);
  }, [progress, interval]);

  return (
    <div className="progress-bar-container">
      <div 
        className="progress-bar-fill" 
        style={{ 
          width: `${progress}%`, 
          backgroundColor: 'blue', 
          height: '15px', 
          borderRadius: '5px' 
        }}
      ></div>
      <div className="percentage-text">{`${progress}%`}</div>
    </div>
  );
}

function StatusCheckPage(props) {
  const { reloadStatus, tasksStatus, progress} = props;

  return (
    <Modal
      fullscreen={true}
      show={props.show}
      onHide={props.onHide}
      className="Status_Modal_Dialog"
    >
      <Modal.Header closeButton>
        <Modal.Title>Status Check Page</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="kubeflow-tasks">
          {tasksStatus &&
            Object.values(tasksStatus).map((item, index) => {
              let currentProgress = item.status ==='finished' || item.status === "Succeeded"
              ? 100 
              : item.status ==='started'
              ? 50 
              : 0;
              return (
                <TaskItem
                  key={`${item.rq_id}`}
                  item={item}
                  reloadStatus={reloadStatus}
                  progress={currentProgress}
                />
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

function TaskItem(props) {
  const { item, reloadStatus, progress} = props;

  const deleteTask = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this task?"
    );
    if (confirmDelete) {
      axios
        .delete(`/models/docker/task/delete?prelabel_id=${item.prelabel_id}`)
        .then((response) => {
          reloadStatus();
        })
        .catch((error) => {
          let errorMsg = error.response.data;
          window.alert(errorMsg);
        });
    }
  };

  const candelete = ["Succeeded", "finished", "Failed", "failed"].includes(
    item.status
  );

  return (
    <div
      className={`card ${
        item.status === "Succeeded" || item.status === "finished"
          ? "Succeeded"
          : item.status === "Failed" || item.status === "failed"
          ? "Failed"
          : "Running"
      }`}
      style={{ width: "18rem" }}
    >
      {candelete && (
        <div class="container-fluid">
          <div class="row">
            <div class="col col-xl-12 d-flex justify-content-end px-2 py-2">
              <button
                type="button"
                class="btn-close"
                aria-label="Close"
                onClick={() => deleteTask(item.prelabel_id)}
              ></button>
            </div>
          </div>
        </div>
      )}
      <div class="card-body">
        <h5 class="card-title">{item.start_time}</h5>
        <div className="task-items-container">
          <p>dataset: {item.dataset_name}</p>
          <p>model: {item.model_name}</p>
          <p>status: {item.status}</p>
          <ProgressBar progress={progress} /> 
        </div>
      </div>
    </div>
  );
}

export default StatusCheckPage;
