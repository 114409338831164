import axios from "axios";
import { useEffect, useState } from "react";
import NavigationBar from "../../components/NavBar/NavBar";

function TrainPage() {
  const [model, setModel] = useState(null);
  const [models, setModels] = useState([]);

  const loadSupportModelList = async () => {
    axios
      .get("/kneron/supported_models/list")
      .then((response) => {
        setModels(response.data.concat());
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  useEffect(() => {
    loadSupportModelList();
  }, []);

  const trainModel = async () => {
    if (!model) {
      window.alert("Please choose a model.");
      return;
    }
    axios
      .put(`/kneron/train/model?model_name=${model}`)
      .then((response) => {
        window.alert(`Start "${model}" training pipeline.`);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  return (
    <div>
      <NavigationBar />
      <div>
        <select
          value={model}
          onChange={(e) => setModel(e.target.value)}
          className="local-model-select"
        >
          <option value={null}>Please choose a model</option>
          {models.map((name) => (
            <option key={`kneron_kubeflow-train-${name}`} value={name}>
              {name}
            </option>
          ))}
        </select>
        <button type="button" class="btn btn-light" onClick={trainModel}>
          Start
        </button>
      </div>
    </div>
  );
}

export default TrainPage;
