// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.instrument-avator {
  position: absolute;
  right: 9%;
  bottom: 37%;
}

.label-input-content {
  width: 250px;
  margin: 0 auto;
}

.label-input-content select {
  width: 90%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin: 0.1rem auto;
}

.label-down-button {
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/LabelsPage/styles/LabelsOpsPage.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".instrument-avator {\n  position: absolute;\n  right: 9%;\n  bottom: 37%;\n}\n\n.label-input-content {\n  width: 250px;\n  margin: 0 auto;\n}\n\n.label-input-content select {\n  width: 90%;\n  padding: 8px;\n  border: 1px solid #ced4da;\n  border-radius: 4px;\n  margin: 0.1rem auto;\n}\n\n.label-down-button {\n  margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
