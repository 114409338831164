import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <div>
      <h1>404 Page Not Found</h1>
      <p>Please check if the url path is correct.</p>
      <Button onClick={() => navigate("/acquisition")}>Go to DAAT</Button>
    </div>
  );
}

export default NotFoundPage;
