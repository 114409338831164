import axios from "axios";
import { useState } from "react";
import { Button } from "react-bootstrap";
import "../../../styles/DatasetPage.css";

function CutterPage(props) {
  const { item, loadDatasetList } = props;

  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [targetDataset, setTargetDataset] = useState(null);
  const [loading, setLoading] = useState(false);

  const cutDataset = async () => {
    if (!item.samples.length || item.samples.length === 0) {
      window.alert(`There is no sample in dataset '${item.name}'`);
      return;
    }
    if (!targetDataset) {
      window.alert("Please input a target dataset name.");
      return;
    }
    if (!start || !end) {
      window.alert("Please input start and end indexs.");
      return;
    }
    if (start > end) {
      window.alert("Start index can not be greater than end index.");
      return;
    }
    setLoading(true);
    let url = `/dataset/cut?dataset_name=${item.name}&start=${start}&end=${end}&target_dataset_name=${targetDataset}`;
    axios
      .put(url)
      .then((response) => {
        setLoading(false);
        loadDatasetList();
        const msg = `Move samples from '${item.name}' to '${targetDataset}' success.`;
        window.alert(msg);
      })
      .catch((error) => {
        setLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  return (
    <div className="cutter-page">
      <p class="card-text">{item.name}</p>
      <p class="card-text">samples num: {item.samples.length}</p>
      <div className="cutter-input-content">
        <input
          type="text"
          id="target-dataset-name"
          className="form-control"
          placeholder="target dataset name"
          value={targetDataset}
          onChange={(e) => {
            setTargetDataset(e.target.value);
          }}
        />
        <input
          type="number"
          id="start"
          placeholder="start index"
          value={start}
          onChange={(e) => {
            setStart(e.target.value);
          }}
        />
        <input
          type="number"
          id="end"
          placeholder="end index"
          value={end}
          onChange={(e) => {
            setEnd(e.target.value);
          }}
        />
        <Button
          variant="secondary"
          type="button"
          onClick={cutDataset}
          disabled={loading}
        >
          {loading ? "Loading..." : "Cut"}
        </Button>
      </div>
    </div>
  );
}

export default CutterPage;
