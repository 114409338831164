import { Tabs, Tab } from "react-bootstrap";
import MembersTable from "./MembersTable";

function AdminOrganization(props) {
  const { fetchUsersData, usersData } = props;

  const OrganizationsUsers = (
    <Tabs>
      {usersData &&
        Object.entries(usersData).map(([organizationName, users]) => {
          return (
            <Tab eventKey={organizationName} title={organizationName}>
              <MembersTable
                members={users}
                organizationName={organizationName}
                fetchUsersData={fetchUsersData}
              />
            </Tab>
          );
        })}
    </Tabs>
  );

  return OrganizationsUsers;
}

export default AdminOrganization;
