import axios from "axios";
import { useEffect, useState } from "react";
import { Datasets } from "../../components/Dataset/Dataset";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import "./CVATPage.css";
import NavigationBar from "../../components/NavBar/NavBar";
import { useDataContext, CVATProvider } from "../../utils/DataContext";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import Timer from "../../components/Timer";

function CVATPage() {
  return (
    <CVATProvider>
      <CVATPageChild />
    </CVATProvider>
  );
}

function CVATPageChild() {
  // const { listOrganizations, listAllprojects } = useDataContext();

  // useEffect(() => {
  //   listOrganizations();
  //   listAllprojects();
  // }, []);

  return (
    <div>
      <NavigationBar />
      <div className="cvat-page">
        <Datasets
          CustomizedContent={CustomizedContent}
          GlobalCustomizedContent={GlobalCustomizedContent}
        />
      </div>
    </div>
  );
}

function CustomizedContent(props) {
  const { item, loadDatasetList, dbMapList } = props;

  let display = (
    <ToAnnotateCard
      item={item}
      loadDatasetList={loadDatasetList}
      dbMapList={dbMapList}
    />
  );
  if (item.anno_key && item.anno_key === "cvat") {
    display = <AnnotatedCard item={item} loadDatasetList={loadDatasetList} />;
  } else if (item.anno_key && item.anno_key !== "cvat") {
    display = <OtherBackendCard item={item} />;
  }
  return <div className="cvat-projects-customized-content">{display}</div>;
}

const LinkStyle = {
  color: "blue",
  textDecoration: "underline",
  cursor: "pointer",
};

function ToAnnotateCard(props) {
  const { item, loadDatasetList } = props;

  const { organizations, allprojects } = useDataContext();

  const [organization, setOrganization] = useState({});
  const [projects, setProjects] = useState(allprojects);
  const [project, setProject] = useState({});
  const [labelField, setLabelField] = useState(null);
  const [segmentSize, setSegmentSize] = useState(null);

  const [renderBegining, setRenderBegining] = useState(true);
  const [annoLoading, setAnnoLoading] = useState(false);

  const userToken = Cookies.get("user_token");
  const userData = userToken ? jwtDecode(userToken) : null;
  const userOrganizationName = userData ? userData.organization : null;

  const syncOrganization = () => {
    if (userOrganizationName) {
      // find responding organization with user's organization
      const userOrganization = organizations.find(
        (organization) => organization.name === userOrganizationName
      );
      if (userOrganization && Object.keys(organization).length === 0) {
        setOrganization({
          ...userOrganization,
        });
      }
    }
  };

  useEffect(() => {
    syncOrganization();
    setProject({});
    listProjects();
    setRenderBegining(false);
  }, [organization]);

  const handleOrgnaizationChange = (e) => {
    const selectedName = e.target.value;
    if (!selectedName) {
      setOrganization({});
      return;
    }
    // find responding organization with selected name
    const selectedOrganization = organizations.find(
      (organization) => organization.name === selectedName
    );
    if (!selectedOrganization) {
      setOrganization({});
      return;
    }
    setOrganization({
      ...selectedOrganization,
    });
  };

  const handleProjectsChange = (e) => {
    const selectedName = e.target.value;
    if (!selectedName) {
      setProject({});
      return;
    }
    const selectedProject = projects.find(
      (project) => project.name === selectedName
    );
    if (!selectedProject) {
      setProject({});
      return;
    }
    setProject({
      ...selectedProject,
    });
  };

  const listProjects = async () => {
    if (renderBegining) {
      return;
    }
    let url = `/projects/list`;
    if (organization && organization.id) {
      url += `?org_id=${organization.id}`;
    }
    axios
      .get(url)
      .then((response) => {
        const newProjects = response.data;
        setProjects(newProjects.concat());
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const annotate = async () => {
    if (item.samples.length <= 0) {
      window.alert(`Empty dataset should not be upload to CVAT.`);
      return;
    }
    if (!project || !project.name) {
      window.alert(`Please select a project.`);
      return;
    } else if (!organization || !organization.name) {
      window.alert(`Please select an organization.`);
      return;
    }
    setAnnoLoading(true);
    let url = `/datasets/annotate?dataset_name=${item.name}&project_name=${project.name}&organization=${organization.name}`;
    if (labelField) {
      url += `&label_field=${labelField}`;
    }
    if (segmentSize) {
      url += `&segment_size=${segmentSize}`;
    }
    axios
      .put(url)
      .then((response) => {
        loadDatasetList();
        setAnnoLoading(false);
        window.alert(`Upload dataset '${item.name}' to CVAT success`);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        setAnnoLoading(false);
        window.alert(errorMsg);
      });
  };

  let ChooseOrganization = (
    <div className="cvat-organization-select-option-container">
      <label htmlFor="organization-select">Choose organization:</label>
      <select
        id="organization-select"
        value={organization.name}
        onChange={handleOrgnaizationChange}
        className="cvat-select-option"
      >
        <option value={null}>Please choose an option</option>
        {organizations &&
          organizations.map((organization) => (
            <option
              key={`${item.name}-${organization.name}`}
              value={organization.name}
            >
              {organization.name}
            </option>
          ))}
      </select>
    </div>
  );
  if (userOrganizationName) {
    ChooseOrganization = (
      <div>
        <h6>Your Organization: </h6>
        <strong>{userOrganizationName}</strong>
      </div>
    );
  }

  const showAdvanceOption =
    Object.keys(organization).length !== 0 &&
    Object.keys(project).length !== 0 &&
    labelField;

  const handleChangeSegmentSize = (e) => {
    let newValue = e.target.value;
    newValue = Math.floor(newValue);
    if (newValue < 1 || newValue > item.samples.length) {
      if (!newValue) {
        setSegmentSize(null);
      }
      return;
    }
    setSegmentSize(newValue);
  };

  const segmentSizeTooltip = (
    <div>
      <p>
        <a
          // onClick={() => {
          //   const w = window.open("about:blank");
          //   w.location.href = `https://en.wikipedia.org/wiki/Frame_rate`;
          // }}
          style={LinkStyle}
        >
          <strong>segment size</strong>
        </a>{" "}
        is the maximum number of images to upload per job. Not applicable to
        videos.{" "}
      </p>
      <p>
        If remain empty is also okey, that will upload all images to one job.
      </p>
    </div>
  );

  return (
    <div className="cvat-upload-input">
      <div className="cvat-select">
        {ChooseOrganization}
        <label htmlFor="project-select">Choose project:</label>
        <div className="cvat-project-select-option-container">
          <select
            id="project-select"
            value={project.name}
            onChange={handleProjectsChange}
            className="cvat-select-option"
          >
            <option value={null}>Please choose an option</option>
            {projects &&
              projects.map((project) => (
                <option
                  key={`${item.name}-${project.name}`}
                  value={project.name}
                >
                  {project.name}
                </option>
              ))}
          </select>
        </div>
        <label htmlFor="field-select">Choose field:</label>
        <div className="label-field-select-option-container">
          <select
            id="field-select"
            value={labelField}
            onChange={(e) => setLabelField(e.target.value)}
            className="cvat-select-option"
          >
            <option value="">Please choose an option</option>
            <option value="pure image">--pure images--</option>
            <option value="ALL">--all fields--</option>
            {item.sample_label_fields &&
              item.sample_label_fields.map((field) => (
                <option key={field} value={field}>
                  {field}
                </option>
              ))}
          </select>
        </div>
        {showAdvanceOption && (
          <div>
            <label>
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-bottom" placement="bottom">
                    {segmentSizeTooltip}
                  </Tooltip>
                }
                placement="bottom"
              >
                <p style={LinkStyle}>segment size:</p>
              </OverlayTrigger>
            </label>
            <input
              type="number"
              placeholder="segment size"
              value={segmentSize}
              onChange={handleChangeSegmentSize}
            />
          </div>
        )}
      </div>
      <Button
        variant="primary"
        onClick={annotate}
        disabled={annoLoading}
        style={{ margin: "0.5rem" }}
      >
        {annoLoading ? (
          <span>
            <Timer loadingStates={[annoLoading]} />
          </span>
        ) : (
          "Annotate"
        )}
      </Button>
    </div>
  );
}

function AnnotatedCard(props) {
  const { item, loadDatasetList } = props;

  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const sdkDownloadAnno = async () => {
    setDownloadLoading(true);
    axios
      .get(`/sdk/datasets/load_annotations?dataset_name=${item.name}&`)
      .then((response) => {
        setDownloadLoading(false);
        window.alert(`SDK downLoad label on dataset '${item.name}' success`);
      })
      .catch((error) => {
        setDownloadLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const cancelAnnotation = async () => {
    setLoading(true);
    axios
      .delete(`/datasets/annotation/cancel?dataset_name=${item.name}`)
      .then((response) => {
        loadDatasetList();
        window.alert(`Delete task on CVAT for dataset '${item.name}' success`);
        setLoading(false);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
        setLoading(false);
      });
  };

  let stageStyle =
    item.stage === "acceptance" ? { backgroundColor: "lightgreen" } : {};

  let display = (
    <div>
      <a
        onClick={() => {
          const w = window.open("about:blank");
          w.location.href = `https://cvat.kneron.com/tasks/${item.cvat_task_id}`;
        }}
        style={LinkStyle}
      >
        Check it in CVAT
      </a>
      <p className="card-text" style={stageStyle}>
        stage:{item.stage}
      </p>
      <p className="card-text">project:{item.cvat_project}</p>
      <p className="card-text">organization:{item.organization}</p>
      <p className="card-text">image_quality:{item.image_quality}</p>
      <div className="button-container">
        <Button
          variant="light"
          onClick={sdkDownloadAnno}
          disabled={
            !item.anno_key || item.anno_key !== "cvat" || downloadLoading
          }
        >
          {downloadLoading ? (
            <span>
              <Timer loadingStates={[downloadLoading]} />
            </span>
          ) : (
            "Download Annotations"
          )}
        </Button>
      </div>
    </div>
  );
  if (item.cvat_disconnected) {
    display = (
      <div class="cvat-disconnected-message">
        <p className="card-text">Disconnected with current cvat. </p>
        <p className="card-text">result url: {item.cvat_result_url}</p>
      </div>
    );
  }

  return (
    <div className="cvat-project-info">
      {display}
      <Button variant="outline-danger" onClick={cancelAnnotation}>
        {loading ? (
          <span>
            <Timer loadingStates={[loading]} />
          </span>
        ) : (
          "Cancel"
        )}
      </Button>
    </div>
  );
}

function OtherBackendCard(props) {
  const { item } = props;

  const keyToURL = {
    cvat: `http://${window.location.hostname}:3000/cvat`,
    labelstudio: `http://${window.location.hostname}:3000/label-studio`,
  };

  return (
    <div class="otherbackend-message">
      <p className="card-text">Is annotating in {item.anno_key}. </p>
      <a
        onClick={() => {
          const w = window.open("about:blank");
          w.location.href = keyToURL[item.anno_key];
        }}
        style={LinkStyle}
      >
        Go to {item.anno_key}
      </a>
    </div>
  );
}

function GlobalCustomizedContent(props) {
  const { loadDatasetList, checkedDatasetItems, setCheckedDatasetItems } =
    props;

  const { organizations, allprojects } = useDataContext();

  const [organization, setOrganization] = useState({});
  const [projects, setProjects] = useState(allprojects);
  const [project, setProject] = useState({});

  const [renderBegining, setRenderBegining] = useState(true);
  const [annoLoading, setAnnoLoading] = useState(false);

  const userToken = Cookies.get("user_token");
  const userData = userToken ? jwtDecode(userToken) : null;
  const userOrganizationName = userData ? userData.organization : null;

  const syncOrganization = () => {
    if (userOrganizationName) {
      // find responding organization with user's organization
      const userOrganization = organizations.find(
        (organization) => organization.name === userOrganizationName
      );
      if (userOrganization && Object.keys(organization).length === 0) {
        setOrganization({
          ...userOrganization,
        });
      }
    }
  };

  useEffect(() => {
    syncOrganization();
    setProject({});
    listProjects();
    setRenderBegining(false);
  }, [organization]);

  const handleProjectsChange = (e) => {
    const selectedName = e.target.value;
    if (!selectedName) {
      setProject({});
      return;
    }
    const selectedProject = projects.find(
      (project) => project.name === selectedName
    );
    if (!selectedProject) {
      setProject({});
      return;
    }
    setProject({
      ...selectedProject,
    });
  };

  const listProjects = async () => {
    if (renderBegining) {
      return;
    }
    let url = `/projects/list`;
    if (organization && organization.id) {
      url += `?org_id=${organization.id}`;
    }
    axios
      .get(url)
      .then((response) => {
        const newProjects = response.data;
        setProjects(newProjects.concat());
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const updateStatus = async () => {
    axios
      .get("/cvat/status/upate")
      .then((response) => {
        loadDatasetList();
        window.alert(`Update CVAT Jobs' status success.`);
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const annotateSelected = async () => {
    if (!project || !project.name) {
      window.alert(`Please select a project.`);
      return;
    } else if (!organization || !organization.name) {
      window.alert(`Please select an organization.`);
      return;
    } else if (!checkedDatasetItems || checkedDatasetItems.length === 0) {
      window.alert("Please select dataset.");
      return;
    }
    setAnnoLoading(true);
    axios
      .post(
        `/datasets/annotate/selected?project_name=${project.name}&organization=${userOrganizationName}`,
        checkedDatasetItems
      )
      .then((response) => {
        loadDatasetList();
        setCheckedDatasetItems([]);
        window.alert(`Upload selected datasets to CVAT success`);
        setAnnoLoading(false);
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
        setAnnoLoading(false);
      });
  };

  let display = (
    <div className="annotate-selected-container">
      <div class="input-group mb-3">
        {/* <Button variant="success" onClick={updateStatus}>
          Update Jobs Status
        </Button> */}
        <h6 style={{ margin: "0.5rem" }}>
          Your Organization: <strong>{userOrganizationName}</strong>
        </h6>
        <div
          className="cvat-project-select-option-container"
          style={{ margin: "auto", width: "300px" }}
        >
          <select
            value={project.name}
            onClick={syncOrganization}
            onChange={handleProjectsChange}
            className="cvat-select-option"
          >
            <option value={null}>Choose project</option>
            {projects &&
              projects.map((project) => (
                <option key={`global-${project.name}`} value={project.name}>
                  {project.name}
                </option>
              ))}
          </select>
        </div>
        <Button variant="primary" onClick={annotateSelected}>
          Annotate Selected
        </Button>
      </div>
    </div>
  );

  if (annoLoading) {
    display = (
      <h2 style={{ margin: "auto" }}>Uploading multiple datasets to CVAT</h2>
    );
  }

  if (!userOrganizationName) {
    display = (
      <h2 style={{ margin: "auto" }}>
        You didn't join any orgnaization yet, so please choose an organizatoin
        when uploading to CVAT
      </h2>
    );
  }

  return display;
}

export default CVATPage;
