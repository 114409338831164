import { Button, Modal } from "react-bootstrap";
import { useDataContext } from "../../../utils/DataContext";
import { useState } from 'react'

function MntPage(props) {
  const { onHide } = props;
  const { setSelectedSource } = useDataContext();
  const [inputValue, setInputValue] = useState('');

  const handleConfirm = () => {
    setSelectedSource('goToMntPage');
    props.onMntPathChange(inputValue);
    onHide();
  };

  return (
    <div>
      <div>
        <label htmlFor="input-field">Please Type Your Path</label>
      </div>
      <div>
        <input
          id="input-field"
          type="text"
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
        />
      </div>
      <Modal.Footer>
        <Button onClick={handleConfirm}>Confirm</Button>
      </Modal.Footer>
    </div>
  );
}

export default MntPage;
