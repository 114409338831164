// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back-button {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #eee;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    color: #333;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .back-button:hover {
    background-color: #ccc;
    color: #fff;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/BackButton/BackButton.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,eAAe;IACf,gCAAgC;EAClC;;EAEA;IACE,sBAAsB;IACtB,WAAW;EACb","sourcesContent":[".back-button {\n    display: flex;\n    align-items: center;\n    padding: 10px;\n    background-color: #eee;\n    border: none;\n    border-radius: 5px;\n    font-size: 1.2rem;\n    color: #333;\n    cursor: pointer;\n    transition: all 0.3s ease-in-out;\n  }\n  \n  .back-button:hover {\n    background-color: #ccc;\n    color: #fff;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
