import axios from "axios";
import { useEffect, useState } from "react";
import AdminOrganization from "./AdminOrganizationPage";
import MembersTable from "./MembersTable";
import NavigationBar from "../../../components/NavBar/NavBar";
import { useDataContext } from "../../../utils/DataContext";
import { updateTokenUserCookie } from "../../../utils/cookiesUtils";
import { useNavigate } from "react-router-dom";

function OrganizationPage() {
  const { userData, userToken, updateUserInfo } = useDataContext();

  const [usersData, setUsersData] = useState({});

  const navigate = useNavigate();

  const fetchUsersData = async (organizationName) => {
    let url = "/auth/organization/list/users";
    if (organizationName) {
      url += `?organization=${organizationName}`;
    }
    axios
      .get(url)
      .then((response) => {
        setUsersData(response.data);
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  useEffect(() => {
    fetchUsersData();
    updateTokenUserCookie();
    updateUserInfo();
  }, []);

  if (!userToken) {
    // window.alert("Your user token is not in cookie, please Log-In again.");
    navigate("/signin");
    return;
  }

  let display;
  if (userData.role === "admin") {
    display = (
      <AdminOrganization
        fetchUsersData={fetchUsersData}
        usersData={usersData}
      />
    );
  } else if (
    userData.organization &&
    usersData.hasOwnProperty(userData.organization) &&
    usersData[userData.organization].hasOwnProperty(userData.email)
  ) {
    const organizationName = userData.organization;
    display = (
      <MembersTable
        members={usersData[organizationName]}
        organizationName={organizationName}
        fetchUsersData={fetchUsersData}
      />
    );
  } else {
    display = (
      <div>
        <h1>Your are not in any organization.</h1>
        <p>Please let admin or organizer invite your to an organization.</p>
      </div>
    );
  }

  return (
    <div>
      <NavigationBar />
      <div className="organization-page">{display}</div>
    </div>
  );
}

export default OrganizationPage;
