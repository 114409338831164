// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
  width: auto;
}

.fields-customized-content {
  display: flex;
  flex-direction: column;
  width: 450px;
}

.field-input-content {
  width: 400px;
  margin: 0 auto;
}

.field-input-content select,
.field-input-content input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin: 0.1rem auto;
}

.down-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.field-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.top-button {
  display: flex;
  align-items: flex-start;
}

.down-button {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;
  width: 50%;
}

.field-button {
  display: flex;
  justify-content: flex-start;
  width: 48%;
  margin: 2.5px;
}

.button-text {
  color: gray;
  font-size: 0.8rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/LabelsPage/styles/FieldOpsPage.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,UAAU;EACV,aAAa;AACf;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".modal-content {\n  width: auto;\n}\n\n.fields-customized-content {\n  display: flex;\n  flex-direction: column;\n  width: 450px;\n}\n\n.field-input-content {\n  width: 400px;\n  margin: 0 auto;\n}\n\n.field-input-content select,\n.field-input-content input {\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ced4da;\n  border-radius: 4px;\n  margin: 0.1rem auto;\n}\n\n.down-container {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n}\n\n.field-button-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.top-button {\n  display: flex;\n  align-items: flex-start;\n}\n\n.down-button {\n  display: flex;\n  justify-content: flex-start;\n  margin-top: 0.5rem;\n  width: 50%;\n}\n\n.field-button {\n  display: flex;\n  justify-content: flex-start;\n  width: 48%;\n  margin: 2.5px;\n}\n\n.button-text {\n  color: gray;\n  font-size: 0.8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
