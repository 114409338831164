import axios from "axios";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Instrument from "../../../components/Instrument/instrument";
import Timer from "../../../components/Timer";
import "../../../styles/LabelsPageModal.css";
import "../styles/LabelsOpsPage.css";

function LabelsOperationModal(props) {
  const { item, loadDatasetList } = props;

  const [labelField, setLabelField] = useState(null);
  const [IoUThred, setIoUThred] = useState(0.8);
  const [confidenceThred, setConfidenceThred] = useState(0.9);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [mergeLoading, setMergeLoading] = useState(false);

  const handleChangeLabelField = (e) => {
    let newLabelField = e.target.value;
    setLabelField(newLabelField);
  };

  const handleChangeIoUThred = (e) => {
    const value = parseFloat(e.target.value);
    setIoUThred(isNaN(value) ? 0 : parseFloat(value.toFixed(2)));
  };

  const handleChangeConfidenceThred = (e) => {
    const value = parseFloat(e.target.value);
    setConfidenceThred(isNaN(value) ? 0 : parseFloat(value.toFixed(2)));
  };

  const deleteBadLabel = async () => {
    if (confidenceThred < 0 || confidenceThred > 1) {
      window.alert(
        `Confidence threshold is Invalid. Please choose a number between 0.0 to 1.0`
      );
      return;
    } else if (
      !item.sample_label_fields ||
      item.sample_label_fields.length === 0
    ) {
      window.alert(`There is no label in dataset '${item.name}'`);
      return;
    }
    setDeleteLoading(true);
    let url = `/datasets/delete/bad_label?dataset_name=${item.name}&label_field=${labelField}&confidence_threshold=${confidenceThred}`;
    if (
      item.sample_label_fields &&
      item.sample_label_fields.includes("ground_truth") &&
      item.sample_label_fields.includes("prediction")
    ) {
      url = `/datasets/delete/bad_label_IOU?dataset_name=${item.name}&confidence_threshold=${confidenceThred}&iou_threshold=${IoUThred}`;
    }
    axios
      .delete(url)
      .then((response) => {
        setDeleteLoading(false);
        loadDatasetList();
        window.alert(`Delete bad label on dataset '${item.name}' success`);
      })
      .catch((error) => {
        setDeleteLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const mergeLabel = async () => {
    if (!item.sample_label_fields || item.sample_label_fields.length === 0) {
      window.alert(`There is no label in dataset '${item.name}'`);
      return;
    }
    setMergeLoading(true);
    axios
      .put(`/dataset/merge_labels?dataset_name=${item.name}&`)
      .then((response) => {
        setMergeLoading(false);
        loadDatasetList();
        window.alert(`Merge label on dataset '${item.name}' success`);
      })
      .catch((error) => {
        setMergeLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      isOpen={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Modal.Title style={{ marginLeft: "10px" }}>
            Label Operations
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div class="label-input-content">
          {item.sample_label_fields &&
            item.sample_label_fields.includes("ground_truth") &&
            item.sample_label_fields.includes("prediction") && (
              <div>
                <label htmlFor="IoU-threshold">IOU threshold:</label>
                <input
                  id="IoU-threshold"
                  type="number"
                  min={0}
                  max={1}
                  step={0.1}
                  value={IoUThred}
                  onChange={handleChangeIoUThred}
                />
              </div>
            )}
          <div>
            <label htmlFor="confidence-threshold">Confidence threshold:</label>
            <input
              id="confidence-threshold"
              type="number"
              min={0}
              max={1}
              step={0.1}
              value={confidenceThred}
              onChange={handleChangeConfidenceThred}
            />
          </div>
          <div className="instrument-avator">
            <Instrument text="This action will delete every prediction label with confidence lower than threshold." />
          </div>
          <div>
            <select value={labelField} onChange={handleChangeLabelField}>
              <option value="">--Please choose an field--</option>
              <option value="ALL">--all fields--</option>
              {item.sample_label_fields &&
                item.sample_label_fields.map((field) => (
                  <option key={field} value={field}>
                    {field}
                  </option>
                ))}
            </select>
          </div>
          <div className="label-down-button">
            <Button
              variant="outline-danger"
              style={{ margin: "2.5px" }}
              onClick={deleteBadLabel}
            >
              {deleteLoading ? "Loading..." : "Delete Bad Label"}
            </Button>
            {/* <Button
              variant="success"
              style={{ margin: "2.5px" }}
              onClick={mergeLabel}
            >
              {mergeLoading ? "Loading..." : "Merge Label"}
            </Button> */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LabelsOperationModal;
