import { Modal } from "react-bootstrap";
import { useState } from "react";
import { Datasets } from "../../components/Dataset/Dataset";
import { Button } from "react-bootstrap";
import "../../styles/DatasetPage.css";
import NavigationBar from "../../components/NavBar/NavBar";
import SplitterPage from "./pages/SplitPage";
import PickerPage from "./pages/PickerPage";
import CutterPage from "./pages/CutterPage";

function DatasetPage() {
  return (
    <div>
      <NavigationBar />
      <div className="dataset-page">
        <Datasets CustomizedContent={CustomizedContent} />
      </div>
    </div>
  );
}

const nameToTitleName = {
  split: "split-dataset page",
  picker: "dataset picker page",
  cutter: "dataset cutter page",
};

function CustomizedContent(props) {
  const { item } = props;

  const [pageName, setPageName] = useState(null);

  const showModel = pageName && nameToTitleName.hasOwnProperty(pageName);

  const isCommon = item.folder !== "Yes" && item.table !== "Yes";
  if (!isCommon) {
    return (
      <div className="acquisition-data-customized-content">
        <p>You can not split dataset that represents a folder/table</p>
        {item.folder === "Yes" && (
          <div>
            <p>If you want to split a folder dataset,</p>
            <p>
              please click 'Folders' tab, but this feat is not implemented yet.
            </p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="dataset-customized-content">
      <p class="card-text">samples num: {item.samples.length}</p>
      <Button
        variant="outline-secondary"
        style={{ margin: "2.5px" }}
        onClick={() => setPageName("split")}
      >
        Split-Dataset
      </Button>
      <Button
        variant="outline-secondary"
        style={{ margin: "2.5px" }}
        onClick={() => setPageName("picker")}
      >
        Picker
      </Button>
      <Button
        variant="outline-secondary"
        style={{ margin: "2.5px" }}
        onClick={() => setPageName("cutter")}
      >
        Cutter
      </Button>
      <DatasetPageModal
        show={showModel}
        onHide={() => setPageName(null)}
        pageName={pageName}
        {...props}
      />
    </div>
  );
}

function DatasetPageModal(props) {
  const { pageName } = props;

  const nameToComponent = {
    split: <SplitterPage {...props} />,
    picker: <PickerPage {...props} />,
    cutter: <CutterPage {...props} />,
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      isOpen={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "10px" }}
          >
            {nameToTitleName[pageName]}
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>{nameToComponent[pageName]}</Modal.Body>
    </Modal>
  );
}

export default DatasetPage;
