import axios from "axios";

export const userSignupRequest = (userData) => {
  return async (dispatch) => {
    try {
      const response = await axios.post("/auth/register", userData);
      dispatch({ type: "USER_SIGNUP_SUCCESS", payload: response.data });
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const errorMessage = error.response.data.message;
        const statusCode = error.response.status;
        dispatch({ type: "USER_SIGNUP_FAILURE", payload: error.response.data });
        return Promise.reject({
          message: errorMessage,
          statusCode: statusCode,
        });
        // return Promise.reject({ message: errorMessage, statusCode });
      } else if (error.request) {
        let errorMsg = error.response.data;
        errorMsg = errorMsg ? errorMsg : "Network Error"
        dispatch({
          type: "USER_SIGNUP_FAILURE",
          payload: { message: errorMsg, statusCode: null },
        });
        return Promise.reject({ message: errorMsg, statusCode: null });
      } else {
        dispatch({
          type: "USER_SIGNUP_FAILURE",
          payload: { message: "Unknown Error", statusCode: null },
        });
        return Promise.reject({ message: "Unknown Error", statusCode: null });
      }
    }
  };
};
