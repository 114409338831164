import ConfigConnection from "../../components/Integration/ConfigConnection";
import NavigationBar from "../../components/NavBar/NavBar";

function SettingPage() {
  return (
    <div className="setting-page">
      <NavigationBar />
      <ConfigConnection />
      {/* <p>I am Setting page</p> */}
    </div>
  );
}

export default SettingPage;
