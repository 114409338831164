// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom styles for the card-text paragraph */
.export-customized-content .card-text {
  font-size: 16px;
  margin-bottom: 10px;
}

/* Custom styles for the cvat-info div */
.export-customized-content .cvat-info {
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin-top: 10px;
}

/* Custom styles for the cvat-info card-text paragraph */
.export-customized-content .cvat-info .card-text {
  font-size: 16px;
  margin: 0;
}

/* Custom styles for the input-content div */
.export-customized-content .input-content {
  margin-top: 20px;
}

/* Custom styles for the text-container div */
.export-customized-content .text-container {
  margin-bottom: 5px;
  margin-top: 1rem;
}

/* Custom styles for the input-field text input */
.export-customized-content #input-field {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
}

/* Custom styles for the button-container div */
.export-customized-content .button-container {
  margin-top: 10px;
}

.Button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ExportPage/ExportPage.css"],"names":[],"mappings":"AAAA,8CAA8C;AAC9C;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA,wCAAwC;AACxC;EACE,sBAAsB;EACtB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA,wDAAwD;AACxD;EACE,eAAe;EACf,SAAS;AACX;;AAEA,4CAA4C;AAC5C;EACE,gBAAgB;AAClB;;AAEA,6CAA6C;AAC7C;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA,iDAAiD;AACjD;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;AACjB;;AAEA,+CAA+C;AAC/C;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;AACvB","sourcesContent":["/* Custom styles for the card-text paragraph */\n.export-customized-content .card-text {\n  font-size: 16px;\n  margin-bottom: 10px;\n}\n\n/* Custom styles for the cvat-info div */\n.export-customized-content .cvat-info {\n  background-color: #fff;\n  padding: 10px;\n  border: 1px solid #ced4da;\n  border-radius: 4px;\n  margin-top: 10px;\n}\n\n/* Custom styles for the cvat-info card-text paragraph */\n.export-customized-content .cvat-info .card-text {\n  font-size: 16px;\n  margin: 0;\n}\n\n/* Custom styles for the input-content div */\n.export-customized-content .input-content {\n  margin-top: 20px;\n}\n\n/* Custom styles for the text-container div */\n.export-customized-content .text-container {\n  margin-bottom: 5px;\n  margin-top: 1rem;\n}\n\n/* Custom styles for the input-field text input */\n.export-customized-content #input-field {\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ced4da;\n  border-radius: 4px;\n  font-size: 16px;\n}\n\n/* Custom styles for the button-container div */\n.export-customized-content .button-container {\n  margin-top: 10px;\n}\n\n.Button {\n  margin-top: 0.5rem;\n  margin-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
