import axios from "axios";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import "react-dropdown/style.css";

function DeleteSamplesPage(props) {
  let { item, loadDatasetList } = props;

  let [deleteLoading, setDeleteLoading] = useState(false);

  const deleteSelectedSamples = async () => {
    if (!item.samples.length || item.samples.length === 0) {
      window.alert(`There is no sample in dataset '${item.name}'`);
      return;
    }
    setDeleteLoading(true);
    let url = `/datasets/delete/selected_samples?dataset_name=${item.name}`;
    axios
      .delete(url)
      .then((response) => {
        setDeleteLoading(false);
        loadDatasetList();
        window.alert(
          `Delete selected samples on dataset '${item.name}' success`
        );
      })
      .catch((error) => {
        setDeleteLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  return (
    <div className="delete-samples-page">
      <p class="card-text">
        media type: {item.media_type ? item.media_type : "null"}
      </p>
      <div class="clear-data">
        <div class="sample-container">
          <p class="card-text">samples num: {item.samples.length}</p>
        </div>
      </div>
      <Button
        variant="outline-danger"
        type="button"
        onClick={deleteSelectedSamples}
        disabled={deleteLoading}
      >
        {deleteLoading ? "Loading..." : "Delete Selected"}
      </Button>
    </div>
  );
}

export default DeleteSamplesPage;
