export const IMG_EXTS = [
  "png",
  "jpg",
  "jpeg",
  "jfif",
  "tiff",
  "ppm",
  "bmp",
  "gif",
  "webp",
];

export const AUDIO_EXTS = ["flac", "m4a", "mp3", "ogg", "wav"];

export const VIDEO_EXTS = [
  "avi",
  "mp4",
  "mkv",
  "mov",
  "flv",
  "wmv",
  "mpg",
  "mpeg",
  "webm",
  "3gp",
  "ogv",
];
