import { LOGIN_SUCCESS } from '../actions/authActionTypes';
import { SET_CURRENT_USER, SET_CURRENT_USER_ROLE } from '../../constant';
import isEmpty from 'lodash/isEmpty';

const initialState = {
  isLoggedIn: false,
  redirectUrl: null,
  user: {},
  isAuthenticated: false,
  role: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        redirectUrl: action.payload,
        role: action.payload
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        user: action.user,
        isAuthenticated: !isEmpty(action.user)
      };
    case SET_CURRENT_USER_ROLE:
      return {
        ...state,
        role: action.payload
      };
    default:
      return state;
  }
};

export default auth;
