// ConfigTab.js
import ConfigInput from "./ConfigInput";

function ConfigTab(props) {
  const { item, tab, config, setConfig } = props;

  return (
    <div className={`tab-pane fade${tab === "Common" ? " show active" : ""}`} id={`nav-${tab}-${item.name}`} role="tabpanel" aria-labelledby={`nav-${tab}-${item.name}-tab`} tabIndex="0">
      {Object.keys(config[tab]).map((key) => {
        const value = config[tab][key];
        return (
          <ConfigInput
            key={`${item.name}-${tab}-${key}`}
            label={key}
            value={value}
            onChange={(newValue) => setConfig({ ...config, [tab]: { ...config[tab], [key]: newValue } })}
          />
        );
      })}
    </div>
  );
}

export default ConfigTab;
