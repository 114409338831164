import Cookies from "js-cookie";
import { useState } from "react";
import "../../Auth/Auth.css";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import Timer from "../../../components/Timer";
import { useDataContext } from "../../../utils/DataContext";
function SigninForm(props) {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [signinloading, setSigninloading] = useState(false);

  const { updateUserInfo } = useDataContext();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.email) {
      formErrors.email = "Email is required";
    }
    if (!formData.password) {
      formErrors.password = "Password is required";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setSigninloading(true);
      try {
        localStorage.clear();
        const response = await props.authActions.login(formData);
        if (response) {
          setSigninloading(false);
          // Login successful, save auth.role to LocalStorage
          Cookies.set("user_token", response.data.token);
          // Login successful, navigate to the home page
          navigate("/acquisition");
        } else {
          setSigninloading(false);
          // Login failed, show an error message
          window.alert("Login failed. Please check your credentials.");
        }
      } catch (error) {
        setSigninloading(false);
        const errorMessage = error.message;
        window.alert(errorMessage);
      }
    }
    updateUserInfo();
  };

  return (
    <div className="signup-container">
      <form onSubmit={handleSubmit}>
        <div className="form-title">
          <h3>Sign In</h3>
        </div>
        <div>
          <label className="control-label">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="abc@gmail.com"
            className={classNames("form-control", {
              "is-invalid": errors.email,
            })}
          />
          {errors.email && (
            <span className="form-text text-muted">{errors.email}</span>
          )}
        </div>
        <div>
          <label className="control-label">Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password here"
            className={classNames("form-control", {
              "is-invalid": errors.password,
            })}
          />
          {errors.password && (
            <span className="form-text text-muted">{errors.password}</span>
          )}
        </div>

        <div className="d-grid">
          <div className="button-container">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={signinloading}
              style={{ margin: "auto" }}
            >
              {signinloading ? (
                <span>
                  <Timer loadingStates={[signinloading]} />
                </span>
              ) : (
                "Sign In"
              )}
            </button>
          </div>
        </div>
        <p className="forgot-password text-right">
          Don't have account <a href="/signup">Sign Up?</a>
        </p>
      </form>
    </div>
  );
}

export default SigninForm;
