export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';


// Other action creators...

export const loginSuccess = (data) => {
  return {
    type: LOGIN_SUCCESS,
    payload: data
  };
};

 

