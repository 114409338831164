import axios from "axios";
import { useState } from "react";
import { Table, Button, Modal, Form } from "react-bootstrap";
import { useDataContext } from "../../../utils/DataContext";
import { useNavigate } from "react-router-dom";

function MembersTable(props) {
  const { members, organizationName, fetchUsersData } = props;

  const [inviteeEmail, setInviteeEmail] = useState(null);
  const [showInvitationModal, setShowInvitationModal] = useState(false);

  const { userToken, userData } = useDataContext();

  const navigate = useNavigate();

  const inviteMember = async () => {
    if (!inviteeEmail) {
      window.alert("Please input an email.");
      return;
    }
    const url = "/auth/organization/invite";
    axios
      .post(url, {
        requesterToken: userToken,
        organization: organizationName,
        email: inviteeEmail,
      })
      .then((response) => {
        fetchUsersData();
        window.alert(
          `Invite '${inviteeEmail}' to '${organizationName}' success.`
        );
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const removeMumber = async (email) => {
    const url = "/auth/organization/member/remove";
    axios
      .post(url, {
        requesterToken: userToken,
        email: email,
      })
      .then((response) => {
        fetchUsersData();
        window.alert(`Remove '${email}' from '${organizationName}' success.`);
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const addOrganizer = async (email) => {
    const url = "/auth/organization/organizer/add";
    axios
      .post(url, {
        requesterToken: userToken,
        email: email,
      })
      .then((response) => {
        fetchUsersData();
        window.alert(
          `Add '${email}' as organizer in '${organizationName}' success.`
        );
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const deleteOrganizer = async (email) => {
    const url = "/auth/organization/organizer/delete";
    axios
      .post(url, {
        requesterToken: userToken,
        email: email,
      })
      .then((response) => {
        fetchUsersData();
        window.alert(
          `Delete '${email}' as organizer from '${organizationName}' success.`
        );
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const quitOrganization = () => {
    removeMumber(userData.email);
    navigate("/signin");
  };

  const hasPermission =
    userData &&
    (userData.role === "admin" || userData.organization_role === "organizer");

  const MembersTableComponent = (
    <Table>
      <thead>
        <tr>
          <th scope="col">Email</th>
          <th scope="col">Organization Role</th>
          <th scope="col" />
          <th scope="col" />
        </tr>
      </thead>
      <tbody class="table-group-divider">
        {members &&
          Object.entries(members).map(([email, role]) => {
            const closeButton = (
              <button
                onClick={() => removeMumber(email)}
                type="button"
                class="btn-close"
                aria-label="Close"
              />
            );
            let organizerButton;
            if (userData && userData.role === "admin") {
              if (role === "regular") {
                organizerButton = (
                  <Button onClick={() => addOrganizer(email)}>
                    Add Organizer
                  </Button>
                );
              } else {
                organizerButton = (
                  <Button
                    onClick={() => deleteOrganizer(email)}
                    variant="outline-danger"
                  >
                    Delete Organizer
                  </Button>
                );
              }
            }
            return (
              <tr>
                <td>{email}</td>
                <td>{role}</td>
                <td>{hasPermission && organizerButton}</td>
                <td>{hasPermission && closeButton}</td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );

  const InvitationModal = (
    <Modal
      show={showInvitationModal}
      onHide={() => {
        setShowInvitationModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Invite new member to <strong>{organizationName}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type="text"
          placeholder="Invitee Email"
          value={inviteeEmail}
          onChange={(e) => {
            setInviteeEmail(e.target.value);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={inviteMember}>
          Invite
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div>
      <h5>Organization: {organizationName}</h5>
      {hasPermission && (
        <Button
          onClick={() => {
            setShowInvitationModal(true);
          }}
        >
          + Invite new member
        </Button>
      )}
      {userData.role !== "admin" && (
        <Button onClick={quitOrganization} variant="outline-danger">
          Quit Organization
        </Button>
      )}
      {MembersTableComponent}
      {InvitationModal}
    </div>
  );
}

export default MembersTable;
