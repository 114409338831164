import axios from "axios";
import { useState } from "react";
import { Button } from "react-bootstrap";
import Timer from "../../../../components/Timer";
import { useDataContext } from "../../../../utils/DataContext";
import { VIDEO_EXTS } from "../../../../constant/FileType";

function FolderPage(props) {
  const { item } = props;

  const { folderList, availSpace } = useDataContext();

  const [folder, setFolder] = useState(null);
  const [file, setFile] = useState(null);
  const [fps, setFps] = useState(null);
  const [imgQuality, setImgQuality] = useState(null);
  const [youtubeLink, setYoutubeLink] = useState(null);

  const [fileLoading, setFileLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const deleteFolder = async () => {
    if (!folder) {
      window.alert("Please select a folder.");
      return;
    }
    setDeleteLoading(true);
    axios
      .delete(`/file/folder/delete?folder_name=${folder}`)
      .then((response) => {
        setDeleteLoading(false);
        let msg = `${folder} delete success.`;
        window.alert(msg);
      })
      .catch((error) => {
        setDeleteLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const uploadData = async () => {
    if (!folder) {
      window.alert("Please select a folder.");
      return;
    }
    if (!file) {
      window.alert("Please upload a file.");
      return;
    }
    setFileLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    let url = `/file/upload?folder_name=${folder}`;
    if (fps) {
      url += `&fps=${fps}`;
    }
    if (imgQuality) {
      if (imgQuality < 1 || imgQuality > 100) {
        window.alert("Image quality should between 1 and 100.");
        return;
      }
      url += `&image_quality=${imgQuality}`;
    }
    axios
      .post(url, formData)
      .then((response) => {
        setFileLoading(false);
        let msg = `File '${file.name}' upload success.`;
        window.alert(msg);
      })
      .catch((error) => {
        setFileLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const downloadYouTube = async () => {
    if (!folder) {
      window.alert("Please select a folder.");
      return;
    }
    if (!youtubeLink) {
      window.alert("Please enter a YouTube Link.");
      return;
    }
    setDownloading(true);
    let url = `/file/video/youtube?folder_name=${folder}&link=${youtubeLink}`;
    axios
      .put(url)
      .then((response) => {
        setDownloading(false);
        let msg = `Download YouTube video to folder '${folder}' success.`;
        window.alert(msg);
      })
      .catch((error) => {
        setDownloading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const isvideofolder = () => folder.startsWith("video_");

  const importable = () => {
    if (!folder) {
      return false;
    }
    const videofolder = isvideofolder();
    if (item.media_type === "image" || !videofolder) {
      return true;
    } else if (item.media_type === "video" || videofolder) {
      return true;
    } else {
      return false;
    }
  };

  const isVideoFile = () => {
    if (!file || !file.name) {
      return false;
    }
    const exts = file.name.split(".").pop();
    return VIDEO_EXTS.includes(exts);
  };

  return (
    <div>
      <div className="folder-operates">
        <h5>Step2. Choose Folder and Upload </h5>
        <p>
          Tips: 1. If you are uploading a compressed file, it will be auto
          unzipped. 2. If you are uploading a video file. it will be auto
          converted to frame images. 3. If you are uploading a compressed video
          file, it will be auto unzipped & converted to images.
        </p>
        <p>
          2. After uploading, please click the <b>Load to Dataset</b>{" "}
        </p>
        <p>System available space: {availSpace}</p>
        <div className="folder-select-option-container">
          <select
            id="folder-select"
            value={folder}
            onChange={(e) => {
              setFolder(e.target.value);
            }}
            className="folder-select-option"
          >
            <option value="">Please choose an option</option>
            {folderList &&
              folderList.map((item) => (
                <option key={item.name} value={item.name}>
                  {item.name} {item.size}
                </option>
              ))}
          </select>
        </div>
        <div>
          <label htmlFor="file-upload">Upload File</label>
        </div>
        <div>
          <input
            id="file-upload"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
          {file && <p>File: {file.name}</p>}
        </div>
        {file && isVideoFile() && !isvideofolder() && (
          <div>
            <label htmlFor="frame-rate">Frame Rate in fps</label>
            <input
              id="frame-rate"
              type="number"
              value={fps}
              onChange={(e) => setFps(e.target.value)}
            />
            <label htmlFor="image-quality">Image Quality in %</label>
            <input
              id="image-quality"
              type="number"
              value={imgQuality}
              max={100}
              min={1}
              onChange={(e) => setImgQuality(e.target.value)}
            />
          </div>
        )}
        {folder && isvideofolder() && (
          <div>
            <label htmlFor="youtube-link">YouTube Link:</label>
            <input
              id="youtube-link"
              type="text"
              value={youtubeLink}
              onChange={(e) => setYoutubeLink(e.target.value)}
            />
            <Button onClick={downloadYouTube} disabled={downloading}>
              {downloading ? (
                <span>
                  <Timer loadingStates={[downloading]} />
                </span>
              ) : (
                "Download"
              )}
            </Button>
          </div>
        )}
        <div class="data-button-container">
          <Button onClick={uploadData} disabled={fileLoading}>
            {fileLoading ? (
              <span>
                <Timer loadingStates={[fileLoading]} />
              </span>
            ) : (
              "Upload"
            )}
          </Button>
          <Button
            variant="outline-danger"
            onClick={deleteFolder}
            disabled={deleteLoading}
          >
            {deleteLoading ? (
              <span>
                <Timer loadingStates={[deleteLoading]} />
              </span>
            ) : (
              "Delete folder"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FolderPage;
