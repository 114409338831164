import axios from "axios";
import { useEffect, useState } from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Timer from "../Timer";
import "./ConfigConnection.css";

function ConfigConnection() {
  const [connections, setConnections] = useState({});

  const [checkLoading, setCheckLoading] = useState(false);
  const [connectLoading, setConnectLoading] = useState(false);

  const getConnections = async () => {
    axios
      .get("/infras/config/get")
      .then((response) => {
        const newConnections = response.data;
        setConnections(newConnections);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  useEffect(() => {
    getConnections();
  }, []);

  const checkURL = {
    kubeflow: `/infras/kubeflow/check?url=${connections.KUBEFLOW_URL}`,
    label_studio: `/infras/label_studio/check?url=${connections.LABEL_STUDIO_URL}&api_key=${connections.LABEL_STUDIO_API_KEY}`,
  };

  const connectURL = {
    kubeflow: `/infras/kubeflow/set?url=${connections.KUBEFLOW_URL}`,
    label_studio: `/infras/label_studio/set?url=${connections.LABEL_STUDIO_URL}&api_key=${connections.LABEL_STUDIO_API_KEY}`,
  };

  const check = async (app) => {
    if (!checkURL.hasOwnProperty(app)) {
      window.alert(`Connection to ${app} not supported yet.`);
    }
    setCheckLoading(true);
    axios
      .get(checkURL[app])
      .then((response) => {
        window.alert(`Check connect to ${app} success.`);
        setCheckLoading(false);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
        setCheckLoading(false);
      });
  };

  const connect = async (app) => {
    if (!connectURL.hasOwnProperty(app)) {
      window.alert(`Connection to ${app} not supported yet.`);
    }
    setConnectLoading(true);
    axios
      .put(connectURL[app])
      .then((response) => {
        getConnections();
        window.alert(`Connect to ${app} success.`);
        setConnectLoading(false);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
        setConnectLoading(false);
      });
  };

  const actions = {
    checkKubeflow: () => {
      check("kubeflow");
    },
    connectKubeflow: () => {
      connect("kubeflow");
    },
    checkLabelStudio: () => {
      check("label_studio");
    },
    connectLabelStudio: () => {
      connect("label_studio");
    },
  };

  function CheckButton(props) {
    const { action } = props;
    return (
      <Button variant="light" onClick={action} disabled={checkLoading}>
        {checkLoading ? (
          <span>
            <Timer loadingStates={[checkLoading]} />
          </span>
        ) : (
          "Check"
        )}
      </Button>
    );
  }

  function ConnectButton(props) {
    const { action } = props;
    return (
      <Button variant="success" onClick={action} disabled={connectLoading}>
        {connectLoading ? (
          <span>
            <Timer loadingStates={[connectLoading]} />
          </span>
        ) : (
          "Connect"
        )}
      </Button>
    );
  }

  return (
    <div className="config-connection">
      <Tabs defaultActiveKey="general" id="uncontrolled-tab-example">
        <Tab eventKey="kubeflow" title="Kubeflow">
          <div>
            <label htmlFor="kubeflow-url">URL</label>
            <input
              id="kubeflow-url"
              type="text"
              value={connections.KUBEFLOW_URL}
              onChange={(e) =>
                setConnections((prevConns) => ({
                  ...prevConns,
                  KUBEFLOW_URL: e.target.value,
                }))
              }
            />
          </div>
          <div class="data-button-container">
            <CheckButton action={actions.checkKubeflow} />
            <ConnectButton action={actions.connectKubeflow} />
          </div>
        </Tab>
        <Tab eventKey="labelStudio" title="Label Studio">
          <div>
            <label htmlFor="label-studio-url">URL</label>
            <input
              id="label-studio-url"
              type="text"
              value={connections.LABEL_STUDIO_URL}
              onChange={(e) =>
                setConnections((prevConns) => ({
                  ...prevConns,
                  LABEL_STUDIO_URL: e.target.value,
                }))
              }
            />
          </div>
          <div>
            <label htmlFor="label-studio-api-key">API Key</label>
            <input
              id="label-studio-api-key"
              type="text"
              value={connections.LABEL_STUDIO_API_KEY}
              onChange={(e) =>
                setConnections((prevConns) => ({
                  ...prevConns,
                  LABEL_STUDIO_API_KEY: e.target.value,
                }))
              }
            />
          </div>
          <div class="data-button-container">
            <CheckButton action={actions.checkLabelStudio} />
            <ConnectButton action={actions.connectLabelStudio} />
          </div>
        </Tab>
        <Tab eventKey="general" title="General Setting">
          <GeneralSetting />
        </Tab>
      </Tabs>
    </div>
  );
}

function GeneralSetting() {
  const [switchValue, setSwitchValue] = useState(false);
  const [businessClientList, setBusinessClientList] = useState([]);
  const [businessClient, setBusinessClient] = useState(null);
  const [modelPvc, setModelPvc] = useState(null);
  const [rsynchost, setRsynchost] = useState(null);

  const loadbusinessClientList = async () => {
    axios
      .get("/infras/business_clients/list")
      .then((response) => {
        const newBusinessClientItems = response.data;
        setBusinessClientList(newBusinessClientItems.concat());
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  useEffect(() => {
    loadbusinessClientList();
  }, []);

  const handleSwitchPublic = async () => {
    axios
      .put(`/infras/public_server/set?is_public_server=${switchValue}`)
      .then((response) => {
        window.alert("submit successful");
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const submitBusinessClient = async () => {
    let url = `/infras/business_client/set`;
    if (businessClient) {
      url += `?business_client=${businessClient}`;
    }
    axios
      .put(url)
      .then((response) => {
        window.alert("submit successful");
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const submitModelPvc = async () => {
    let url = `/infras/model_pvc/set`;
    if (modelPvc) {
      url += `?model_pvc=${modelPvc}`;
    }
    axios
      .put(url)
      .then((response) => {
        window.alert("submit successful");
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const submitRsynchost = async () => {
    let url = `/infras/rsync_host/set`;
    if (rsynchost) {
      url += `?rsync_host=${rsynchost}`;
    }
    axios
      .put(url)
      .then((response) => {
        window.alert("submit successful");
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };
  return (
    <div>
      <div className="setting-switch-container">
        <div className="label">
          <label>Public Server</label>
        </div>
        <Form.Check
          type="switch"
          id="public-server-switch"
          checked={switchValue}
          onChange={() => setSwitchValue(!switchValue)}
        />
      </div>
      <div>
        <Button variant="success" onClick={handleSwitchPublic}>
          Submit
        </Button>
      </div>
      <div className="business-client">
        <div className="label">
          <label htmlFor="business-client-select">Choose Business Client</label>
        </div>
        <div>
          <select
            id="business-client-select"
            value={businessClient}
            onChange={(e) => setBusinessClient(e.target.value)}
          >
            <option value={null}>--Please choose an option--</option>
            {businessClientList.map((client) => (
              <option key={client} value={client}>
                {client}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <Button variant="success" onClick={submitBusinessClient}>
          Submit
        </Button>
      </div>
      <div className="model-pvc">
        <div className="label">
          <label htmlFor="model-pvc-select">
            Choose model pvc where pipeline get model files
          </label>
        </div>
        <div>
          <select
            id="model-pvc-select"
            value={modelPvc}
            onChange={(e) => setModelPvc(e.target.value)}
          >
            <option value={null}>--Please choose an option--</option>
            <option value={"models"}>models</option>
            <option value={"taipei-train-data"}>taipei-train-data</option>
          </select>
        </div>
      </div>
      <div>
        <Button variant="success" onClick={submitModelPvc}>
          Submit
        </Button>
      </div>
      <div className="rsync-host">
        <div className="label">
          <label htmlFor="rsync-host">
            Choose rsync host for public pre-labeling
          </label>
        </div>
        <div>
          <select
            id="rsync-host"
            value={rsynchost}
            onChange={(e) => setRsynchost(e.target.value)}
          >
            <option value={null}>--Please choose an option--</option>
            <option value={"daat"}>daat</option>
            <option value={"shark"}>shark</option>
            <option value={"intern"}>intern</option>
          </select>
        </div>
      </div>
      <div>
        <Button variant="success" onClick={submitRsynchost}>
          Submit
        </Button>
      </div>
    </div>
  );
}

export default ConfigConnection;
