import { useNavigate } from "react-router-dom";

const LinkStyle = {
  color: "blue",
  textDecoration: "underline",
  cursor: "pointer",
};

function EmptyDataset() {
  const navigate = useNavigate();

  return (
    <div className="empty-dataset">
      <h6>This dataset is empty</h6>
      <p>To load data, please:</p>
      <p>
        Go to{" "}
        <a
          onClick={() => {
            navigate("/acquisition");
          }}
          style={LinkStyle}
        >
          <strong>Acquisition-Data</strong>
        </a>
        .
      </p>
    </div>
  );
}

export default EmptyDataset;
