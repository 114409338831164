import "../../styles/Folder.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { Tabs, Tab, Button } from "react-bootstrap";
import { useDataContext } from "../../utils/DataContext";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import UploadModal from "./UploadModal";
import DeleteModal from "./DeleteModal";
import { SpinnerCircularSplit } from "spinners-react";
import { getUserCookie } from "../../utils/cookiesUtils";

function Folders(props) {
  const { CustomizedContent, onlytype } = props;

  const [checkedItems, setCheckedItems] = useState([]);

  return (
    <div className="daat-folders">
      <InputGroup />
      <FolderGallery
        CustomizedContent={CustomizedContent}
        checkedItems={checkedItems}
        setCheckedItems={setCheckedItems}
        onlytype={onlytype}
      />
    </div>
  );
}

function InputGroup() {
  const { fetchList, folderType } = useDataContext();

  const [folderName, setFolderName] = useState(null);

  const [showAddModal, setShowAddModal] = useState(false);
  const [deleteAllLoading, setDeleteAllLoading] = useState(false);

  const addFolder = async () => {
    if (!folderName) {
      window.alert("Please input a folder name.");
      return;
    }
    let url = `/file/folder/create?folder_name=${folderName}`;
    if (folderType) {
      url += `&folder_type=${folderType}`;
    }
    axios
      .put(url)
      .then((response) => {
        fetchList();
        window.alert(`Folder "${folderName}" has been created successfully.`);
        setShowAddModal(false);
        setFolderName(null);
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const deleteAllFolder = async () => {
    setDeleteAllLoading(true);
    axios
      .delete(`/file/folder/delete/all`)
      .then((response) => {
        setDeleteAllLoading(false);
        fetchList();
      })
      .catch((error) => {
        setDeleteAllLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const addFolderModal = (
    <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add new folder</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type="text"
          id="folder-name"
          placeholder="Folder Name"
          value={folderName}
          onChange={(e) => setFolderName(e.target.value)}
        />
        <div className="folder-type-select-option-container">
          <label htmlFor="media-type-select">folder type: </label>
          <strong>{folderType}</strong>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowAddModal(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={addFolder}>
          Add
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div className="input-group">
      <div class="input-group mb-3">
        <div className="folder-group">
          <div className="folder-option">
            <Button variant="outline-danger">Delete Selected</Button>
            <Button
              variant="outline-danger"
              onClick={deleteAllFolder}
              disabled={deleteAllLoading}
            >
              {deleteAllLoading ? "Loading..." : "Delete All"}
            </Button>
          </div>
          <div className="add-group">
            <Button variant="primary" onClick={() => setShowAddModal(true)}>
              + Add new folder
            </Button>
          </div>
        </div>
        {addFolderModal}
      </div>
    </div>
  );
}

function FolderGallery(props) {
  const { CustomizedContent, checkedItems, setCheckedItems, onlytype } = props;

  const { folderList, setFolderType } = useDataContext();
  const folderItems = folderList;

  const imageFolderItems = folderItems.filter((item) => item.type === "image");
  const videoFolderItems = folderItems.filter((item) => item.type === "video");
  const audioFolderItems = folderItems.filter((item) => item.type === "audio");
  const textFolderItems = folderItems.filter((item) => item.type === "text");

  const FoldersCards = (props) => {
    const { items } = props;
    return (
      <div className="folderGallery">
        {items &&
          items.map((item) => {
            return (
              <FolderItem
                key={`folder-${item.name}`}
                item={item}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
                CustomizedContent={CustomizedContent}
              />
            );
          })}
      </div>
    );
  };

  let display;
  if (onlytype && onlytype === "text") {
    display = <FoldersCards items={textFolderItems} />;
  } else {
    display = (
      <Tabs
        defaultActiveKey="image"
        onSelect={(eventkey) => {
          setFolderType(eventkey);
        }}
      >
        <Tab eventKey="image" title="IMAGE">
          <FoldersCards items={imageFolderItems} />
        </Tab>
        <Tab eventKey="video" title="VIDEO">
          <FoldersCards items={videoFolderItems} />
        </Tab>
        <Tab eventKey="audio" title="AUDIO">
          <FoldersCards items={audioFolderItems} />
        </Tab>
        <Tab eventKey="text" title="TEXT">
          <FoldersCards items={textFolderItems} />
        </Tab>
      </Tabs>
    );
  }

  const userData = getUserCookie();
  const role = userData ? userData.role : null;
  if (role === "annotator") {
    display = <FoldersCards items={imageFolderItems} />;
  }

  return (
    <div className="Tabs-folderGallery">
      {folderItems.length === 0 ? (
        <SpinnerCircularSplit size="5.5rem" thickness={125} />
      ) : (
        display
      )}
    </div>
  );
}

function FolderItem(props) {
  const { item, checkedItems, setCheckedItems, CustomizedContent } = props;

  const { fetchList } = useDataContext();

  const [checked, setChecked] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [showCloseConfirmModal, setShowCloseConfirmModal] = useState(false);

  useEffect(() => {
    setChecked(checkedItems.includes(item.name));
  }, [checkedItems, item.name]);

  const deleteFolder = async (folderName, folderType, clearData = null) => {
    let url = `/file/folder/delete?folder_name=${folderName}`;
    if (folderType) {
      url += `&folder_type=${folderType}`;
    }
    if (clearData === true) {
      url += "&clear_data=true";
    }
    setIsDeleting(true);
    axios
      .delete(url)
      .then((response) => {
        setIsDeleting(false);
        setShowCloseConfirmModal(false);
        fetchList();
        let newCheckedItems = checkedItems.filter(
          (name) => name !== folderName
        );
        setCheckedItems(newCheckedItems.slice());
      })
      .catch((error) => {
        setIsDeleting(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const handleCheckboxChange = (e) => {
    let isChecked = e.target.checked;
    setChecked(isChecked);
    let newCheckedItems = [];
    if (isChecked) {
      newCheckedItems = checkedItems.concat([item.name]);
    } else {
      newCheckedItems = checkedItems.filter((name) => name !== item.name);
    }
    setCheckedItems(newCheckedItems.slice());
  };

  let content = null;
  if (CustomizedContent) {
    content = <CustomizedContent item={item} />;
  }

  return (
    <div class="card" style={{ width: "18rem" }}>
      <div class="container-fluid">
        <div class="row">
          <div class="col col-xl-6 d-flex justify-content-start px-2 py-2">
            <input
              class="form-check-input"
              type="checkbox"
              checked={checked}
              onChange={handleCheckboxChange}
              style={{ left: "0.5rem", top: "0.3rem", scale: "1.5" }}
            />
          </div>
          <div class="col col-xl-6 d-flex justify-content-end px-2 py-2">
            <button
              onClick={() => setShowCloseConfirmModal(true)}
              type="button"
              className="btn-close"
              aria-label="Close"
            />
          </div>
        </div>
      </div>
      <div class="card-body" style={{ marginTop: "-1.0rem" }}>
        <button
          type="button"
          class="btn btn-light"
          onClick={() => {
            if (item.type === "image" || item.type === "video") {
              const w = window.open("about:blank");
              w.location.href = `http://${window.location.hostname}:5151/datasets/[folder]${item.name}`;
            }
          }}
        >
          <h5 class="card-title">{item.name}</h5>
        </button>
        <UploadContent item={item} />
        {content}
      </div>
      <DeleteModal
        show={showCloseConfirmModal}
        onHide={() => setShowCloseConfirmModal(false)}
        item={item}
        deleteFolder={deleteFolder}
        isDeleting={isDeleting}
      />
    </div>
  );
}

function UploadContent(props) {
  const { item } = props;

  const [showUploadModal, setShowUploadModal] = useState(false);

  return (
    <div className="folder-upload-content">
      <p className="card-text">file number: {item.file_number}</p>
      <p className="card-text">size: {item.size}</p>
      <div class="button-container">
        <Button
          className="upload-button"
          onClick={() => setShowUploadModal(true)}
        >
          Upload Data
        </Button>
        <UploadModal
          show={showUploadModal}
          onHide={() => setShowUploadModal(false)}
          item={item}
        />
      </div>
    </div>
  );
}

export { Folders };
