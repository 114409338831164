import { Button, Modal, Tooltip, OverlayTrigger } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Timer from "../../components/Timer";
import { VIDEO_EXTS } from "../../constant/FileType";
import { useDataContext } from "../../utils/DataContext";

const LinkStyle = {
  color: "blue",
  textDecoration: "underline",
  cursor: "pointer",
};

const frameRateTooltip = (
  <div>
    <p>
      <a
        onClick={() => {
          const w = window.open("about:blank");
          w.location.href = `https://en.wikipedia.org/wiki/Frame_rate`;
        }}
        style={LinkStyle}
      >
        <strong>Frame Rate</strong>
      </a>{" "}
      is typically the frequency at which consecutive images are captured or
      displayed.{" "}
    </p>
    <p>
      If the input frame rate is greater than 5, it will take 5 to convert to
      frames.
    </p>
  </div>
);

function UploadModal(props) {
  const { item, loadDatasetList, onHide } = props;

  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [fps, setFps] = useState(null);
  const [imgQuality, setImgQuality] = useState(100);

  const [fileLoading, setFileLoading] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  const handleChangeImgQuality = (e) => {
    let newSplitNum = e.target.value;
    if (newSplitNum < 1 || newSplitNum > 100) {
      if (!newSplitNum) {
        setImgQuality(100);
      }
      return;
    }
    setImgQuality(newSplitNum);
  };

  const isVideoFile = () => {
    if (!file || !file.name) {
      return false;
    }
    const exts = file.name.split(".").pop();
    return VIDEO_EXTS.includes(exts);
  };

  const uploadFile = async () => {
    if (!file) {
      window.alert("Please upload a file.");
      return;
    }
    setShowProgress(true);
    setFileLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    let delta = 80;
    let timer = null;
    let url = `/file/upload?folder_name=${item.name}`;
    if (fps) {
      url += `&fps=${fps}`;
    }
    if (imgQuality) {
      if (imgQuality < 1 || imgQuality > 100) {
        window.alert("Image quality should between 1 and 100.");
        return;
      }
      url += `&image_quality=${imgQuality}`;
    }
    try {
      const response = await axios.post(url, formData, {
        onUploadProgress: (progressEvent) => {
	  console.log("progressEvent:",progressEvent);
          if (progressEvent.total > 0) {
            const percent = Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            );
	    console.log("percent:",percent);
            const dataTotal = progressEvent.total / (1024 * 1024);
            if (percent <= 80) {
              setProgress(percent);
            } else {
              if (!timer) {
                timer = setInterval(() => {
                  delta += (100 - delta) / (0.11 * dataTotal + 13);
                  const newProgress = Math.min(99, Math.round(delta));
                  setProgress(newProgress);

                  if (delta >= 100) {
                    clearInterval(timer); // 处理完成，清除定时器
                    timer = null;
                    setProgress(99);
                  }
                }, 1000);
              }
            }
          }
        },
      });

      setProgress(100);
      // 使用setTimeout等待一段时间后再显示上传成功的消息
      setTimeout(() => {
        setFileLoading(false);
        setShowProgress(false);
        loadDatasetList();
        let msg = `File '${file.name}' upload success.`;
        window.alert(msg);
      }, 500); // 这里设置为0.5秒
    } catch (error) {
      setProgress(0); // 上传失败，重置进度
      setFileLoading(false);
      let errorMsg = error.response.data;
      window.alert(errorMsg);
    }
    onHide();
  };

  return (
    <Modal {...props} size="lg">
      <Modal.Header closeButton>
        <Modal.Title style={{ marginLeft: "10px" }}>Upload Data</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: "flex", flexDirection: "column" }}>
        <div class="file-input" style={{ width: "100%" }}>
          <input
            id="file-upload"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
        </div>
        {file && isVideoFile() && item.media_type === "image" && (
          <div style={{ margin: "1rem", width: "100%" }}>
            <label htmlFor="frame-rate">
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-bottom" placement="bottom">
                    {frameRateTooltip}
                  </Tooltip>
                }
                placement="bottom"
              >
                <a
                  onClick={() => {
                    const w = window.open("about:blank");
                    w.location.href = `https://en.wikipedia.org/wiki/Frame_rate`;
                  }}
                  style={LinkStyle}
                >
                  <strong>Frame Rate</strong>
                </a>
              </OverlayTrigger>
            </label>
            <input
              id="frame-rate"
              type="number"
              value={fps}
              onChange={(e) => setFps(e.target.value)}
            />
            <label htmlFor="image-quality">Image Quality in %</label>
            <input
              id="image-quality"
              type="number"
              value={imgQuality}
              onChange={handleChangeImgQuality}
            />
          </div>
        )}
        <Modal.Footer>
          {fileLoading ? (
            <p className="loading-text">
              Tips: You can click X to leave here, we will continue to process
              your request in the background.
            </p>
          ) : null}
          <Button onClick={uploadFile} disabled={fileLoading}>
            {fileLoading ? (
              <span>
                <Timer loadingStates={[fileLoading]} />
              </span>
            ) : (
              "Upload"
            )}
          </Button>
          {showProgress && ( // 只有当showProgress为true时才显示进度条
            <div>
              <progress value={progress} max="100" />
              <span>{progress}%</span>
            </div>
          )}
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
}

export default UploadModal;
