import React from 'react';
import '../../Auth/Auth.css';
import { connect } from "react-redux";
import SignupForm from './SignupForm';
import { userSignupRequest } from '../../../redux/actions/signupAction';


function SignUp(props) {
  return (
    <div className='row'>
        <div className='col-md-3'></div>
        <div className='col-md-6'>
            <SignupForm signupActions={props.signupActions}/>
        </div>
        <div className='col-md-3'></div>
    </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      signupActions: {
        userSignupRequest: (userData) => dispatch(userSignupRequest(userData))
      }
    };
  };
  
  export default connect(null, mapDispatchToProps)(SignUp);