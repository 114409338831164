// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datasetGallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.card {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-body {
  max-width: 18rem;
}

.card-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-group {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.dataset-option {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.dataset-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 1rem;
  margin-right: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Dataset.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".datasetGallery {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.card {\n  margin-left: 1rem;\n  margin-bottom: 1rem;\n}\n\n.card-title {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.card-body {\n  max-width: 18rem;\n}\n\n.card-text {\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.add-group {\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-start;\n}\n\n.dataset-option {\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-start;\n}\n\n.dataset-group {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  margin-left: 1rem;\n  margin-right: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
