// ConfigInput.js
import React from "react";
import Form from 'react-bootstrap/Form';
import "./ConfigModal.css"

function ConfigInput(props) {
  const { label, value, onChange } = props;
  const isBoolean = typeof value === "boolean";

  return (
    <div className="ELT-input">
      {isBoolean ? (
        <div className="form-input input-group mb-3 switch-custom-input-group">
          <label className="form-check-label custom-label" htmlFor={`flexSwitchCheck-${label}`}>
            {label}
          </label>
          <div className='switch-container'>
            <Form.Check
              type="switch"
              id={`flexSwitchCheck-${label}`}
              checked={value}
              onChange={(e) => onChange(e.target.checked)}
            />
          </div>
          
        </div>
      ) : (
        <div className="input-group mb-3 custom-input-group">
          <span className="input-group-text custom-label">{label}</span>
          <input
            type="text"
            className="form-control custom-input"
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        </div>
      )}
    </div>
  );
}

export default ConfigInput;
