import axios from "axios";
import { useReducer, useState } from "react";
import { Datasets } from "../../components/Dataset/Dataset";
import { Button } from "react-bootstrap";
import "../../styles/LabelsPage.css";
import { useNavigate } from "react-router-dom";

import NavigationBar from "../../components/NavBar/NavBar";
import ClassOperationModal from "./pages/ClassOpsPage";
import LabelsOperationModal from "./pages/LabelsOpsPage";
import FieldOperationModal from "./pages/FieldOpsPage";

function LabelsPage() {
  return (
    <div>
      <NavigationBar />
      <div className="labels-page">
        <Datasets CustomizedContent={CustomizedContent} />
      </div>
    </div>
  );
}

const LinkStyle = {
  color: "blue",
  textDecoration: "underline",
  cursor: "pointer",
};

function CustomizedContent(props) {
  const { item, loadDatasetList, dbMapList } = props;

  const [fieldModalShow, setFieldModalShow] = useState(false);
  const [labelModalShow, setLabelModalShow] = useState(false);
  const [classModalShow, setClassModalShow] = useState(false);

  const hasPrediction =
    item.sample_label_fields &&
    item.sample_label_fields.some((item) => item.includes("prediction"));
  const hasGroundTruth =
    item.sample_label_fields &&
    item.sample_label_fields.some((item) => item.includes("ground_truth"));
  const hasPredictionStyle = hasPrediction
    ? { backgroundColor: "lightblue" }
    : { backgroundColor: "lightcoral" };
  const hasGroundTruthStyle = hasGroundTruth
    ? { backgroundColor: "lightblue" }
    : { backgroundColor: "lightcoral" };

  const LabelInfo = (
    <div className="label-info">
      <p className="card-text" style={hasGroundTruthStyle}>
        has ground_truth:
        {hasGroundTruth ? "Yes" : "No"}
      </p>
      <p className="card-text" style={hasPredictionStyle}>
        has prediction:
        {hasPrediction ? "Yes" : "No"}
      </p>
    </div>
  );

  const ButtonGroup = (
    <div className="label-page-group">
      <Button
        variant="outline-success"
        style={{ margin: "2.5px" }}
        onClick={() => setFieldModalShow(true)}
      >
        Field-Ops
      </Button>
      <Button
        style={{ margin: "2.5px" }}
        onClick={() => setLabelModalShow(true)}
      >
        Label-Opt
      </Button>
      <Button
        variant="outline-primary"
        style={{ margin: "2.5px" }}
        onClick={() => setClassModalShow(true)}
      >
        Class-Opt
      </Button>
    </div>
  );

  const LabelPageComponent = (
    <div className="labels-page-customized-content">
      {LabelInfo}
      {ButtonGroup}
      <FieldOperationModal
        show={fieldModalShow}
        onHide={() => setFieldModalShow(false)}
        item={item}
        loadDatasetList={loadDatasetList}
      />
      <LabelsOperationModal
        show={labelModalShow}
        onHide={() => setLabelModalShow(false)}
        item={item}
        loadDatasetList={loadDatasetList}
      />
      <ClassOperationModal
        show={classModalShow}
        onHide={() => setClassModalShow(false)}
        item={item}
        loadDatasetList={loadDatasetList}
        dbMapList={dbMapList}
      />
    </div>
  );

  const navigate = useNavigate();

  const noLabelDisplay = (
    <div>
      <h6>No Label in this dataset</h6>
      <p>To get labels, please:</p>
      <p>
        1. Go to{" "}
        <a
          onClick={() => {
            navigate("/pre-label");
          }}
          style={LinkStyle}
        >
          <strong>Pre-Label</strong>
        </a>
        .
      </p>
      <p>
        2. Go to{" "}
        <a
          onClick={() => {
            navigate("/cvat");
          }}
          style={LinkStyle}
        >
          <strong>Annotation</strong>
        </a>
        .
      </p>
    </div>
  );

  let display = (
    <div>
      {LabelInfo}
      {noLabelDisplay}
    </div>
  );
  if (hasPrediction || hasGroundTruth) {
    display = LabelPageComponent;
  }

  return display;
}

export default LabelsPage;
