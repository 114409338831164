import axios from "axios";
import { useState } from "react";
import NavigationBar from "../../components/NavBar/NavBar";
import { LabelStudioProject } from "../../components/LabelStudioProject/LabelStudioProject";
import { Datasets } from "../../components/Dataset/Dataset";
import { Tabs, Tab, Button } from "react-bootstrap";
import Timer from "../../components/Timer";

function LabelStudioPage() {
  return (
    <div className="label-studio-page">
      <NavigationBar />
      <Tabs defaultActiveKey="label-studio-dataset">
        <Tab eventKey="label-studio-dataset" title="Datasets">
          <Datasets CustomizedContent={CustomizedContent} />
        </Tab>
        <Tab eventKey="label-studio-project" title="Projects">
          <LabelStudioProject />
        </Tab>
      </Tabs>
    </div>
  );
}

function CustomizedContent(props) {
  const { item, loadDatasetList } = props;

  let display = (
    <ToAnnotateCard item={item} loadDatasetList={loadDatasetList} />
  );
  if (item.anno_key && item.anno_key === "labelstudio") {
    display = <AnnotatedCard item={item} loadDatasetList={loadDatasetList} />;
  }
  if (item.anno_key && item.anno_key !== "labelstudio") {
    display = <OtherBackendCard item={item} />;
  }
  return <div className="label-studio-customized-content">{display}</div>;
}

const LinkStyle = {
  color: "blue",
  textDecoration: "underline",
  cursor: "pointer",
};

function ToAnnotateCard(props) {
  const { item, loadDatasetList } = props;

  const [labelField, setLabelField] = useState(null);

  const [annoLoading, setAnnoLoading] = useState(false);

  const annotate = async () => {
    if (item.samples.length <= 0) {
      window.alert(`Empty dataset should not be upload to CVAT.`);
      return;
    }
    setAnnoLoading(true);
    let url = `/datasets/label_studio/annotate?dataset_name=${item.name}`;
    if (labelField) {
      url += `&label_field=${labelField}`;
    }
    axios
      .put(url)
      .then((response) => {
        loadDatasetList();
        setAnnoLoading(false);
        window.alert(`Upload dataset '${item.name}' to Label Studio success`);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        setAnnoLoading(false);
        window.alert(errorMsg);
      });
  };

  return (
    <div className="label-studio-upload-input">
      <div className="label-studio-select">
        <label htmlFor="field-select">Choose field:</label>
        <div className="label-field-select-option-container">
          <select
            id="field-select"
            value={labelField}
            onChange={(e) => setLabelField(e.target.value)}
            className="cvat-select-option"
          >
            <option value="">Please choose an option</option>
            <option value="pure image">--pure images--</option>
            <option value="ALL">--all fields--</option>
            {item.sample_label_fields &&
              item.sample_label_fields.map((field) => (
                <option key={field} value={field}>
                  {field}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className="button-container">
        <button
          className="btn btn-primary"
          type="button"
          onClick={annotate}
          disabled={annoLoading}
        >
          {annoLoading ? (
            <span>
              <Timer loadingStates={[annoLoading]} />
            </span>
          ) : (
            "Annotate"
          )}
        </button>
      </div>
    </div>
  );
}

function AnnotatedCard(props) {
  const { item, loadDatasetList } = props;

  const [loading, setLoading] = useState(false);

  const cancelAnnotation = async () => {
    setLoading(true);
    axios
      .delete(
        `/datasets/label_studio/annotation/cancel?dataset_name=${item.name}`
      )
      .then((response) => {
        loadDatasetList();
        window.alert(
          `Delete project on Label Studio for dataset '${item.name}' success`
        );
        setLoading(false);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
        setLoading(false);
      });
  };

  return (
    <div className="label-studio-project-info">
      <a
        onClick={() => {
          const w = window.open("about:blank");
          w.location.href = `http://${window.location.hostname}:8081/projects/${item.labelstudio_project_id}`;
        }}
        style={LinkStyle}
      >
        Check in Label Studio
      </a>
      <Button variant="outline-danger" onClick={cancelAnnotation}>
        {loading ? (
          <span>
            <Timer loadingStates={[loading]} />
          </span>
        ) : (
          "Cancel"
        )}
      </Button>
    </div>
  );
}

function OtherBackendCard(props) {
  const { item } = props;

  const keyToURL = {
    cvat: `http://${window.location.hostname}:3000/cvat`,
    labelstudio: `http://${window.location.hostname}:3000/label-studio`,
  };

  return (
    <div class="otherbackend-message">
      <p className="card-text">Is annotating in {item.anno_key}. </p>
      <a
        onClick={() => {
          const w = window.open("about:blank");
          w.location.href = keyToURL[item.anno_key];
        }}
        style={LinkStyle}
      >
        Go to {item.anno_key}
      </a>
    </div>
  );
}

export default LabelStudioPage;
