import { useState } from "react";
import DoubleComfirmModal from "./DoubleConfirmModal";
import { Button } from "react-bootstrap";

function DatasetInfo({ item, onDeleteData }) {
  const [DoubleCheckModalShow, setDoubleCheckModalShow] = useState(false);
  const handleShowModal = () => {
    setDoubleCheckModalShow(true);
  };

  return (
    <div className="dataset-info">
      <p className="card-text">media type: {item.media_type || "null"}</p>
      {/* <div className="sample-container"> */}
      <p className="card-text">samples num: {item.samples.length}</p>
      <p className="card-text">size: {item.size}</p>
      <Button
        className="sample-clear-button"
        variant="outline-secondary"
        onClick={handleShowModal}
        disabled={item.samples.length === 0}
      >
        Clear Data
      </Button>
      <DoubleComfirmModal
        show={DoubleCheckModalShow}
        onHide={() => setDoubleCheckModalShow(false)}
        datasetName={item.name}
        onDelete={onDeleteData}
      />
      {/* </div> */}
    </div>
  );
}

export default DatasetInfo;
