import axios from "axios";
import { useDataContext } from "../../utils/DataContext";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Timer from "../../components/Timer";
import StatusCheckPage from "./pages/LocalModelStatus";

function LocalInferenceContent(props) {
  const { item, loadDatasetList } = props;

  const [dagname, setDagname] = useState(null);
  const [tasksStatus, setTasksStatus] = useState();

  const [loading, setLoading] = useState(false);
  const [showStatusCheckModal, setShowStatusCheckModal] = useState(false);

  const { autodagNames } = useDataContext();

  let subDag;
  if (dagname) {
    let subDags = autodagNames[dagname];
    subDags = subDags.map(Number);
    subDag = Math.min(...subDags);
  }

  let hasLabel, hasLabelStyle;
  if (item.sample_label_fields && item.sample_label_fields.length > 0) {
    hasLabel = "Yes";
    hasLabelStyle = { backgroundColor: "lightblue" };
  } else {
    hasLabel = "No";
    hasLabelStyle = { backgroundColor: "lightcoral" };
  }

  const localLoadModel = async () => {
    if (!dagname) {
      window.alert(`Please select a model first.`);
      return;
    }
    let url = `/models/docker/autodag/load_model`;
    url += `?dataset_name=${item.name}&dag_name=${dagname}&sub_dag=${subDag}`;
    setLoading(true);
    axios
      .put(url)
      .then((response) => {
        setLoading(false);
        loadDatasetList();
        window.alert(`Load model in datasets '${item.name}' success`);
      })
      .catch((error) => {
        setLoading(false);
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const getTaskStatus = async (dataset_name) => {
    try {
      const response = await axios.get(
        `/models/docker/status?dataset_name=${dataset_name}`
      );
      const res = response.data;
      setTasksStatus(res);
    } catch (error) {
      let errorMsg = error.response?.data || "Unknown error occurred";
      window.alert(errorMsg);
    }
  };

  const handleStatusCheckModel = () => {
    getTaskStatus(item.name);
    setShowStatusCheckModal(true);
  };

  return (
    <div className="model-customized-content">
      <p className="card-text" style={hasLabelStyle}>
        hasLabel: {hasLabel}
      </p>
      <p class="card-text">samples num: {item.samples.length}</p>
      <select
        value={dagname}
        onChange={(e) => setDagname(e.target.value)}
        className="local-model-select"
      >
        <option value={null}>Please choose a model</option>
        {Object.keys(autodagNames).map((dag) => (
          <option key={`${item.name}-${dag}`} value={dag}>
            {dag}
          </option>
        ))}
      </select>
      <Button
        onClick={localLoadModel}
        disabled={loading}
        style={{ margin: "0.5rem" }}
      >
        {loading ? (
          <span>
            <Timer loadingStates={[loading]} />
          </span>
        ) : (
          "Start"
        )}
      </Button>
      <Button
        variant="outline-primary"
        onClick={handleStatusCheckModel}
        style={{ margin: "0.5rem" }}
      >
        Status Check
      </Button>
      <StatusCheckPage
        show={showStatusCheckModal}
        onHide={() => setShowStatusCheckModal(false)}
        reloadStatus={handleStatusCheckModel}
        tasksStatus={tasksStatus}
      />
    </div>
  );
}

export default LocalInferenceContent;
