import axios from "axios";
import { useState } from "react";
import { Button } from "react-bootstrap";
import "../../../styles/DatasetPage.css";

function SplitterPage(props) {
  const { item, loadDatasetList } = props;

  const [splitNum, setSplitNum] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChangeSplitNum = (e) => {
    let newSplitNum = e.target.value;
    newSplitNum = Math.floor(newSplitNum)
    if (newSplitNum < 1 || newSplitNum > 100) {
      if (!newSplitNum) {
        setSplitNum(null);
      }
      return;
    }
    setSplitNum(newSplitNum);
  };

  const split = async () => {
    if (splitNum < 1 || splitNum > 100) {
      window.alert(
        `Please choose number between 1 to 100 as split number. Your input is ${splitNum}.`
      );
      return;
    }
    setLoading(true);
    axios
      .put(`/datasets/split?dataset_name=${item.name}&split_number=${splitNum}`)
      .then((response) => {
        setLoading(false);
        loadDatasetList();
      })
      .catch((error) => {
        setLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  return (
    <div className="split-page">
      <p class="card-text">{item.name}</p>
      <p class="card-text">samples num: {item.samples.length}</p>
      <input
        type="number"
        placeholder="split number"
        value={splitNum}
        onChange={handleChangeSplitNum}
      />
      <Button
        variant="secondary"
        type="button"
        onClick={split}
        disabled={loading}
      >
        {loading ? "Loading..." : "Split"}
      </Button>
    </div>
  );
}

export default SplitterPage;
