import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function DeleteModal(props) {
  const { item, deleteFolder, isDeleting } = props;
  return (
    <Modal show={props.show} onHide={props.onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Folder</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to Delete this folder?</p>
        <p>Or if you want to clear data only but keep the folder?</p>
      </Modal.Body>
      <Modal.Footer>
        {isDeleting ? (
          <Button variant="outline-danger" disabled>
            Deleting
          </Button>
        ) : (
          <Button
            variant="outline-danger"
            onClick={() => {
              deleteFolder(item.name, item.type);
            }}
          >
            Delete
          </Button>
        )}
        {isDeleting ? (
          <Button variant="outline-danger" disabled>
            Clearing
          </Button>
        ) : (
          <Button
            variant="outline-danger"
            onClick={() => {
              deleteFolder(item.name, item.type, true);
            }}
          >
            Clear Data
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;
