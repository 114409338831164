// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.model-customized-content {
  width: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
}

.chooseModel {
  margin-bottom: 0.5rem;
}

.model-choice-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.button-row {
  display: flex;
  gap: 1rem;
}

.loadModel {
  margin-bottom: 0.5rem;
}

.dag-select {
  width: 90%;
}

.model-info {
  width: 95%;
  margin-right: -2rem;
}

.model-info p {
  margin-bottom: 0.2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.model-info p:hover {
  text-overflow: visible;
  white-space: normal;
}

.auto-dag {
  width: 600px;
  margin: 0 auto;
}

.auto-dag select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.local-model-select {
  width: 220px;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin: 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/PreLabelPage/PreLabelPage.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,SAAS;AACX;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".model-customized-content {\n  width: 100%;\n}\n\n.buttonContainer {\n  display: flex;\n  flex-direction: column;\n}\n\n.chooseModel {\n  margin-bottom: 0.5rem;\n}\n\n.model-choice-container {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 1rem;\n}\n\n.button-row {\n  display: flex;\n  gap: 1rem;\n}\n\n.loadModel {\n  margin-bottom: 0.5rem;\n}\n\n.dag-select {\n  width: 90%;\n}\n\n.model-info {\n  width: 95%;\n  margin-right: -2rem;\n}\n\n.model-info p {\n  margin-bottom: 0.2rem;\n  text-overflow: ellipsis;\n  overflow: hidden;\n  white-space: nowrap;\n}\n\n.model-info p:hover {\n  text-overflow: visible;\n  white-space: normal;\n}\n\n.auto-dag {\n  width: 600px;\n  margin: 0 auto;\n}\n\n.auto-dag select {\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ced4da;\n  border-radius: 4px;\n}\n\n.local-model-select {\n  width: 220px;\n  padding: 8px;\n  border: 1px solid #ced4da;\n  border-radius: 4px;\n  margin: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
