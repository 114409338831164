// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-rate-customized-content {
    display: flex;
    flex-direction: column;
  }
  
  .error-rate-customized-content .card-text {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .error-rate-customized-content label {
    display: block;
    font-size: 16px;
    margin-top: 5px;
  }
  
  .error-rate-customized-content input[type="number"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .error-rate-customized-content .down-button {
    text-align: right;
  }
  
  .error-rate-customized-content .field-select {
    margin-bottom: 10px;
  }
  
  .error-rate-customized-content .field-select label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .error-rate-customized-content .field-select-option {
    width: 100%;
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }

.field-select {
    display: flex;
    flex-direction: column;
    width: 220px;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.field-select-container{
    width: 220px; 
    overflow: hidden; 
}



  `, "",{"version":3,"sources":["webpack://./src/pages/ErrorRatePage/ErrorRatePage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;EACpB;;AAEF;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".error-rate-customized-content {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .error-rate-customized-content .card-text {\n    font-size: 16px;\n    margin-bottom: 5px;\n  }\n  \n  .error-rate-customized-content label {\n    display: block;\n    font-size: 16px;\n    margin-top: 5px;\n  }\n  \n  .error-rate-customized-content input[type=\"number\"] {\n    width: 100%;\n    padding: 8px;\n    border: 1px solid #ced4da;\n    border-radius: 4px;\n    font-size: 16px;\n  }\n  \n  .error-rate-customized-content .down-button {\n    text-align: right;\n  }\n  \n  .error-rate-customized-content .field-select {\n    margin-bottom: 10px;\n  }\n  \n  .error-rate-customized-content .field-select label {\n    display: block;\n    font-size: 16px;\n    margin-bottom: 5px;\n  }\n  \n  .error-rate-customized-content .field-select-option {\n    width: 100%;\n    padding: 8px;\n    border: 1px solid #ced4da;\n    border-radius: 4px;\n  }\n\n.field-select {\n    display: flex;\n    flex-direction: column;\n    width: 220px;\n    margin-bottom: 1rem;\n    margin-top: 1rem;\n}\n\n.field-select-container{\n    width: 220px; \n    overflow: hidden; \n}\n\n\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
