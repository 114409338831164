import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import auth from './reducers/Auth'

// 创建根 reducer
const rootReducer = combineReducers({
  auth: auth,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
