import axios from "axios";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDataContext } from "../../../utils/DataContext";

function DatasetPage(props) {
  const { item, loadDatasetList } = props;
  const { datasetNames } = useDataContext();
  const [datasetName, setDatasetName] = useState(null);

  const [cloneLoading, setCloneLoading] = useState(false);

  const cloneDataset = async () => {
    setCloneLoading(true);
    axios
      .put(
        `/datasets/samples/clone?dataset_name=${item.name}&target_dataset_name=${datasetName}`
      )
      .then((response) => {
        loadDatasetList();
        window.alert(`Clone dataset success`);
        setCloneLoading(false);
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
        setCloneLoading(false);
      });
  };

  return (
    <div>
      <select
        value={datasetName}
        onChange={(e) => {
          setDatasetName(e.target.value);
        }}
      >
        <option value="">--Choose a dataset to clone--</option>
        {datasetNames &&
          datasetNames.map((dataset) => (
            <option key={dataset} value={dataset}>
              {dataset}
            </option>
          ))}
      </select>
      <Modal.Footer>
        <Button onClick={cloneDataset} disabled={cloneLoading}>
          {cloneLoading ? "Cloning..." : "Clone"}
        </Button>
      </Modal.Footer>
    </div>
  );
}

export default DatasetPage;
