import axios from 'axios';

export const setAuthToken = (token) => {
  if (token) {
    // 设置请求头部的 Authorization 字段为令牌值
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    // 若没有令牌值，则移除请求头部的 Authorization 字段
    delete axios.defaults.headers.common["Authorization"];
  }
};


