import axios from "axios";
import { setAuthToken } from "../../utils/validations/setAuthToken";
import { loginSuccess } from "./authActionTypes";
import { SET_CURRENT_USER, SET_CURRENT_USER_ROLE } from "../../constant";
import jwtDecode from "jwt-decode";

export const setCurrentUser = (user) => {
  return {
    type: SET_CURRENT_USER,
    user,
  };
};

export const setCurrentUserRole = (role) => {
  return {
    type: SET_CURRENT_USER_ROLE,
    payload: role,
  };
};

export const setRole = (role) => {
  return {
    type: "SET_ROLE",
    payload: role,
  };
};

export const logout = () => {
  return (dispatch) => {
    localStorage.removeItem("loginToken");
    setAuthToken(false);
    dispatch(setCurrentUser({}));
  };
};

export const login = (userData) => {
  return async (dispatch, props) => {
    try {
      const response = await axios.post("/auth/login", userData);
      dispatch(loginSuccess("/acquisition"));
      const token = response.data.token;
      localStorage.setItem("token", token);
      setAuthToken(token);

      // 解析 JWT Token 获取角色信息
      const decodedToken = jwtDecode(token);
      const role = decodedToken.role;

      // 分发设置当前用户和角色的 action
      dispatch(setCurrentUser(decodedToken));
      dispatch(setCurrentUserRole(role));
      return response;
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        const errorCode = error.response.status;
        dispatch({ type: "USER_LOGIN_FAILURE", payload: errorMessage });
        return Promise.reject({ message: errorMessage, status: errorCode });
      } else {
        dispatch({ type: "USER_LOGIN_FAILURE", payload: "Network Error" });
        return Promise.reject({ message: "Network Error" });
      }
    }
  };
};
