import { Modal } from "react-bootstrap";
import { useState } from "react";
import { Datasets } from "../../components/Dataset/Dataset";
import { Button } from "react-bootstrap";
import "../../styles/DatasetPage.css";
import NavigationBar from "../../components/NavBar/NavBar";
import DownSamplingPage from "./pages/DownSamplingPage";
import DeleteRedundantPage from "./pages/DeleteRedundantPage";
import DeleteSamplesPage from "./pages/DeleteSamplesPage";

function DataCleansingPage() {
  return (
    <div>
      <NavigationBar />
      <div className="dataset-page">
        <Datasets CustomizedContent={CustomizedContent} />
      </div>
    </div>
  );
}

const nameToTitleName = {
  downsampling: "sampling page",
  deleteRedundant: "delete redundant page",
  deleteSamples: "delete samples page",
};

function CustomizedContent(props) {
  const { item } = props;

  const [pageName, setPageName] = useState(null);

  const showModel = pageName && nameToTitleName.hasOwnProperty(pageName);

  const isCommon = item.folder !== "Yes" && item.table !== "Yes";
  if (!isCommon) {
    return (
      <div className="folder-table-dataset-tips">
        <p>You can clean data in dataset that represents a folder/table</p>
        {item.folder === "Yes" && (
          <div>
            <p>If you want to clean data in a folder dataset,</p>
            <p>
              please click 'Folders' tab, but this feat is not implemented yet.
            </p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="dataset-customized-content">
      <p class="card-text">samples num: {item.samples.length}</p>
      <Button
        variant="outline-secondary"
        style={{ margin: "2.5px" }}
        onClick={() => setPageName("downsampling")}
      >
        Sampling
      </Button>
      <Button
        variant="outline-secondary"
        style={{ margin: "2.5px" }}
        onClick={() => setPageName("deleteRedundant")}
      >
        Delete Redundant
      </Button>
      <Button
        variant="outline-secondary"
        style={{ margin: "2.5px" }}
        onClick={() => setPageName("deleteSamples")}
      >
        Delete Samples
      </Button>
      <DataCleansingPageModal
        show={showModel}
        onHide={() => setPageName(null)}
        pageName={pageName}
        {...props}
      />
    </div>
  );
}

function DataCleansingPageModal(props) {
  const { pageName } = props;

  const nameToComponent = {
    downsampling: <DownSamplingPage {...props} />,
    deleteRedundant: <DeleteRedundantPage {...props} />,
    deleteSamples: <DeleteSamplesPage {...props} />,
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      isOpen={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "10px" }}
          >
            {nameToTitleName[pageName]}
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>{nameToComponent[pageName]}</Modal.Body>
    </Modal>
  );
}

export default DataCleansingPage;
