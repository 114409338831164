import SigninForm from "./LoginForm";
import "../../Auth/Auth.css";
import { connect } from "react-redux";
import { login } from "../../../redux/actions/authAction";

function Login(props) {
  const { role } = props; // 从props中获取role

  return (
    <div className="signin-container">
      <div className="container">
        <div>
          <SigninForm authActions={props.authActions} role={role} />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    role: state.auth.role, // 从Redux store中获取role的值
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    authActions: {
      login: (userData) => dispatch(login(userData)),
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
