import axios from "axios";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Tabs, Tab, Button } from "react-bootstrap";
import NavigationBar from "../../components/NavBar/NavBar";
import { Folders } from "../../components/Folder/Folder";
import { AcquisitionDataProvider } from "../../utils/DataContext";
import "../../styles/LabelsPage.css";

function LLMPage() {
  return (
    <AcquisitionDataProvider>
      <div className="LLM-page">
        <NavigationBar />
        <Folders CustomizedContent={CustomizedContent} onlytype={"text"} />
      </div>
    </AcquisitionDataProvider>
  );
}

function CustomizedContent(props) {
  const { item, loadFolderList } = props;

  const [docName, setdocName] = useState(null);
  const [keywordsContent, setKeywordsContent] = useState(null);

  const [downloading, setDownloading] = useState(false);
  const [checking, setChecking] = useState(false);

  const [showAnnoModal, setShowAnnoModal] = useState(false);

  const getDocContent = async () => {
    axios
      .get(
        `/LLM/document/content/get?folder_name=${item.name}&doc_name=${docName}`
      )
      .then((response) => {
        setKeywordsContent(response.data);
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
        loadFolderList();
      });
  };

  const checkDocuments = async () => {
    setChecking(true);
    axios
      .put(`/LLM/document/deepcheck?folder_name=${item.name}`)
      .then((response) => {
        setChecking(false);
        loadFolderList();
      })
      .catch((error) => {
        setChecking(false);
        const errorMsg = error.response.data;
        window.alert(errorMsg);
        loadFolderList();
      });
  };

  const handleAnnotate = () => {
    if (!docName) {
      window.alert("Please select a document.");
      return;
    }
    getDocContent();
    setShowAnnoModal(true);
  };

  const download = async () => {
    let url = `/file/folder/download?folder_name=${item.name}&folder_type=${item.type}`;
    setDownloading(true);
    axios
      .get(url, {
        responseType: "blob", // important
      })
      .then((response) => {
        setDownloading(false);
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${item.name}.zip`); //or any other extension
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        window.alert(`Download folder '${item.name}' success`);
      })
      .catch((error) => {
        setDownloading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const qualifiedDocs = item.filenames.filter(
    (item) => !item.startsWith("<unqualified>")
  );
  const unqualifiedDocs = item.filenames.filter((item) =>
    item.startsWith("<unqualified>")
  );
  const finishedDocs = item.filenames.filter((item) =>
    item.startsWith("<finished>")
  );

  return (
    <div className="LLM-customized-content">
      {qualifiedDocs && (
        <select
          id="docName-select"
          value={docName}
          onChange={(e) => setdocName(e.target.value)}
          className="docName-select-option"
          style={{ width: "100%" }}
        >
          <option value={null}>Please choose a document.</option>
          {qualifiedDocs &&
            qualifiedDocs.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
        </select>
      )}
      {unqualifiedDocs && (
        <select
          id="unqualified-docName-select"
          className="docName-select-option"
          style={{ width: "100%" }}
        >
          <option value={null}>Check unqualified documents.</option>
          {unqualifiedDocs &&
            unqualifiedDocs.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
        </select>
      )}
      <Button
        variant="success"
        onClick={checkDocuments}
        style={{ marginTop: "0.5rem" }}
      >
        {checking ? "Checking..." : "Check"}
      </Button>
      <Button onClick={handleAnnotate} style={{ marginTop: "0.5rem" }}>
        Annotate
      </Button>
      <Button
        variant="secondary"
        onClick={download}
        disabled={downloading}
        style={{ marginTop: "0.5rem" }}
      >
        {downloading ? "Downloading..." : "Download"}
      </Button>
      <AnnotationModal
        show={showAnnoModal}
        onHide={() => setShowAnnoModal(false)}
        item={item}
        docName={docName}
        keywordsContent={keywordsContent}
      />
    </div>
  );
}

function AnnotationModal(props) {
  const { item, docName, keywordsContent } = props;

  const [keyword, setKeyword] = useState(null);
  const [annotation, setAnnotation] = useState({ Content: "", Questions: {} });

  if (keyword && keywordsContent[keyword]) {
    const container = document.querySelector(".document-content");
    if (container) {
      container.innerHTML = keywordsContent[keyword];
    }
  }

  const readAnnotation = async () => {
    axios
      .get(
        `/LLM/document/json_template/read?folder_name=${item.name}&doc_name=${docName}&keyword=${keyword}`
      )
      .then((response) => {
        setAnnotation(response.data);
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const saveAnnotation = async () => {
    if (!docName) {
      window.alert("document not selected.");
      return;
    }
    if (!keyword) {
      window.alert("keyword: chapter / title not selected.");
    }
    axios
      .post(
        `/LLM/document/json_template/save?folder_name=${item.name}&doc_name=${docName}&keyword=${keyword}`,
        annotation
      )
      .then((response) => {
        window.alert(`Save LLM annotation successfully.`);
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  useEffect(() => {
    if (docName && keyword) {
      readAnnotation();
    }
  }, [keyword]);

  const addQuestion = (number, newContent) => {
    const updatedAnnotation = { ...annotation };
    Object.keys(annotation.Questions).forEach((currentNum) => {
      if (currentNum < number) {
        updatedAnnotation.Questions[currentNum] =
          annotation.Questions[currentNum];
      } else if (currentNum === number) {
        updatedAnnotation.Questions[number] = newContent;
      } else {
        updatedAnnotation.Questions[currentNum + 1] =
          annotation.Questions[currentNum];
      }
    });
    if (number === 0) {
      updatedAnnotation.Questions[1] = newContent;
    } else if (number === Object.keys(annotation.Questions).length + 1) {
      updatedAnnotation.Questions[number] = newContent;
    }
    setAnnotation(updatedAnnotation);
  };

  const deleteQuestion = (number) => {
    const updatedAnnotation = { ...annotation };
    delete updatedAnnotation.Questions[number];
    Object.keys(updatedAnnotation.Questions).forEach((currentNum) => {
      if (parseInt(currentNum) > number) {
        updatedAnnotation.Questions[parseInt(currentNum) - 1] =
          updatedAnnotation.Questions[currentNum];
        delete updatedAnnotation.Questions[currentNum];
      }
    });
    setAnnotation(updatedAnnotation);
  };

  const editQuestion = (number, newContent) => {
    setAnnotation((annotation) => ({
      ...annotation,
      Questions: {
        ...annotation.Questions,
        [number]: newContent,
      },
    }));
  };

  const questions = (
    <div className="questions">
      <ul>
        {annotation.Questions &&
          Object.entries(annotation.Questions).map(([number, question]) => {
            return (
              <li key={number}>
                {number}:{" "}
                <textarea
                  value={question}
                  onChange={(e) => editQuestion(number, e.target.value)}
                  rows="2"
                  style={{ width: "100%" }}
                />
                <button onClick={() => deleteQuestion(number)}>Delete</button>
              </li>
            );
          })}
      </ul>
      <button
        onClick={() =>
          addQuestion(Object.keys(annotation.Questions).length + 1, "")
        }
      >
        Add New
      </button>
    </div>
  );

  const editContent = (newContent) => {
    setAnnotation((annotation) => ({
      ...annotation,
      Content: newContent,
    }));
  };

  const content = (
    <div className="content">
      {annotation.Content && (
        <textarea
          value={annotation.Content}
          onChange={(e) => editContent(e.target.value)}
          rows="20"
          style={{ width: "100%" }}
        />
      )}
    </div>
  );

  return (
    <Modal
      fullscreen={true}
      show={props.show}
      onHide={props.onHide}
      className="LLM-annotation-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>LLM Annotation Tool</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="docname-keyword"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <strong className="docname" style={{ width: "35%" }}>
            {docName}
          </strong>
          <div className="choose-keywords" style={{ width: "35%" }}>
            {keywordsContent && (
              <select
                id="keyword-select"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                className="keyword-select-option"
                style={{ width: "100%" }}
              >
                <option value={null}>Please choose a keyword.</option>
                {keywordsContent &&
                  Object.keys(keywordsContent) &&
                  Object.keys(keywordsContent).map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
              </select>
            )}
          </div>
        </div>
        <div
          className="annotation-part"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div class="overflow-y-auto" style={{ width: "60%", height: "100%" }}>
            <div className="document-content" />
          </div>
          <div className="content-questions" style={{ width: "40%" }}>
            <Tabs defaultActiveKey="questions">
              <Tab eventKey="questions" title="Questions">
                {questions}
              </Tab>
              <Tab eventKey="content" title="Content">
                {content}
              </Tab>
            </Tabs>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={saveAnnotation}>Save</Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LLMPage;
