// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-container{
    position: fixed; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    display: flex;
    flex-direction: column;
    width: 22rem;
    justify-content: center;
    align-content: center;
}

.signin-container{
    position: fixed; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    display: flex;
    flex-direction: column;
    width: 22rem;
    justify-content: center;
    align-content: center;
}

.button-container{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.form-title{
    display: flex;
    margin-bottom: 1rem;
    text-align: center;
    justify-content: center;
}

.form-container{
    display: flex;
    flex-direction: column;
}

.down-button-container{
    margin-top: 20rem;
}

.container{
    display: flex;
    flex-direction: column;
}

`, "",{"version":3,"sources":["webpack://./src/pages/Auth/Auth.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,uBAAuB;IACvB,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":[".signup-container{\n    position: fixed; \n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -60%);\n    display: flex;\n    flex-direction: column;\n    width: 22rem;\n    justify-content: center;\n    align-content: center;\n}\n\n.signin-container{\n    position: fixed; \n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -60%);\n    display: flex;\n    flex-direction: column;\n    width: 22rem;\n    justify-content: center;\n    align-content: center;\n}\n\n.button-container{\n    display: flex;\n    flex-direction: column;\n    margin-top: 1rem;\n}\n\n.form-title{\n    display: flex;\n    margin-bottom: 1rem;\n    text-align: center;\n    justify-content: center;\n}\n\n.form-container{\n    display: flex;\n    flex-direction: column;\n}\n\n.down-button-container{\n    margin-top: 20rem;\n}\n\n.container{\n    display: flex;\n    flex-direction: column;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
