import axios from "axios";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import { createContext, useContext, useState, useEffect } from "react";

const DataContext = createContext();

export function AppProvidider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [userToken, setUserToken] = useState(null);
  const [userData, setUserData] = useState(null);

  const [role, setRole] = useState(null);
  const [organization, setOrganization] = useState(null);

  const updateUserInfo = () => {
    const userToken = Cookies.get("user_token");
    const userData = userToken ? jwtDecode(userToken) : null;
    const role = userData ? userData.role : null;
    const isLoggedIn = userData ? true : false;
    const organization = userData ? userData.organization : null;
    setUserToken(userToken);
    setUserData(userData);
    setIsLoggedIn(isLoggedIn);
    setRole(role);
    setOrganization(organization);
  };

  useEffect(() => {
    updateUserInfo();
  }, []);

  const contextValue = {
    isLoggedIn,
    setIsLoggedIn,
    updateUserInfo,
    userToken,
    userData,
    role,
    organization,
  };

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
}

export function AcquisitionDataProvider({ children }) {
  const [selectedSource, setSelectedSource] = useState("");
  const [datasetNames, setDatasetNames] = useState([]);
  const [zooDatasetNames, setZooDatasetNames] = useState([]);
  const [folderList, setFolderList] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [availSpace, setAvailSpace] = useState("");

  const [CopyPathPage, setCopyPathPage] = useState({
    CopyPathPage: "",
  });
  const [folderType, setFolderType] = useState("image");

  const fetchList = async () => {
    try {
      const [datasetNames, zooDatasetNames, folderList, tableList, availSpace] =
        await Promise.all([
          axios.get("/datasets/list?names_only=true"),
          axios.get("/datasets/zoo/list"),
          axios.get("/file/folder/list"),
          axios.get("/ETL/table/list"),
          axios.get("/available/space"),
        ]);
      setDatasetNames(datasetNames.data);
      setZooDatasetNames(zooDatasetNames.data);
      setFolderList(folderList.data.concat());
      setTableList(tableList.data.concat());
      setAvailSpace(availSpace.data);
    } catch (error) {
      let errorMsg = error.response.data;
      window.alert(errorMsg);
    }
  };

  useEffect(() => {
    fetchList();
  }, []);

  const contextValue = {
    selectedSource,
    setSelectedSource,
    fetchList,
    datasetNames,
    zooDatasetNames,
    folderList,
    tableList,
    availSpace,
    CopyPathPage,
    setCopyPathPage,
    folderType,
    setFolderType,
  };

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
}

export function PreLabelProvider({ children }) {
  const [ZooModelNames, setZooModelNames] = useState([]);
  const [kubeflowModelNames, setKubeflowModelNames] = useState([]);
  const [autodagNames, setAutodagNames] = useState([]);
  const [huggingfaceModels, setHuggingfaceModels] = useState([]);
  const [HqSamModels, setHqSamModels] = useState([]);
  const [ultralyticsModels, setUltralyticsModels] = useState([]);

  const fetchModelsList = async () => {
    try {
      const [
        zooModelNames,
        kubeflowModelNames,
        autodagNames,
        huggingfaceModels,
        HqSamModels,
        ultralyticsModels,
      ] = await Promise.all([
        axios.get("/models/zoo/list"),
        axios.get("/models/kubeflow/list"),
        axios.get("/models/kubeflow/autodags"),
        axios.get("/models/huggingface/models/list"),
        axios.get("/models/hq_sam/models/list"),
        axios.get("/models/ultralytics/models/list"),
      ]);
      setZooModelNames(zooModelNames.data);
      setKubeflowModelNames(kubeflowModelNames.data);
      setAutodagNames(autodagNames.data);
      setHuggingfaceModels(huggingfaceModels.data);
      setHqSamModels(HqSamModels.data);
      setUltralyticsModels(ultralyticsModels.data);
    } catch (error) {
      const errorMsg = error.response.data;
      window.alert(errorMsg);
    }
  };

  const contextValue = {
    ZooModelNames,
    kubeflowModelNames,
    autodagNames,
    huggingfaceModels,
    HqSamModels,
    ultralyticsModels,
    fetchModelsList,
  };

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
}

export function CVATProvider({ children }) {
  const [organizations, setOrganizations] = useState([]);
  const [allprojects, setAllprojects] = useState([]);

  useEffect(() => {
    listOrganizations();
    listAllprojects();
  }, []);

  const listOrganizations = async () => {
    axios
      .get(`/organizations/list`)
      .then((response) => {
        const newOrganizations = response.data;
        setOrganizations(newOrganizations.concat());
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const listAllprojects = async () => {
    axios
      .get(`/projects/list`)
      .then((response) => {
        const fetchedAllprojects = response.data;
        setAllprojects(fetchedAllprojects.concat());
      })
      .catch((error) => {
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const contextValue = {
    organizations,
    listOrganizations,
    allprojects,
    listAllprojects,
  };

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
}

export function useDataContext() {
  return useContext(DataContext);
}
