import React, { useState } from "react";
import "../../Auth/Auth.css";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import Timer from "../../../components/Timer";

function SignupForm(props) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [signuploading, setSignuploading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let formErrors = {};

    if (!formData.username) {
      formErrors.username = "Username is required";
    }

    if (!formData.email) {
      formErrors.email = "Email is required";
    }

    if (!formData.password) {
      formErrors.password = "Password is required";
    }

    if (formData.password !== formData.confirmPassword) {
      formErrors.confirmPassword = "Passwords do not match";
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setSignuploading(true);
      try {
        const response = await props.signupActions.userSignupRequest(formData);
        const status = response.status;
        setSignuploading(false);
        if (status === 200) {
          // Signup successful, navigate to the home page
          const successful_alert = response.data.message;
          window.alert(successful_alert);
          navigate("/signin");
        } else {
          // Signup failed, show an error message
          window.alert("Signup failed. Please try again.");
        }
      } catch (error) {
        setSignuploading(false);
        if (error.statusCode === 400) {
          window.alert(JSON.stringify(error.message));
        } else {
          // Handle any other errors
          window.alert(error.message);
        }
      }
    }
  };

  return (
    <div className="signup-container">
      <form onSubmit={handleSubmit}>
        <div className="form-title">
          <h3>Sign Up</h3>
        </div>
        <div>
          <label className="control-label">Username</label>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            placeholder="Username here"
            className={classNames("form-control", {
              "is-invalid": errors.username,
            })}
          />
          {errors.username && (
            <span className="form-text text-muted">{errors.username}</span>
          )}
        </div>
        <div>
          <label className="control-label">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="abc@gmail.com"
            className={classNames("form-control", {
              "is-invalid": errors.email,
            })}
          />
          {errors.email && (
            <span className="form-text text-muted">{errors.email}</span>
          )}
        </div>
        <div>
          <label className="control-label">Password</label>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password here"
            className={classNames("form-control", {
              "is-invalid": errors.password,
            })}
          />
          {errors.password && (
            <span className="form-text text-muted">{errors.password}</span>
          )}
        </div>
        <div>
          <label className="control-label">Confirm Password</label>
          <input
            type="password"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            placeholder="Please confirm password here"
            className={classNames("form-control", {
              "is-invalid": errors.confirmPassword,
            })}
          />
          {errors.confirmPassword && (
            <span className="form-text text-muted">
              {errors.confirmPassword}
            </span>
          )}
        </div>
        <div className="d-grid">
          <div className="button-container">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={signuploading}
              style={{ margin: "auto" }}
            >
              {signuploading ? (
                <span>
                  <Timer loadingStates={[signuploading]} />
                </span>
              ) : (
                "Sign Up"
              )}
            </button>
          </div>
        </div>
        <p className="forgot-password text-right">
          Already registered <a href="/signin">Sign In?</a>
        </p>
      </form>
    </div>
  );
}

export default SignupForm;
