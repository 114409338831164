// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.NavLink-with-divider {
  border-right: none;
  padding: 0;
  position: relative;
}

.NavLink-with-divider::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 55%;
  border-left: 2px solid rgb(200, 200, 200);
}

.left-navbar-container {
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
}

.custom-style {
  background-color: #cfcfcf;
  border-radius: 0.5rem;
}

.right-navbar-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: 1rem;
}

.nav-link {
  font-weight: normal;
  color: black;
}

.nav-link.active,
.nav-link:hover {
  font-weight: bold;
  color: gray;
}

.nav-item + .nav-item {
  margin-left: 0;
}

.navbar-container {
  display: flex;
  margin-top: 1rem;
  margin-left: 1rem;
  justify-content: center;
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/NavBar/NavBar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;EAC3B,WAAW;EACX,yCAAyC;AAC3C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;;EAEE,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".navbar {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n}\n\n.NavLink-with-divider {\n  border-right: none;\n  padding: 0;\n  position: relative;\n}\n\n.NavLink-with-divider::before {\n  content: \"\";\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  height: 55%;\n  border-left: 2px solid rgb(200, 200, 200);\n}\n\n.left-navbar-container {\n  display: flex;\n  flex-direction: row;\n  margin-left: 1rem;\n}\n\n.custom-style {\n  background-color: #cfcfcf;\n  border-radius: 0.5rem;\n}\n\n.right-navbar-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  margin-left: 1rem;\n}\n\n.nav-link {\n  font-weight: normal;\n  color: black;\n}\n\n.nav-link.active,\n.nav-link:hover {\n  font-weight: bold;\n  color: gray;\n}\n\n.nav-item + .nav-item {\n  margin-left: 0;\n}\n\n.navbar-container {\n  display: flex;\n  margin-top: 1rem;\n  margin-left: 1rem;\n  justify-content: center;\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
