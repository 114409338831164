// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-nav {
  flex-direction: row;
  flex-wrap: wrap;
}
`, "",{"version":3,"sources":["webpack://./src/styles/LabelsPageModal.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".navbar-nav {\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
