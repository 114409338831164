import axios from "axios";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Timer from "../../../components/Timer";
import "../../../styles/LabelsPageModal.css";

function ClassOperationModal(props) {
  const { item, loadDatasetList, dbMapList } = props;

  const [labelField, setLabelField] = useState(null);
  const [labelclassItems, setLabelclassItems] = useState([]);
  const [labelname, setLabelname] = useState(null);
  const [newlabelname, setNewLabelname] = useState(null);
  const [selectedDbMap, setSelectedDbMap] = useState(null);
  const [DbMapClassItems, setDbMapClassItems] = useState([]);

  const [labelLoading, setLabelLoading] = useState(false);
  const [renderBegining, setRenderBegining] = useState(true);

  const reset = () => {
    setLabelname(null);
    listLabelclasses();
    getDbMapClassItems();
    setRenderBegining(false);
  };

  useEffect(() => {
    reset();
  }, [labelField, selectedDbMap]);

  const getDbMapClassItems = async () => {
    if (!selectedDbMap || selectedDbMap == "") {
      return;
    }
    const url = `/kneron/global_constant/dbmap/classes?dbmap_name=${selectedDbMap}`;
    axios
      .get(url)
      .then((response) => {
        const newDbMapClassItems = response.data;
        setDbMapClassItems(newDbMapClassItems.concat());
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const listLabelclasses = async () => {
    if (renderBegining) {
      return;
    }
    if (!labelField || labelField == "") {
      return;
    }
    const url = `/datasets/list/classes?dataset_name=${item.name}&field=${labelField}`;
    axios
      .get(url)
      .then((response) => {
        const newLabelItems = response.data;
        setLabelclassItems(newLabelItems.concat());
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const renameLabel = async () => {
    if (!labelField || labelField == "") {
      window.alert(`Please selected field of dataset '${item.name}'`);
      return;
    }
    if (!labelname || !newlabelname) {
      window.alert(
        `Please selected label and input new label name you want to rename.`
      );
      return;
    }
    let url = `/datasets/remap/class?dataset_name=${item.name}&field=${labelField}&label=${labelname}&new_label=${newlabelname}`;
    setLabelLoading(true);
    axios
      .put(url)
      .then((response) => {
        setLabelLoading(false);
        reset();
        loadDatasetList();
        window.alert(`Remap label class on dataset '${item.name}' success`);
      })
      .catch((error) => {
        setLabelLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const deleteLabel = async () => {
    if (!labelField || labelField == "") {
      window.alert(`Please selected field of dataset '${item.name}'`);
      return;
    }
    if (!labelname) {
      window.alert(`Please selected label you want to delete.`);
      return;
    }
    let url = `/datasets/delete/class?dataset_name=${item.name}&field=${labelField}&label=${labelname}`;
    setLabelLoading(true);
    axios
      .delete(url)
      .then((response) => {
        setLabelLoading(false);
        reset();
        loadDatasetList();
        window.alert(`Delete label class on dataset '${item.name}' success`);
      })
      .catch((error) => {
        setLabelLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const matchedItems = labelclassItems.filter((elem) =>
    DbMapClassItems.includes(elem)
  );
  const unmatchedItems = labelclassItems.filter(
    (elem) => !DbMapClassItems.includes(elem)
  );
  const restItems = DbMapClassItems.filter(
    (elem) => !labelclassItems.includes(elem)
  );

  function ClassItemList({ items, badgeClass }) {
    return (
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        {items &&
          items.map((item) => (
            <li key={item}>
              <span className={`badge rounded-pill ${badgeClass}`}>{item}</span>
            </li>
          ))}
      </ul>
    );
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      isOpen={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "10px" }}
          >
            Class Operations
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        {item.sample_fields && (
          <div className="class-operation">
            <select
              id="field-select"
              value={labelField}
              onChange={(e) => setLabelField(e.target.value)}
              className="field-select-option"
            >
              <option value="">Please choose a Label field</option>
              {item.sample_fields &&
                item.sample_fields.map((field) => (
                  <option key={field} value={field}>
                    {field}
                  </option>
                ))}
            </select>
            {labelField && (
              <div className="class-items-list">
                <select
                  id="class-select"
                  value={labelname}
                  onChange={(e) => setLabelname(e.target.value)}
                  className="class-select-option"
                >
                  <option value="">Please choose an label class</option>
                  {labelclassItems &&
                    labelclassItems.map((label) => (
                      <option key={label} value={label}>
                        {label}
                      </option>
                    ))}
                </select>
                {labelname && (
                  <div>
                    <input
                      type="text"
                      id="new-label-name"
                      class="form-control"
                      placeholder="new label name"
                      value={newlabelname}
                      onChange={(e) => setNewLabelname(e.target.value)}
                    />
                    <Button
                      variant="success"
                      onClick={renameLabel}
                      disabled={labelLoading}
                    >
                      {labelLoading ? (
                        <span>
                          <Timer loadingStates={[labelLoading]} />
                        </span>
                      ) : (
                        "Rename"
                      )}
                    </Button>
                    <Button
                      variant="outline-danger"
                      onClick={deleteLabel}
                      disabled={labelLoading}
                    >
                      {labelLoading ? (
                        <span>
                          <Timer loadingStates={[labelLoading]} />
                        </span>
                      ) : (
                        "Delete"
                      )}
                    </Button>
                  </div>
                )}
              </div>
            )}
            <select
              id="dbmap-select"
              value={selectedDbMap}
              onChange={(e) => setSelectedDbMap(e.target.value)}
              className="dbmap-select-option"
            >
              <option value={null}>Please choose a pre-define class</option>
              {dbMapList &&
                dbMapList.map((dbmap) => (
                  <option key={dbmap} value={dbmap}>
                    {dbmap}
                  </option>
                ))}
            </select>
            <div>
              {(matchedItems || unmatchedItems || restItems) && (
                <div>
                  <p>
                    Green: Exists in both prediction labels and public field;
                  </p>
                  <p>
                    Red: Exists in prediction labels, not in public fields, It
                    is necessary to rename the label, delete it or add the new
                    label to dbmap;
                  </p>
                  <p>
                    Gray: Exists in the public field, not in prediction labels,
                    It is necessary to rename the label in the prediction labels
                    by referring to the labels in the public field;
                  </p>
                </div>
              )}
              <ClassItemList
                items={matchedItems}
                badgeClass="text-bg-success"
              />
              <ClassItemList
                items={unmatchedItems}
                badgeClass="text-bg-danger"
              />
              <ClassItemList items={restItems} badgeClass="text-bg-secondary" />
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ClassOperationModal;
