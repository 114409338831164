import axios from "axios";
import React, { useState } from "react";
import { Datasets } from "../../components/Dataset/Dataset";
import Button from "react-bootstrap/Button";
import "./ErrorRatePage.css";
import "react-dropdown/style.css";
import NavigationBar from "../../components/NavBar/NavBar";

function ErrorRatePage() {
  return (
    <div className="error-rate-page">
      <NavigationBar />
      <Datasets CustomizedContent={CustomizedContent} />
      {/* <p>I am Error Rate page</p> */}
    </div>
  );
}

function CustomizedContent(props) {
  const { item, loadDatasetList } = props;

  const [IouThred, setIouThred] = useState(0.8);
  const [errRateThred, setErrRateThred] = useState(0.5);
  const [evaluatedFieldName, setEvaluatedFieldName] = useState(null);
  const [targetFieldName, setTargetFieldName] = useState(null);
  const [targetDataset, setTargetDataset] = useState(null);
  const [deleteLowErrRate, setDeleteLowErrRate] = useState(false);

  const [computeLoading, setComputeLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleChangeEvaluatedField = (e) => {
    let newLabelField = e.target.value;
    setEvaluatedFieldName(newLabelField);
  };

  const handleChangeTargetField = (e) => {
    let newLabelField = e.target.value;
    setTargetFieldName(newLabelField);
  };

  const handleChangeIouThred = (e) => {
    const value = parseFloat(e.target.value);
    setIouThred(isNaN(value) ? 0 : parseFloat(value.toFixed(2)));
  };

  const handleChangeErrRateThred = (e) => {
    const value = parseFloat(e.target.value);
    setErrRateThred(isNaN(value) ? 0 : parseFloat(value.toFixed(2)));
  };

  const computeIou = async () => {
    if (!item.samples.length || item.samples.length === 0) {
      window.alert(`There is no sample in dataset '${item.name}'`);
      return;
    }
    if (!evaluatedFieldName || !targetFieldName) {
      window.alert(
        `Please both input evaluated field and target field in dataset '${item.name}'`
      );
      return;
    }
    setComputeLoading(true);
    let url = `/samples/compute/error_rate`;
    let params =
      `?dataset_name=${item.name}&iou_threshold=${IouThred}` +
      `&evaluated_field_name=${evaluatedFieldName}&target_field_name=${targetFieldName}`;
    url = url + params;
    axios
      .put(url)
      .then((response) => {
        setComputeLoading(false);
        loadDatasetList();
        window.alert(`Compute error rate on dataset '${item.name}' success`);
      })
      .catch((error) => {
        setComputeLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const checkInput = () => {
    if (errRateThred < 0 || errRateThred > 1) {
      window.alert(
        `Iou threshold is Invalid. Please choose a number between 0.0 to 1.0`
      );
      return false;
    } else if (!item.samples.length || item.samples.length === 0) {
      window.alert(`There is no sample in dataset '${item.name}'`);
      return false;
    }
    return true;
  };

  const deleteSampleByErrRate = async () => {
    // if (errRateThred < 0 || errRateThred > 1) {
    //   window.alert(
    //     `Iou threshold is Invalid. Please choose a number between 0.0 to 1.0`
    //   );
    //   return;
    // } else if (!item.samples.length || item.samples.length === 0) {
    //   window.alert(`There is no sample in dataset '${item.name}'`);
    //   return;
    // }
    if (!checkInput()) {
      return;
    }
    setDeleteLoading(true);
    let url =
      `/samples/delete/by_error_rate?` +
      `dataset_name=${item.name}&error_rate_threshold=${errRateThred}` +
      `&delete_low_err_rate=${deleteLowErrRate}`;
    axios
      .delete(url)
      .then((response) => {
        setDeleteLoading(false);
        loadDatasetList();
        window.alert(
          `Delete samples by error rate on dataset '${item.name}' success`
        );
      })
      .catch((error) => {
        setDeleteLoading(false);
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  const cutDatasetByErrRate = async () => {
    if (!checkInput()) {
      return;
    }
    if (!targetDataset) {
      window.alert("Please input a target dataset name.");
      return;
    }
    let url =
      `/dataset/cut/by_error_rate?` +
      `dataset_name=${item.name}&error_rate_threshold=${errRateThred}` +
      `&target_dataset_name=${targetDataset}`;
    axios
      .put(url)
      .then((response) => {
        loadDatasetList();
        window.alert(
          `Cut dataset by error rate on dataset '${item.name}' success`
        );
      })
      .catch((error) => {
        let errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  return (
    <div className="error-rate-customized-content">
      <p class="card-text">
        media type: {item.media_type ? item.media_type : "null"}
      </p>
      <div class="clear-data">
        <div class="sample-container">
          <p class="card-text">samples num: {item.samples.length}</p>
        </div>
      </div>
      {item.sample_fields && item.sample_fields.includes("error_rate") ? (
        <div>
          <div>
            <label htmlFor="error-rate-threshold">Error Rate threshold:</label>
            <input
              id="error-rate-threshold"
              type="number"
              min={0}
              max={1}
              step={0.1}
              value={errRateThred}
              onChange={handleChangeErrRateThred}
            />
          </div>
          <div className="down-button">
            <Button
              variant="outline-danger"
              type="button"
              onClick={deleteSampleByErrRate}
            >
              {deleteLoading ? "Loading..." : "Delete Samples"}
            </Button>
          </div>
          <input
            type="text"
            id="target-dataset-name"
            className="form-control"
            placeholder="target dataset name"
            value={targetDataset}
            onChange={(e) => {
              setTargetDataset(e.target.value);
            }}
          />
          <div className="down-button">
            <Button
              variant="secondary"
              type="button"
              onClick={cutDatasetByErrRate}
            >
              Cut
            </Button>
          </div>
        </div>
      ) : (
        <div>
          <div className="field-select">
            <label htmlFor="class-select">Evaluated field:</label>
            <select
              id="field-select"
              value={evaluatedFieldName}
              onChange={handleChangeEvaluatedField}
              className="field-select-option"
            >
              <option value="">Please choose an option</option>
              {item.sample_label_fields &&
                item.sample_label_fields.map((field) => (
                  <option key={field} value={field}>
                    {field}
                  </option>
                ))}
            </select>
          </div>
          <div className="field-select">
            <label htmlFor="class-select">Target field:</label>
            <select
              id="field-select"
              value={targetFieldName}
              onChange={handleChangeTargetField}
              className="field-select-option"
            >
              <option value="">Please choose an option</option>
              {item.sample_label_fields &&
                item.sample_label_fields.map((field) => (
                  <option key={field} value={field}>
                    {field}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <label htmlFor="iou-threshold">IOU threshold:</label>
            <input
              id="iou-threshold"
              type="number"
              min={0}
              max={1}
              step={0.1}
              value={IouThred}
              onChange={handleChangeIouThred}
            />
          </div>
          <div className="down-button">
            <Button type="button" onClick={computeIou}>
              {computeLoading ? "Loading..." : "Compute Iou"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ErrorRatePage;
