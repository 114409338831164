import { Button } from "react-bootstrap";
import { getUserCookie } from "../../../utils/cookiesUtils";

function MainPage(props) {
  const { setCurrentPage } = props;

  const userData = getUserCookie();
  const role = userData ? userData.role : null;

  let display = (
    <div>
      <Button
        onClick={() => {
          setCurrentPage("MntPage");
        }}
      >
        From Dir
      </Button>
      <Button
        onClick={() => {
          setCurrentPage("datasetPage");
        }}
      >
        Clone Dataset
      </Button>
      <Button
        onClick={() => {
          setCurrentPage("FiftyonePage");
        }}
      >
        FiftyOne
      </Button>
    </div>
  );
  if (role === "annotator") {
    display = (
      <div>
        <Button
          onClick={() => {
            setCurrentPage("datasetPage");
          }}
        >
          Clone Dataset
        </Button>
      </div>
    );
  }

  return display;
}

export default MainPage;
