import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import BackButton from "../../components/BackButton/BackButton";
import { useDataContext } from "../../utils/DataContext";
import "./PreLabelPage.css";

const pageToModelTitleMap = {
  ZooModelPage: "ZooModel",
  KubeflowPage: "kubeflow",
  AutodagPage: "auto DAG",
  HuggingFacePage: "Hugging Face",
  HqSamPage: "HQ SAM",
  CustomPage: "Custom Model",
  UltralyticsPage: "Ultralytics Model",
};

function ModelPageModal(props) {
  const { item, onSelectedModelType, handleModel } = props;

  const {
    ZooModelNames,
    kubeflowModelNames,
    autodagNames,
    huggingfaceModels,
    HqSamModels,
    ultralyticsModels,
  } = useDataContext();

  const [selectedModels, setSelectedModels] = useState({
    zooModel: "",
    KubeflowModel: "",
    autodagName: "",
    huggingfaceModel: "",
    HqSamModel: "",
    CustomModel: "",
    ultralyticsModel: "",
  });

  const [currentPage, setCurrentPage] = useState("main");
  const [textCaption, setTextCaption] = useState("");
  const [autodagKeyword, setAutodagKeyword] = useState("");
  const [subDag, setSubDag] = useState(null);

  const [labelField, setLabelField] = useState(null);

  const handleModelChange = (modelType, e) => {
    setSelectedModels((prevModels) => ({
      ...prevModels,
      [modelType]: e.target.value,
    }));
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const pageToModel = {
    ZooModelPage: selectedModels["zooModel"],
    KubeflowPage: selectedModels["KubeflowModel"],
    AutodagPage: selectedModels["autodagName"],
    HuggingFacePage: selectedModels["huggingfaceModel"],
    HqSamPage: selectedModels["HqSamModel"],
    CustomPage: selectedModels["CustomModel"],
    UltralyticsPage: selectedModels["ultralyticsModel"],
  };

  const handleModelSubmit = (page) => {
    onSelectedModelType(page);
    let model = pageToModel[page];
    if (page === "KubeflowPage" && model.split("/")[0] === "taskmatrix") {
      model = { model, textCaption };
    } else if (page === "AutodagPage") {
      const dagName = model;
      model = { dagName, subDag };
    } else if (page === "HqSamPage") {
      const hqsam = model;
      model = { hqsam, labelField };
    }
    handleModel(page, model);
  };

  const displayAutodagNames = Object.keys(autodagNames).filter((name) =>
    name.includes(autodagKeyword)
  );

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <div style={{ display: "flex", alignItems: "center" }}>
          {currentPage !== "main" && (
            <BackButton onClick={() => goToPage("main")} />
          )}
          <Modal.Title
            id="contained-modal-title-vcenter"
            style={{ marginLeft: "10px" }}
          >
            Choose Model
          </Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        {currentPage === "main" && (
          <div className="model-choice-container">
            {Object.entries(pageToModelTitleMap).map(([page, title]) => (
              <Button key={page} onClick={() => goToPage(page)}>
                {title}
              </Button>
            ))}
          </div>
        )}
        {currentPage === "ZooModelPage" && (
          <div className="zoo-model-select">
            <label htmlFor="model-select">Choose zoo model:</label>
            <select
              id="model-select"
              value={selectedModels["zooModel"]}
              onChange={(e) => handleModelChange("zooModel", e)}
            >
              <option value="">--Please choose an option--</option>
              {ZooModelNames.map((model) => (
                <option key={model} value={model}>
                  {model}
                </option>
              ))}
            </select>
            <Modal.Footer>
              <Button
                onClick={() => {
                  handleModelSubmit(currentPage);
                  props.onHide();
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </div>
        )}
        {currentPage === "KubeflowPage" && (
          <div className="kubeflow-input">
            <label htmlFor="model-select">Choose kubeflow model:</label>
            <select
              id="model-select"
              value={selectedModels["KubeflowModel"]}
              onChange={(e) => handleModelChange("KubeflowModel", e)}
            >
              <option value="">--Please choose an option--</option>
              {kubeflowModelNames.map((model) => (
                <option key={model} value={model}>
                  {model}
                </option>
              ))}
            </select>
            {selectedModels["KubeflowModel"].split("/")[0] === "taskmatrix" && (
              <div>
                <label htmlFor="tectCaption">Text Caption</label>
                <input
                  id="tectCaption"
                  type="text"
                  value={textCaption}
                  onChange={(event) => setTextCaption(event.target.value)}
                />
              </div>
            )}
            <Modal.Footer>
              <Button
                onClick={() => {
                  if (
                    selectedModels["KubeflowModel"].split("/")[0] ===
                      "taskmatrix" &&
                    !textCaption
                  ) {
                    window.alert(`Please input text prompt.`);
                    return;
                  }
                  handleModelSubmit(currentPage);
                  props.onHide();
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </div>
        )}
        {currentPage === "AutodagPage" && (
          <div className="auto-dag">
            <label htmlFor="model-contains-keyword">Contains keyword:</label>
            <input
              id="model-contains-keyword"
              type="text"
              value={autodagKeyword}
              onChange={(e) => setAutodagKeyword(e.target.value)}
            />
            <label htmlFor="dag-select">Choose Auto DAG:</label>
            <select
              id="dag-select"
              value={selectedModels["autodagName"]}
              onChange={(e) => handleModelChange("autodagName", e)}
            >
              <option value="">--Please choose an option--</option>
              {displayAutodagNames.map((dag) => (
                <option key={dag} value={dag}>
                  {dag}
                </option>
              ))}
            </select>
            {selectedModels["autodagName"] && (
              <div>
                <label htmlFor="sub-dag-select">Choose Sub DAG:</label>
                <select
                  id="sub-dag-select"
                  value={subDag}
                  onChange={(e) => setSubDag(e.target.value)}
                >
                  <option value="">--Please choose an option--</option>
                  {autodagNames[selectedModels["autodagName"]].map((subDag) => (
                    <option key={subDag} value={subDag}>
                      {subDag}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <Modal.Footer>
              <Button
                onClick={() => {
                  if (!selectedModels["autodagName"] && !subDag) {
                    window.alert(`Please choose DAG and sub DAG.`);
                    return;
                  }
                  handleModelSubmit(currentPage);
                  props.onHide();
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </div>
        )}
        {currentPage === "HuggingFacePage" && (
          <div className="hugging-face-page">
            <label htmlFor="hugging-face-model-select">
              Choose Hugging Face model:
            </label>
            <select
              id="hugging-face-model-select"
              value={selectedModels["huggingfaceModel"]}
              onChange={(e) => handleModelChange("huggingfaceModel", e)}
            >
              <option value="">--Please choose an option--</option>
              {huggingfaceModels.map((model) => (
                <option key={model} value={model}>
                  {model}
                </option>
              ))}
            </select>
            <Modal.Footer>
              <Button
                onClick={() => {
                  handleModelSubmit(currentPage);
                  props.onHide();
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </div>
        )}
        {currentPage === "HqSamPage" && (
          <div className="HQ-SAM-page">
            <label htmlFor="model-select">Choose HQ SAM model:</label>
            <select
              id="HQ-SAM-model-select"
              value={selectedModels["HqSamModel"]}
              onChange={(e) => handleModelChange("HqSamModel", e)}
            >
              <option value="">--Please choose an option--</option>
              {HqSamModels.map((model) => (
                <option key={model} value={model}>
                  {model}
                </option>
              ))}
            </select>
            <label htmlFor="bbox-field-select">
              Choose bbox field for prompting:
            </label>
            <select
              id="bbox-field-select"
              value={labelField}
              onChange={(e) => {
                setLabelField(e.target.value);
              }}
            >
              <option value="">Please choose an option</option>
              {item.sample_fields &&
                item.sample_fields.map((field) => (
                  <option key={field} value={field}>
                    {field}
                  </option>
                ))}
            </select>
            <Modal.Footer>
              <Button
                onClick={() => {
                  handleModelSubmit(currentPage);
                  props.onHide();
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </div>
        )}
        {currentPage === "CustomPage" && (
          <div className="custom-page">
            <label htmlFor="custom-model-input">Input custom model name:</label>
            <input
              id="custom-model-input"
              type="text"
              value={selectedModels["CustomModel"]}
              onChange={(e) => handleModelChange("CustomModel", e)}
            />
            <Modal.Footer>
              <Button
                onClick={() => {
                  handleModelSubmit(currentPage);
                  props.onHide();
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </div>
        )}
        {currentPage === "UltralyticsPage" && (
          <div className="ultralytics-page">
            <label htmlFor="ultralytics-model-select">
              Choose Ultralytics model:
            </label>
            <select
              id="ultralytics-model-select"
              value={selectedModels["ultralyticsModel"]}
              onChange={(e) => handleModelChange("ultralyticsModel", e)}
            >
              <option value="">--Please choose an option--</option>
              {ultralyticsModels.map((model) => (
                <option key={model} value={model}>
                  {model}
                </option>
              ))}
            </select>
            <Modal.Footer>
              <Button
                onClick={() => {
                  handleModelSubmit(currentPage);
                  props.onHide();
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default ModelPageModal;
