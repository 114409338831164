import Nav from "react-bootstrap/Nav";
import Cookies from "js-cookie";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useState, useEffect } from "react";
import { useDataContext } from "../../utils/DataContext";
import "./NavBar.css";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../redux/actions/authAction";
import { Dropdown } from "react-bootstrap";
import { getUserCookie } from "../../utils/cookiesUtils";
import daatLogo from "../../assets/DAAT_Logo.png";
import fiftyoneLogo from "../../assets/fiftyone_logo.webp";
import CVATLogo from "../../assets/cvat_logo.png";

const annotationItems = [
  { eventKey: "cvat", href: "/cvat", text: "CVAT" },
  { eventKey: "label-studio", href: "/label-studio", text: "Label Studio" },
  { eventKey: "llm", href: "/llm", text: "LLM" },
];

function NavigationBar(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const [annotationTitle, setAnnotationTitle] = useState("Annotation");
  const [selectedAnnotation, setSelectedAnnotation] = useState("");

  const userData = getUserCookie();
  const role = userData ? userData.role : null;
  const organization = userData ? userData.organization : null;

  let displayAnnotationItems = annotationItems;
  if (role === "annotator") {
    displayAnnotationItems = [
      { eventKey: "cvat", href: "/cvat", text: "CVAT" },
    ];
  }

  const locatePage = () => {
    const page = window.location.href;

    const findAndSetSelectedItem = (items, setTitle, setSelectedItem) => {
      const found = items.some((item) => {
        if (page.indexOf(item.eventKey) !== -1) {
          setTitle(item.text);
          setSelectedItem(item.eventKey);
          return true;
        }
      });
      return found;
    };
    const annotationFound = findAndSetSelectedItem(
      annotationItems,
      setAnnotationTitle,
      setSelectedAnnotation
    );

    if (!annotationFound) {
      setAnnotationTitle("Annotation");
    }
  };

  useEffect(locatePage, []);

  const handleDropdownSelect = (eventKey, dropdownItems, setTitle) => {
    for (let item in dropdownItems) {
      if (item.eventKey === eventKey) {
        setTitle(item.text);
        break;
      }
    }
  };

  const onLogOut = () => {
    localStorage.removeItem("token");
    props.logout();
    window.history.replaceState(null, "", "/signin");
    Cookies.remove("user_token");
    navigate("/signin");
  };

  const logined = (
    <Dropdown>
      <Dropdown.Toggle variant="light" id="user-dropdown">
        {props.user.user.email}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {role === "admin" && (
          <div>
            <Dropdown.Item
              href={`http://${window.location.hostname}:5001/admin`}
              target="_blank"
            >
              Dashboard
            </Dropdown.Item>
            <Dropdown.Item href={"/organization"}>Organizations</Dropdown.Item>
            <Dropdown.Divider />
          </div>
        )}
        {organization && (
          <Dropdown.Item href={"/organization"}>
            Your Organization: {organization}
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={onLogOut}>Log Out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const login = (
    <Link to="/signin" onClick={() => navigate("/signin")}>
      Please Signin
    </Link>
  );

  const allowedPaths = {
    admin: ["/*"], // Allows access to all paths
    developer: ["/*"], // Allows access to all paths
    annotator: ["/*"],
  };

  const isAllowedToClick = (path) => {
    const allowed = allowedPaths[role] || [];
    if (allowed.includes(path) || allowed.includes("/*")) {
      return true;
    }
    return false;
  };

  return (
    <div className="navbar-container">
      <div class="navbar navbar-expand-lg bg-gradient mb-3 mx-auto">
        <img src={daatLogo} style={{ width: "260px", height: "100px" }} />
        <a
          onClick={() => {
            const w = window.open("about:blank");
            w.location.href = `http://${window.location.hostname}:5151/`;
          }}
          style={{ cursor: "pointer" }}
        >
          <img
            src={fiftyoneLogo}
            style={{ width: "120px", height: "50px", marginLeft: "1.5rem" }}
          />
        </a>
        <a
          onClick={() => {
            const w = window.open("about:blank");
            w.location.href = `https://cvat.kneron.com/`;
          }}
          style={{ cursor: "pointer" }}
        >
          <img
            src={CVATLogo}
            style={{ width: "120px", height: "50px", marginLeft: "1rem" }}
          />
        </a>
        <div class="container-fluid">
          <Nav variant="pills" defaultActiveKey="/acquisition">
            <div className="right-navbar-container">
              <div className="nav-item-container">
                <Nav.Item>
                  <Nav.Link
                    eventKey="acquisition"
                    href={"/acquisition"}
                    active={location.pathname === "/acquisition"}
                    disabled={!isAllowedToClick("/acquisition")}
                  >
                    Acquisition-Data
                  </Nav.Link>
                </Nav.Item>
              </div>
              <div className="nav-item-container">
                <Nav.Item>
                  <Nav.Link
                    eventKey="data-cleansing"
                    href={"/data-cleansing"}
                    active={location.pathname === "/data-cleansing"}
                    disabled={!isAllowedToClick("/data-cleansing")}
                  >
                    Data-Cleansing
                  </Nav.Link>
                </Nav.Item>
              </div>
              <div className="nav-item-container">
                <Nav.Item>
                  <Nav.Link
                    eventKey="pre-label"
                    href={"/pre-label"}
                    active={location.pathname === "/pre-label"}
                    disabled={!isAllowedToClick("/pre-label")}
                  >
                    Pre-Label
                  </Nav.Link>
                </Nav.Item>
              </div>
              <div className="nav-item-container">
                <Nav.Item>
                  <Nav.Link
                    eventKey="labels"
                    href={"/labels"}
                    active={location.pathname === "/labels"}
                    disabled={!isAllowedToClick("/labels")}
                  >
                    Label-Opt
                  </Nav.Link>
                </Nav.Item>
              </div>
              {role !== "annotator" && (
                <div className="nav-item-container">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="dataset"
                      href={"/dataset"}
                      active={location.pathname === "/dataset"}
                      disabled={!isAllowedToClick("/dataset")}
                    >
                      Split-Dataset
                    </Nav.Link>
                  </Nav.Item>
                </div>
              )}
              <CustomizedNavDropdown
                title={annotationTitle}
                selectedItem={selectedAnnotation}
                dropdownItems={displayAnnotationItems}
                setTitle={setAnnotationTitle}
                handleDropdownSelect={handleDropdownSelect}
                isAllowedToClick={isAllowedToClick}
                location={location}
              />
              {role !== "annotator" && (
                <div className="nav-item-container">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="export"
                      href={"/export"}
                      active={location.pathname === "/export"}
                      disabled={!isAllowedToClick("/export")}
                    >
                      Export
                    </Nav.Link>
                  </Nav.Item>
                </div>
              )}
              {role !== "annotator" && (
                <div className="nav-item-container">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="train"
                      href={"/train"}
                      active={location.pathname === "/train"}
                    >
                      Train
                    </Nav.Link>
                  </Nav.Item>
                </div>
              )}
              {props.user.user.email ? logined : login}
            </div>
          </Nav>
        </div>
      </div>
    </div>
  );
}

function CustomizedNavDropdown(props) {
  const {
    title,
    selectedItem,
    dropdownItems,
    setTitle,
    handleDropdownSelect,
    isAllowedToClick,
    location,
  } = props;

  return (
    <NavDropdown
      title={title}
      id="nav-dropdown"
      active={selectedItem !== ""}
      onSelect={handleDropdownSelect(dropdownItems, setTitle)}
    >
      {dropdownItems.map((item) => (
        <NavDropdown.Item
          key={item.eventKey}
          eventKey={item.eventKey}
          href={item.href}
          active={location.pathname === item.href}
          disabled={!isAllowedToClick(item.href)}
        >
          {item.text}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth,
  };
};

export default connect(mapStateToProps, { logout })(NavigationBar);
