import { Button } from "react-bootstrap";
import { getUserCookie } from "../../../utils/cookiesUtils";

function MainPage(props) {
  const { goToCopyPathPage, goToLocalPage } = props;

  const userData = getUserCookie();
  const role = userData ? userData.role : null;

  return (
    <div>
      {role !== "annotator" && (
        <Button onClick={goToCopyPathPage}>Copy Data Path</Button>
      )}
      <Button onClick={goToLocalPage}>Local</Button>
    </div>
  );
}

export default MainPage;
