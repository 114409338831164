import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDataContext } from "../../../utils/DataContext";

function FiftyonePage(props) {
  const { setSelectedSource, zooDatasetNames } = useDataContext();
  const { onHide } = props;
  const [fiftyoneSelection, setFiftyoneSelection] = useState({
    selectedDataset: "",
    selectedSplit: "",
    sampleNum: 0,
  });

  const handleZooDatasetChange = (event) => {
    const selectedDataset = event.target.value;
    setFiftyoneSelection((prevSelection) => ({
      ...prevSelection,
      selectedDataset,
    }));
  };

  const handleZooDatasetSplitChange = (event) => {
    const selectedSplit = event.target.value;
    setFiftyoneSelection((prevSelection) => ({
      ...prevSelection,
      selectedSplit,
    }));
  };

  const handleSampleNumChange = (event) => {
    const sampleNum = parseInt(event.target.value, 10);
    setFiftyoneSelection((prevSelection) => ({
      ...prevSelection,
      sampleNum,
    }));
  };

  const handleConfirm = () => {
    setSelectedSource("fiftyone");
    props.onFiftyoneSelectionChange(fiftyoneSelection);
    onHide();
  };

  return (
    <div>
      <div className="selection">
        <label htmlFor="dataset-select">Choose zoo dataset:</label>
        <select
          id="dataset-select"
          value={fiftyoneSelection.selectedDataset}
          onChange={handleZooDatasetChange}
        >
          <option value="">--Please choose an option--</option>
          {zooDatasetNames &&
            zooDatasetNames.map((dataset) => (
              <option key={dataset} value={dataset}>
                {dataset}
              </option>
            ))}
        </select>
        <label htmlFor="split-select">Choose split:</label>
        <select
          id="split-select"
          value={fiftyoneSelection.selectedSplit}
          onChange={handleZooDatasetSplitChange}
        >
          <option value="">--Please choose an option--</option>
          <option key={"train"} value={"train"}>
            train
          </option>
          <option key={"validation"} value={"validation"}>
            validation
          </option>
          <option key={"test"} value={"test"}>
            test
          </option>
        </select>
        <label htmlFor="sample-number">Max sample number:</label>
        <input
          id="sample-number"
          type="number"
          min={1}
          step={1}
          value={fiftyoneSelection.sampleNum}
          onChange={handleSampleNumChange}
        />
      </div>
      <Modal.Footer>
        <Button onClick={handleConfirm}>Confirm</Button>
      </Modal.Footer>
    </div>
  );
}

export default FiftyonePage;
