// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag-customized-content {
    display: flex;
    flex-direction: column;
  }
  
  .tag-customized-content .card-text {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .tag-customized-content label {
    display: block;
    font-size: 16px;
    margin-top: 5px;
  }
  
  .tag-customized-content input[type="number"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .tag-customized-content button {
    padding: 10px 20px;
    margin-top: 1rem;
    border-radius: 4px;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/pages/TagPage/TagPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;EACjB","sourcesContent":[".tag-customized-content {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .tag-customized-content .card-text {\n    font-size: 16px;\n    margin-bottom: 5px;\n  }\n  \n  .tag-customized-content label {\n    display: block;\n    font-size: 16px;\n    margin-top: 5px;\n  }\n  \n  .tag-customized-content input[type=\"number\"] {\n    width: 100%;\n    padding: 8px;\n    border: 1px solid #ced4da;\n    border-radius: 4px;\n    font-size: 16px;\n  }\n  \n  .tag-customized-content button {\n    padding: 10px 20px;\n    margin-top: 1rem;\n    border-radius: 4px;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
