// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Assuming you have a main CSS file or a CSS module for ConfigInput component */
.ELT-input {
  display: flex;
  align-items: center;
}

.ELT-input .form-check {
  margin-right: 10px; /* Add some margin to separate the checkbox and input field */
}

/* Optional: Set a fixed height for the input field to align it with the checkbox height */
.ELT-input .input-group .form-control {
  height: 34px; /* You can adjust the height as per your requirement */
}

.form-input {
  display: flex;
  align-items: center; /* Align elements vertically in the center */
  gap: 10px; /* Add some gap between the label and the switch */
}

/* Assuming you have a main CSS file or a CSS module for custom styles */
.custom-input-group {
  display: flex;
  align-items: center;
}

.switch-custom-input-group {
  display: flex;
  align-items: center;
  margin-left: 50px;
}

.custom-label {
  min-width: 100px; /* 调整宽度以适应您的设计 */
  display: flex;
  align-items: center;
  padding: 0 10px;
  background-color: white; /* 可选：设置标签的背景颜色 */
  border: none; /* 移除边框 */
}

.custom-input {
  flex: 1 1;
  margin: 0;
}

.switch-container {
  display: flex;
  align-items: center;
}




`, "",{"version":3,"sources":["webpack://./src/pages/ETLPage/ConfigModal/ConfigModal.css"],"names":[],"mappings":"AAAA,gFAAgF;AAChF;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB,EAAE,6DAA6D;AACnF;;AAEA,0FAA0F;AAC1F;EACE,YAAY,EAAE,sDAAsD;AACtE;;AAEA;EACE,aAAa;EACb,mBAAmB,EAAE,4CAA4C;EACjE,SAAS,EAAE,kDAAkD;AAC/D;;AAEA,wEAAwE;AACxE;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB,EAAE,gBAAgB;EAClC,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,uBAAuB,EAAE,iBAAiB;EAC1C,YAAY,EAAE,SAAS;AACzB;;AAEA;EACE,SAAO;EACP,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":["/* Assuming you have a main CSS file or a CSS module for ConfigInput component */\n.ELT-input {\n  display: flex;\n  align-items: center;\n}\n\n.ELT-input .form-check {\n  margin-right: 10px; /* Add some margin to separate the checkbox and input field */\n}\n\n/* Optional: Set a fixed height for the input field to align it with the checkbox height */\n.ELT-input .input-group .form-control {\n  height: 34px; /* You can adjust the height as per your requirement */\n}\n\n.form-input {\n  display: flex;\n  align-items: center; /* Align elements vertically in the center */\n  gap: 10px; /* Add some gap between the label and the switch */\n}\n\n/* Assuming you have a main CSS file or a CSS module for custom styles */\n.custom-input-group {\n  display: flex;\n  align-items: center;\n}\n\n.switch-custom-input-group {\n  display: flex;\n  align-items: center;\n  margin-left: 50px;\n}\n\n.custom-label {\n  min-width: 100px; /* 调整宽度以适应您的设计 */\n  display: flex;\n  align-items: center;\n  padding: 0 10px;\n  background-color: white; /* 可选：设置标签的背景颜色 */\n  border: none; /* 移除边框 */\n}\n\n.custom-input {\n  flex: 1;\n  margin: 0;\n}\n\n.switch-container {\n  display: flex;\n  align-items: center;\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
