import axios from "axios";
import { useState } from "react";
import Timer from "../../../components/Timer";
import Button from "react-bootstrap/Button";
import "react-dropdown/style.css";

const LinkStyle = {
  color: "blue",
  textDecoration: "underline",
  cursor: "pointer",
};

function DownSamplingPage(props) {
  const { item, loadDatasetList } = props;

  const [sampleRate, setSampleRate] = useState(null);

  const [downSamplingLoading, setDownSamplingLoading] = useState(false);

  const handleChangeSampleRate = (e) => {
    let newSampleRate = e.target.value;
    if (newSampleRate === "") {
      newSampleRate = "0";
    }
    if (!/^\+?(0|[1-9]\d*)$/.test(newSampleRate)) {
      return;
    }
    setSampleRate(newSampleRate);
  };

  const downSample = async () => {
    if (sampleRate < 2 || sampleRate > Math.floor(item.samples.length / 2)) {
      window.alert(
        `Sample rate is Invalid. Please choose a number between 2 to ${Math.floor(
          item.samples.length / 2
        )}`
      );
    }
    if (!item.samples.length || item.samples.length === 0) {
      window.alert(`There is no sample in dataset '${item.name}'`);
      return;
    }
    setDownSamplingLoading(true);
    let url = "/datasets/samples/downsample";
    url += `?dataset_name=${item.name}&sample_rate=${sampleRate}`;
    axios
      .delete(url)
      .then((response) => {
        setDownSamplingLoading(false);
        loadDatasetList();
        window.alert(`Down-sampling on dataset '${item.name}' success`);
      })
      .catch((error) => {
        setDownSamplingLoading(false);
        const errorMsg = error.response.data;
        window.alert(errorMsg);
      });
  };

  return (
    <div className="down-sampling-page">
      <p>
        This page allow you to do{" "}
        <a
          onClick={() => {
            const w = window.open("about:blank");
            w.location.href = `https://en.wikipedia.org/wiki/Sampling_(statistics)#Systematic_sampling`;
          }}
          style={LinkStyle}
        >
          Systematic sampling
        </a>{" "}
        with samples of a dataset.
      </p>
      <div className="sample-rate-input">
        <label htmlFor="sample-rate">Select 1 sample in every </label>
        <input
          id="sample-rate"
          type="number"
          value={sampleRate}
          onChange={handleChangeSampleRate}
        />
      </div>
      <Button
        type="button"
        variant="outline-danger"
        onClick={downSample}
        disabled={downSamplingLoading}
      >
        {downSamplingLoading ? (
          <span>
            <Timer loadingStates={[downSamplingLoading]} />
          </span>
        ) : (
          "Down Sample"
        )}
      </Button>
    </div>
  );
}

export default DownSamplingPage;
